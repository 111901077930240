[
  {
    "code": 2,
    "date": "6-Jan-1993",
    "plane_type": "Dash 8-311",
    "location": "Near Paris Charles de Gualle",
    "country": "France",
    "registration": "D-BEAT",
    "operator": "Lufthansa Cityline",
    "fat": "4",
    "f": 4,
    "sqrt+10": 12,
    "px": 22,
    "PX": 15,
    "cat": "A1",
    "phase": "approach",
    "certainty": "confirmed",
    "FIELD16": "",
    "meta": "human_error",
    "cause": "pilot & ATC error",
    "interesting notes": "",
    "FIELD20": "",
    "Cause sources": "",
    "FIELD22": ""
  },
  {
    "code": 3,
    "date": "9-Jan-1993",
    "plane_type": "Hawker Siddeley HS-748-234 Srs",
    "location": "near Surabaya Airport",
    "country": "Indonesia",
    "registration": "PK-IHE",
    "operator": "Bouraq Indonesia",
    "fat": "15",
    "f": 15,
    "sqrt+10": 13.9,
    "px": 24,
    "PX": 25,
    "cat": "A1",
    "phase": "initial climb",
    "certainty": "probable",
    "FIELD16": "",
    "meta": "mechanical",
    "cause": "engine failure",
    "interesting notes": "",
    "FIELD20": "",
    "Cause sources": "",
    "FIELD22": ""
  },
  {
    "code": 4,
    "date": "31-Jan-1993",
    "plane_type": "Shorts SC.7 Skyvan 3-100",
    "location": "Mt. Kapur",
    "country": "Indonesia",
    "registration": "9M-PID",
    "operator": "Pan Malaysian Air Transport",
    "fat": "14",
    "f": 14,
    "sqrt+10": 13.7,
    "px": 24,
    "PX": 25,
    "cat": "A1",
    "phase": "en route",
    "certainty": "probable",
    "FIELD16": "",
    "meta": "weather",
    "cause": "low visibility",
    "interesting notes": "",
    "FIELD20": "",
    "Cause sources": "",
    "FIELD22": ""
  },
  {
    "code": 5,
    "date": "8-Feb-1993",
    "plane_type": "Tupolev 154M",
    "location": "near Tehran-Mehra...",
    "country": "Iran",
    "registration": "EP-ITD",
    "operator": "Iran Air Tours",
    "fat": "131",
    "f": 131,
    "sqrt+10": 21.4,
    "px": 31,
    "PX": 75,
    "cat": "A1",
    "phase": "en route",
    "certainty": "confirmed",
    "FIELD16": "",
    "meta": "human_error",
    "cause": "pilot error, collision",
    "interesting notes": "",
    "FIELD20": "",
    "Cause sources": "",
    "FIELD22": ""
  },
  {
    "code": 6,
    "date": "28-Feb-1993",
    "plane_type": "Dornier 228-201",
    "location": "near Orchid Island",
    "country": "Taiwan",
    "registration": "B-12238",
    "operator": "Formosa Airlines",
    "fat": "6",
    "f": 6,
    "sqrt+10": 12.4,
    "px": 22,
    "PX": 25,
    "cat": "A1",
    "phase": "approach",
    "certainty": "probable",
    "FIELD16": "",
    "meta": "weather",
    "cause": "bad weather",
    "interesting notes": "",
    "FIELD20": "",
    "Cause sources": "",
    "FIELD22": ""
  },
  {
    "code": 7,
    "date": "5-Mar-1993",
    "plane_type": "Fokker 100",
    "location": "near Skopje, Macedonia",
    "country": "Macedonia",
    "registration": "PH-KXL",
    "operator": "Palair Macedonian",
    "fat": "83",
    "f": 83,
    "sqrt+10": 19.1,
    "px": 29,
    "PX": 65,
    "cat": "A1",
    "phase": "initial climb",
    "certainty": "confirmed",
    "FIELD16": "",
    "meta": "human_error",
    "cause": "maintenance error",
    "interesting notes": "",
    "FIELD20": "",
    "Cause sources": "",
    "FIELD22": ""
  },
  {
    "code": 8,
    "date": "19-Mar-1993",
    "plane_type": "Beechcraft 200 Super King Air",
    "location": "near Geilo-Dagali",
    "country": "Norway",
    "registration": "LN-TSA",
    "operator": "West Aviation",
    "fat": "3",
    "f": 3,
    "sqrt+10": 11.7,
    "px": 22,
    "PX": 15,
    "cat": "A1",
    "phase": "approach",
    "certainty": "probable",
    "FIELD16": "",
    "meta": "weather",
    "cause": "bad weather",
    "interesting notes": "",
    "FIELD20": "",
    "Cause sources": "",
    "FIELD22": ""
  },
  {
    "code": 9,
    "date": "23-Mar-1993",
    "plane_type": "Embraer 110C Bandeirante",
    "location": "near Cuiabá, MT",
    "country": "Brazil",
    "registration": "PP-SBJ",
    "operator": "Oeste Linhas Aéreas",
    "fat": "6",
    "f": 6,
    "sqrt+10": 12.4,
    "px": 22,
    "PX": 25,
    "cat": "A1",
    "phase": "en route",
    "certainty": "probable",
    "FIELD16": "",
    "meta": "weather",
    "cause": "bad weather",
    "interesting notes": "",
    "FIELD20": "",
    "Cause sources": "",
    "FIELD22": ""
  },
  {
    "code": 10,
    "date": "6-Apr-1993",
    "plane_type": "McDonnell-Douglas MD-11",
    "location": "near Aleutian Islands, Alaska",
    "country": "USA",
    "registration": "B-2171",
    "operator": "China Eastern Airlines",
    "fat": "2",
    "f": 2,
    "sqrt+10": 11.4,
    "px": 21,
    "PX": 15,
    "cat": "A2",
    "phase": "en route",
    "certainty": "probable",
    "FIELD16": "",
    "meta": "mechanical",
    "cause": "design flaw",
    "interesting notes": "",
    "FIELD20": "",
    "Cause sources": "",
    "FIELD22": ""
  },
  {
    "code": 11,
    "date": "23-Apr-1993",
    "plane_type": "Antonov 26",
    "location": "near Zavkhan Prov",
    "country": "Mongolia",
    "registration": "BNMAU-14102",
    "operator": "MIAT",
    "fat": "32",
    "f": 32,
    "sqrt+10": 15.7,
    "px": 26,
    "PX": 45,
    "cat": "A1",
    "phase": "en route",
    "certainty": "suspected",
    "FIELD16": "",
    "meta": "human_error",
    "cause": "pilot & crew error",
    "interesting notes": "",
    "FIELD20": "",
    "Cause sources": "",
    "FIELD22": ""
  },
  {
    "code": 12,
    "date": "26-Apr-1993",
    "plane_type": "Boeing 737 2AB",
    "location": "Near Aurangabad Airport",
    "country": "India",
    "registration": "VT-ECQ",
    "operator": "Indian Airlines",
    "fat": "55",
    "f": 55,
    "sqrt+10": 17.4,
    "px": 27,
    "PX": 55,
    "cat": "A1",
    "phase": "takeoff",
    "certainty": "probable",
    "FIELD16": "",
    "meta": "human_error",
    "cause": "pilot error",
    "interesting notes": "",
    "FIELD20": "",
    "Cause sources": "",
    "FIELD22": ""
  },
  {
    "code": 13,
    "date": "19-May-1993",
    "plane_type": "Boeing 727-46",
    "location": "Mt Paramo Frontino, Colombia",
    "country": "Colombia",
    "registration": "HK-2422X",
    "operator": "SAM Colombia",
    "fat": "132",
    "f": 132,
    "sqrt+10": 21.5,
    "px": 31,
    "PX": 75,
    "cat": "A1",
    "phase": "en route",
    "certainty": "probable",
    "FIELD16": "",
    "meta": "human_error",
    "cause": "pilot & ATC error",
    "interesting notes": "",
    "FIELD20": "",
    "Cause sources": "",
    "FIELD22": ""
  },
  {
    "code": 14,
    "date": "17-Jun-1993",
    "plane_type": "Antonov 26B",
    "location": "near Tbilisi",
    "country": "Georgia",
    "registration": "26035",
    "operator": "Tajikistan Airlines",
    "fat": "41",
    "f": 41,
    "sqrt+10": 16.4,
    "px": 26,
    "PX": 45,
    "cat": "A1",
    "phase": "en route",
    "certainty": "probable",
    "FIELD16": "",
    "meta": "weather",
    "cause": "turbulence",
    "interesting notes": "",
    "FIELD20": "",
    "Cause sources": "",
    "FIELD22": ""
  },
  {
    "code": 15,
    "date": "1-Jul-1993",
    "plane_type": "Fokker F-28 Fellowship 3000",
    "location": "Sorong-Jefma",
    "country": "Indonesia",
    "registration": "PK-GFU",
    "operator": "Merpati Nusantara",
    "fat": "41",
    "f": 41,
    "sqrt+10": 16.4,
    "px": 26,
    "PX": 45,
    "cat": "A1",
    "phase": "approach",
    "certainty": "unknown",
    "FIELD16": "",
    "meta": "unknown",
    "cause": "unknown",
    "interesting notes": "",
    "FIELD20": "",
    "Cause sources": "",
    "FIELD22": ""
  },
  {
    "code": 16,
    "date": "9-Jun-1993",
    "plane_type": "Pilatus BN-2A-20 Islander",
    "location": "Gulgubip Air",
    "country": "Papua New Guinea",
    "registration": "P2-SWA",
    "operator": "Southwest Air",
    "fat": "9",
    "f": 9,
    "sqrt+10": 13,
    "px": 23,
    "PX": 25,
    "cat": "A1",
    "phase": "approach",
    "certainty": "probable",
    "FIELD16": "",
    "meta": "mechanical",
    "cause": "engine failure",
    "interesting notes": "",
    "FIELD20": "",
    "Cause sources": "",
    "FIELD22": ""
  },
  {
    "code": 17,
    "date": "23-Jul-1993",
    "plane_type": "BAe 146-300",
    "location": "Yinchuan, Ningxia",
    "country": "China",
    "registration": "B-2716",
    "operator": "China Northwest Airlines",
    "fat": "55",
    "f": 55,
    "sqrt+10": 17.4,
    "px": 27,
    "PX": 55,
    "cat": "A1",
    "phase": "takeoff",
    "certainty": "confirmed",
    "FIELD16": "",
    "meta": "mechanical",
    "cause": "mechanical failure",
    "interesting notes": "",
    "FIELD20": "",
    "Cause sources": "",
    "FIELD22": ""
  },
  {
    "code": 18,
    "date": "26-Jul-1993",
    "plane_type": "Boeing 737-5L9",
    "location": "Ungeo Mountain near Mokpo",
    "country": "Korea",
    "registration": "HL7229",
    "operator": "Asiana Airlines",
    "fat": "68",
    "f": 68,
    "sqrt+10": 18.2,
    "px": 28,
    "PX": 55,
    "cat": "A1",
    "phase": "approach",
    "certainty": "probable",
    "FIELD16": "",
    "meta": "weather",
    "cause": "bad weather",
    "interesting notes": "",
    "FIELD20": "",
    "Cause sources": "",
    "FIELD22": ""
  },
  {
    "code": 19,
    "date": "28-Jun-1993",
    "plane_type": "Beechcraft 200 Super King Air",
    "location": "Aminius",
    "country": "Namibia",
    "registration": "ZS-NEP",
    "operator": "Trans Namibia Aviation",
    "fat": "3",
    "f": 3,
    "sqrt+10": 11.7,
    "px": 22,
    "PX": 15,
    "cat": "A1",
    "phase": "initial climb",
    "certainty": "unknown",
    "FIELD16": "",
    "meta": "unknown",
    "cause": "unknown",
    "interesting notes": "",
    "FIELD20": "",
    "Cause sources": "",
    "FIELD22": ""
  },
  {
    "code": 20,
    "date": "31-Jul-1993",
    "plane_type": "Dornier 228-101",
    "location": "near Bharatpur Ai",
    "country": "Nepal",
    "registration": "9N-ACL",
    "operator": "Everest Air",
    "fat": "19",
    "f": 19,
    "sqrt+10": 14.4,
    "px": 24,
    "PX": 35,
    "cat": "A1",
    "phase": "approach",
    "certainty": "unknown",
    "FIELD16": "",
    "meta": "unknown",
    "cause": "unknown",
    "interesting notes": "",
    "FIELD20": "",
    "Cause sources": "",
    "FIELD22": ""
  },
  {
    "code": 21,
    "date": "26-Aug-1993",
    "plane_type": "Let L-410UVP-E",
    "location": "near Aldan Airpor",
    "country": "Russia",
    "registration": "RA-67656",
    "operator": "Sakha Avia",
    "fat": "24",
    "f": 24,
    "sqrt+10": 14.9,
    "px": 25,
    "PX": 35,
    "cat": "A1",
    "phase": "landing",
    "certainty": "probable",
    "FIELD16": "",
    "meta": "human_error",
    "cause": "overload",
    "interesting notes": "",
    "FIELD20": "",
    "Cause sources": "",
    "FIELD22": ""
  },
  {
    "code": 22,
    "date": "28-Aug-1993",
    "plane_type": "Yakovlev 40",
    "location": "near Khorog",
    "country": "Tajikistan",
    "registration": "87995",
    "operator": "Tajikistan Airlines",
    "fat": "82",
    "f": 82,
    "sqrt+10": 19.1,
    "px": 29,
    "PX": 65,
    "cat": "A1",
    "phase": "takeoff",
    "certainty": "probable",
    "FIELD16": "",
    "meta": "human_error",
    "cause": "overload",
    "interesting notes": "",
    "FIELD20": "",
    "Cause sources": "",
    "FIELD22": ""
  },
  {
    "code": 23,
    "date": "14-Sep-1993",
    "plane_type": "Airbus A320",
    "location": "Warsaw",
    "country": "Poland",
    "registration": "D-AIPN",
    "operator": "Lufthansa",
    "fat": "2",
    "f": 2,
    "sqrt+10": 11.4,
    "px": 21,
    "PX": 15,
    "cat": "A1",
    "phase": "landing",
    "certainty": "confirmed",
    "FIELD16": "",
    "meta": "human_error",
    "cause": "crew error, design flaws",
    "interesting notes": "",
    "FIELD20": "",
    "Cause sources": "",
    "FIELD22": ""
  },
  {
    "code": 24,
    "date": "21-Sep-1993",
    "plane_type": "Tupolev-134A",
    "location": "Sukhumi-Babusheri Airport",
    "country": "Georgia",
    "registration": "65809",
    "operator": "Transair Georgia",
    "fat": "27",
    "f": 27,
    "sqrt+10": 15.2,
    "px": 25,
    "PX": 35,
    "cat": "C1",
    "phase": "approach",
    "certainty": "confirmed",
    "FIELD16": "",
    "meta": "criminal",
    "cause": "heat seeking missile",
    "interesting notes": "",
    "FIELD20": "",
    "Cause sources": "",
    "FIELD22": ""
  },
  {
    "code": 25,
    "date": "22-Sep-1993",
    "plane_type": "Tupolev-154B",
    "location": "Sukhumi-Babusheri Airport",
    "country": "Georgia",
    "registration": "85163",
    "operator": "Transair Georgia",
    "fat": "108",
    "f": 108,
    "sqrt+10": 20.4,
    "px": 30,
    "PX": 65,
    "cat": "C1",
    "phase": "approach",
    "certainty": "confirmed",
    "FIELD16": "",
    "meta": "criminal",
    "cause": "shot down",
    "interesting notes": "",
    "FIELD20": "",
    "Cause sources": "",
    "FIELD22": ""
  },
  {
    "code": 26,
    "date": "23-Sep-1993",
    "plane_type": "Tupolev-154B-2",
    "location": "Sukhumi-Babusheri Airport",
    "country": "Georgia",
    "registration": "85359",
    "operator": "Orbi Georgian Airways",
    "fat": "1",
    "f": 1,
    "sqrt+10": 11,
    "px": 21,
    "PX": 15,
    "cat": "C1",
    "phase": "grounded",
    "certainty": "suspected",
    "FIELD16": "",
    "meta": "criminal",
    "cause": "mortar or artillery fire",
    "interesting notes": "",
    "FIELD20": "",
    "Cause sources": "",
    "FIELD22": ""
  },
  {
    "code": 27,
    "date": "26-Oct-1993",
    "plane_type": "McDonnell Douglas MD-82",
    "location": "Fuzhou, Fujian",
    "country": "China",
    "registration": "B-2103",
    "operator": "China Eastern Airlines",
    "fat": "2",
    "f": 2,
    "sqrt+10": 11.4,
    "px": 21,
    "PX": 15,
    "cat": "A1",
    "phase": "landing",
    "certainty": "probable",
    "FIELD16": "",
    "meta": "weather",
    "cause": "wet runway",
    "interesting notes": "",
    "FIELD20": "",
    "Cause sources": "",
    "FIELD22": ""
  },
  {
    "code": 28,
    "date": "27-Oct-1993",
    "plane_type": "de Havilland Canada DHC-6-300 Twin Otter",
    "location": "near Namsos Airport",
    "country": "Norway",
    "registration": "LN-BNM",
    "operator": "Widerøe",
    "fat": "6",
    "f": 6,
    "sqrt+10": 12.4,
    "px": 22,
    "PX": 25,
    "cat": "A1",
    "phase": "approach",
    "certainty": "probable",
    "FIELD16": "",
    "meta": "human_error",
    "cause": "pilot & company error",
    "interesting notes": "",
    "FIELD20": "",
    "Cause sources": "",
    "FIELD22": ""
  },
  {
    "code": 29,
    "date": "10-Nov-1993",
    "plane_type": "Hawker Siddeley HS-748-234 Srs",
    "location": "near Sandy Lake, ON",
    "country": "Canada",
    "registration": "C-GQTH",
    "operator": "Air Manitoba",
    "fat": "7",
    "f": 7,
    "sqrt+10": 12.6,
    "px": 23,
    "PX": 25,
    "cat": "A1",
    "phase": "initial climb",
    "certainty": "probable",
    "FIELD16": "",
    "meta": "human_error",
    "cause": "pilot & crew error",
    "interesting notes": "",
    "FIELD20": "",
    "Cause sources": "",
    "FIELD22": ""
  },
  {
    "code": 30,
    "date": "13-Nov-1993",
    "plane_type": "McDonnell-Douglas MD-82",
    "location": "near Urumqi Airport",
    "country": "China",
    "registration": "B-2141",
    "operator": "China Northern Airlines",
    "fat": "12",
    "f": 12,
    "sqrt+10": 13.5,
    "px": 23,
    "PX": 25,
    "cat": "A1",
    "phase": "approach",
    "certainty": "probable",
    "FIELD16": "",
    "meta": "mechanical",
    "cause": "autopilot failure",
    "interesting notes": "",
    "FIELD20": "",
    "Cause sources": "",
    "FIELD22": ""
  },
  {
    "code": 31,
    "date": "20-Nov-1993",
    "plane_type": "Yakovlev Yak-42",
    "location": "near Ohrid",
    "country": "Macedonia",
    "registration": "RA-42390",
    "operator": "Avioimpex",
    "fat": "116",
    "f": 116,
    "sqrt+10": 20.8,
    "px": 31,
    "PX": 65,
    "cat": "A1",
    "phase": "approach",
    "certainty": "probable",
    "FIELD16": "",
    "meta": "human_error",
    "cause": "pilot error",
    "interesting notes": "",
    "FIELD20": "",
    "Cause sources": "",
    "FIELD22": ""
  },
  {
    "code": 32,
    "date": "1-Dec-1993",
    "plane_type": "Jetstream 31",
    "location": "near Hibbing, Minnesota",
    "country": "USA",
    "registration": "N334PX",
    "operator": "Northwest Airlink",
    "fat": "18",
    "f": 18,
    "sqrt+10": 14.2,
    "px": 24,
    "PX": 35,
    "cat": "A1",
    "phase": "approach",
    "certainty": "confirmed",
    "FIELD16": "",
    "meta": "human_error",
    "cause": "pilot & company error",
    "interesting notes": "",
    "FIELD20": "",
    "Cause sources": "",
    "FIELD22": ""
  },
  {
    "code": 33,
    "date": "3-Dec-1993",
    "plane_type": "BN-2A-20 Islander",
    "location": "near Tuktoyaktuk, NT",
    "country": "Canada",
    "registration": "C-GMOP",
    "operator": "Arctic Wings and Rotors",
    "fat": "7",
    "f": 7,
    "sqrt+10": 12.6,
    "px": 23,
    "PX": 25,
    "cat": "A1",
    "phase": "en route",
    "certainty": "probable",
    "FIELD16": "",
    "meta": "mechanical",
    "cause": "engine failure",
    "interesting notes": "",
    "FIELD20": "",
    "Cause sources": "",
    "FIELD22": ""
  },
  {
    "code": 34,
    "date": "9-Dec-1993",
    "plane_type": "DHC-6 Twin Otter 300",
    "location": "near Dakar-Yoff A",
    "country": "Senegal",
    "registration": "6V-ADE",
    "operator": "Air Senegal",
    "fat": "3",
    "f": 3,
    "sqrt+10": 11.7,
    "px": 22,
    "PX": 15,
    "cat": "A1",
    "phase": "approach",
    "certainty": "confirmed",
    "FIELD16": "",
    "meta": "human_error",
    "cause": "pilot error",
    "interesting notes": "",
    "FIELD20": "",
    "Cause sources": "",
    "FIELD22": ""
  },
  {
    "code": 35,
    "date": "13-Dec-1993",
    "plane_type": "Harbin Yunshuji Y-12-II",
    "location": "Phonesavanh",
    "country": "Laos",
    "registration": "RDPL-34117",
    "operator": "Lao Aviation",
    "fat": "18",
    "f": 18,
    "sqrt+10": 14.2,
    "px": 24,
    "PX": 35,
    "cat": "A1",
    "phase": "approach",
    "certainty": "probable",
    "FIELD16": "",
    "meta": "weather",
    "cause": "low visibility",
    "interesting notes": "",
    "FIELD20": "",
    "Cause sources": "",
    "FIELD22": ""
  },
  {
    "code": 36,
    "date": "26-Dec-1993",
    "plane_type": "Antonov 26B",
    "location": "Gyumri-Lenin",
    "country": "Armenia",
    "registration": "CCCP-26141",
    "operator": "Kuban Airlines",
    "fat": "35",
    "f": 35,
    "sqrt+10": 15.9,
    "px": 26,
    "PX": 45,
    "cat": "A1",
    "phase": "landing",
    "certainty": "probable",
    "FIELD16": "",
    "meta": "human_error",
    "cause": "overload",
    "interesting notes": "",
    "FIELD20": "",
    "Cause sources": "",
    "FIELD22": ""
  },
  {
    "code": 37,
    "date": "3-Jan-1994",
    "plane_type": "Tupolev154M",
    "location": "near Mamony",
    "country": "Russia",
    "registration": "RA-85656",
    "operator": "Baikal Airlines",
    "fat": "124+1",
    "f": 125,
    "sqrt+10": 21.2,
    "px": 31,
    "PX": 65,
    "cat": "A1",
    "phase": "en route",
    "certainty": "confirmed",
    "FIELD16": "",
    "meta": "human_error",
    "cause": "crew & company error",
    "interesting notes": "",
    "FIELD20": "",
    "Cause sources": "",
    "FIELD22": ""
  },
  {
    "code": 38,
    "date": "7-Jan-1994",
    "plane_type": "British Aerospace 4101 Jetstre",
    "location": "near Columbus-Por",
    "country": "USA",
    "registration": "N304UE",
    "operator": "Atlantic Coast Airlines, opf. United Express",
    "fat": "5",
    "f": 5,
    "sqrt+10": 12.2,
    "px": 22,
    "PX": 15,
    "cat": "A1",
    "phase": "approach",
    "certainty": "probable",
    "FIELD16": "",
    "meta": "human_error",
    "cause": "pilot & crew error",
    "interesting notes": "",
    "FIELD20": "",
    "Cause sources": "",
    "FIELD22": ""
  },
  {
    "code": 39,
    "date": "25-Feb-1994",
    "plane_type": "Yakovlev 40",
    "location": "near Tingo Maria",
    "country": "Peru",
    "registration": "OB-1559",
    "operator": "Expresso Aéreo",
    "fat": "31",
    "f": 31,
    "sqrt+10": 15.6,
    "px": 26,
    "PX": 45,
    "cat": "A1",
    "phase": "en route",
    "certainty": "suspected",
    "FIELD16": "",
    "meta": "human_error",
    "cause": "pilot error",
    "interesting notes": "",
    "FIELD20": "",
    "Cause sources": "",
    "FIELD22": ""
  },
  {
    "code": 40,
    "date": "7-May-1994",
    "plane_type": "Embraer 110EJ Bandeirante",
    "location": "São Gabriel",
    "country": "Brazil",
    "registration": "PT-GJW",
    "operator": "RICO",
    "fat": "8",
    "f": 8,
    "sqrt+10": 12.8,
    "px": 23,
    "PX": 25,
    "cat": "A1",
    "phase": "landing",
    "certainty": "unknown",
    "FIELD16": "",
    "meta": "unknown",
    "cause": "unknown",
    "interesting notes": "",
    "FIELD20": "",
    "Cause sources": "",
    "FIELD22": ""
  },
  {
    "code": 41,
    "date": "23-Mar-1994",
    "plane_type": "Airbus A310-304",
    "location": "Mezhdurechensk",
    "country": "Russia",
    "registration": "F-OGQS",
    "operator": "Aeroflot",
    "fat": "75",
    "f": 75,
    "sqrt+10": 18.7,
    "px": 29,
    "PX": 55,
    "cat": "A1",
    "phase": "en route",
    "certainty": "confirmed",
    "FIELD16": "",
    "meta": "human_error",
    "cause": "pilot error",
    "interesting notes": "",
    "FIELD20": "",
    "Cause sources": "",
    "FIELD22": ""
  },
  {
    "code": 42,
    "date": "4-Apr-1994",
    "plane_type": "Saab 340B",
    "location": "Amsterdamn Schiphol Airport",
    "country": "Netherlands",
    "registration": "PH-KSH",
    "operator": "KLM Cityhopper",
    "fat": "3",
    "f": 3,
    "sqrt+10": 11.7,
    "px": 22,
    "PX": 15,
    "cat": "A1",
    "phase": "landing",
    "certainty": "probable",
    "FIELD16": "",
    "meta": "human_error",
    "cause": "pilot & crew error",
    "interesting notes": "",
    "FIELD20": "",
    "Cause sources": "",
    "FIELD22": ""
  },
  {
    "code": 43,
    "date": "25-Apr-1994",
    "plane_type": "IRMA/BN-2A-21 Islander",
    "location": "near Nangapinoh",
    "country": "Indonesia",
    "registration": "PK-ZAA",
    "operator": "Dirgantara Air Services",
    "fat": "10",
    "f": 10,
    "sqrt+10": 13.2,
    "px": 23,
    "PX": 25,
    "cat": "A1",
    "phase": "en route",
    "certainty": "probable",
    "FIELD16": "",
    "meta": "human_error",
    "cause": "pilot error",
    "interesting notes": "",
    "FIELD20": "",
    "Cause sources": "",
    "FIELD22": ""
  },
  {
    "code": 44,
    "date": "26-Apr-1994",
    "plane_type": "Airbus A300b4-622r",
    "location": "Nagoya-Komaki",
    "country": "Japan",
    "registration": "B-1816",
    "operator": "China Airlines",
    "fat": "264",
    "f": 264,
    "sqrt+10": 26.2,
    "px": 36,
    "PX": 85,
    "cat": "A1",
    "phase": "approach",
    "certainty": "probable",
    "FIELD16": "",
    "meta": "human_error",
    "cause": "pilot error",
    "interesting notes": "",
    "FIELD20": "",
    "Cause sources": "",
    "FIELD22": ""
  },
  {
    "code": 45,
    "date": "28-May-1994",
    "plane_type": "Douglas C-53D-DO (DC-3)",
    "location": "near Villavicencio",
    "country": "Colombia",
    "registration": "HK-2213",
    "operator": "Transoriente Colombia",
    "fat": "7",
    "f": 7,
    "sqrt+10": 12.6,
    "px": 23,
    "PX": 25,
    "cat": "A1",
    "phase": "initial climb",
    "certainty": "probable",
    "FIELD16": "",
    "meta": "mechanical",
    "cause": "engine failure",
    "interesting notes": "",
    "FIELD20": "",
    "Cause sources": "",
    "FIELD22": ""
  },
  {
    "code": 46,
    "date": "6-Jun-1994",
    "plane_type": "Tupolev Tu-154M",
    "location": "Near Xian",
    "country": "China",
    "registration": "B-2610",
    "operator": "China Northwest Airlines",
    "fat": "160",
    "f": 160,
    "sqrt+10": 22.6,
    "px": 33,
    "PX": 75,
    "cat": "A1",
    "phase": "en route",
    "certainty": "probable",
    "FIELD16": "",
    "meta": "mechanical",
    "cause": "maintenance error",
    "interesting notes": "",
    "FIELD20": "",
    "Cause sources": "",
    "FIELD22": ""
  },
  {
    "code": 47,
    "date": "13-Jun-1994",
    "plane_type": "Swearingen SA226-TC Metro II",
    "location": "near Uruapan Airport",
    "country": "Mexico",
    "registration": "XA-SLU",
    "operator": "Aero Cuahonte",
    "fat": "9",
    "f": 9,
    "sqrt+10": 13,
    "px": 23,
    "PX": 25,
    "cat": "A1",
    "phase": "approach",
    "certainty": "probable",
    "FIELD16": "",
    "meta": "weather",
    "cause": "low visibility",
    "interesting notes": "",
    "FIELD20": "",
    "Cause sources": "",
    "FIELD22": ""
  },
  {
    "code": 48,
    "date": "18-Jun-1994",
    "plane_type": "Fokker F-27 Friendship 500F",
    "location": "Mount Kalora",
    "country": "Indonesia",
    "registration": "PK-MFI",
    "operator": "Merpati Nusantara",
    "fat": "12",
    "f": 12,
    "sqrt+10": 13.5,
    "px": 23,
    "PX": 25,
    "cat": "A1",
    "phase": "approach",
    "certainty": "probable",
    "FIELD16": "",
    "meta": "weather",
    "cause": "low visibility",
    "interesting notes": "",
    "FIELD20": "",
    "Cause sources": "",
    "FIELD22": ""
  },
  {
    "code": 49,
    "date": "23-Jun-1994",
    "plane_type": "DHC-3 Otter",
    "location": "Taku Inlet, AK",
    "country": "USA",
    "registration": "N13GA ",
    "operator": "Wings of Alaska",
    "fat": "7",
    "f": 7,
    "sqrt+10": 12.6,
    "px": 23,
    "PX": 25,
    "cat": "A1",
    "phase": "en route",
    "certainty": "probable",
    "FIELD16": "",
    "meta": "weather",
    "cause": "low visibility",
    "interesting notes": "",
    "FIELD20": "",
    "Cause sources": "",
    "FIELD22": ""
  },
  {
    "code": 50,
    "date": "26-Jun-1994",
    "plane_type": "Fokker F-27 Friendship 400M",
    "location": "near Abidjan-Felix Houphouet Boigny Airport ",
    "country": "Ivory Coast",
    "registration": "TU-TIP",
    "operator": "Air Ivoire",
    "fat": "17",
    "f": 17,
    "sqrt+10": 14.1,
    "px": 24,
    "PX": 35,
    "cat": "A1",
    "phase": "approach",
    "certainty": "suspected",
    "FIELD16": "",
    "meta": "human_error",
    "cause": "pilot error",
    "interesting notes": "",
    "FIELD20": "",
    "Cause sources": "",
    "FIELD22": ""
  },
  {
    "code": 51,
    "date": "1-Jul-1994",
    "plane_type": "Fokker F-28 Fellowship 4000",
    "location": "Tidjikja Airport",
    "country": "Mauritania",
    "registration": "5T-CLF",
    "operator": "Air Mauritanie",
    "fat": "80",
    "f": 80,
    "sqrt+10": 18.9,
    "px": 29,
    "PX": 55,
    "cat": "A1",
    "phase": "landing",
    "certainty": "probable",
    "FIELD16": "",
    "meta": "weather",
    "cause": "low visibility",
    "interesting notes": "",
    "FIELD20": "",
    "Cause sources": "",
    "FIELD22": ""
  },
  {
    "code": 52,
    "date": "2-Jul-1994",
    "plane_type": "McMcDonnell Douglas DC-9-31",
    "location": "Charlotte, NC",
    "country": "USA",
    "registration": "N954VJ",
    "operator": "USAir",
    "fat": "37",
    "f": 37,
    "sqrt+10": 16.1,
    "px": 26,
    "PX": 45,
    "cat": "A1",
    "phase": "approach",
    "certainty": "confirmed",
    "FIELD16": "",
    "meta": "human_error",
    "cause": "pilot error & windshear",
    "interesting notes": "",
    "FIELD20": "",
    "Cause sources": "",
    "FIELD22": ""
  },
  {
    "code": 53,
    "date": "17-Jul-1994",
    "plane_type": "IRMA/Pilatus BN-2B-26 Islander",
    "location": "near Fort de France-Lamentin Airport",
    "country": "Martinique",
    "registration": "8P-TAD",
    "operator": "Trans Island Air, opf. Air Martinique",
    "fat": "6",
    "f": 6,
    "sqrt+10": 12.4,
    "px": 22,
    "PX": 25,
    "cat": "A1",
    "phase": "approach",
    "certainty": "confirmed",
    "FIELD16": "",
    "meta": "human_error",
    "cause": "pilot error",
    "interesting notes": "",
    "FIELD20": "",
    "Cause sources": "",
    "FIELD22": ""
  },
  {
    "code": 54,
    "date": "19-Jul-1994",
    "plane_type": "mbraer 110P1 Bandeirante",
    "location": "near Colon-Enrique A Jimenez Airport",
    "country": "Panama",
    "registration": "HP-1202AC",
    "operator": "Alas Chiricanas",
    "fat": "21",
    "f": 21,
    "sqrt+10": 14.6,
    "px": 25,
    "PX": 35,
    "cat": "C1",
    "phase": "en route",
    "certainty": "suspected",
    "FIELD16": "",
    "meta": "criminal",
    "cause": "suicide bomber",
    "interesting notes": "",
    "FIELD20": "",
    "Cause sources": "",
    "FIELD22": ""
  },
  {
    "code": 55,
    "date": "21-Aug-1994",
    "plane_type": "ATR 42-312",
    "location": "Atlas Mountains, near Tizounine",
    "country": "Morocco",
    "registration": "CN-CDT",
    "operator": "Royal Air Maroc",
    "fat": "44",
    "f": 44,
    "sqrt+10": 16.6,
    "px": 27,
    "PX": 45,
    "cat": "A1",
    "phase": "en route",
    "certainty": "official, contested",
    "FIELD16": "",
    "meta": "human_error",
    "cause": "intentional pilot error",
    "interesting notes": "",
    "FIELD20": "",
    "Cause sources": "",
    "FIELD22": ""
  },
  {
    "code": 56,
    "date": "8-Sep-1994",
    "plane_type": "Boeing 737-3B7",
    "location": "near Aliquippa, PA",
    "country": "USA",
    "registration": "N513AU",
    "operator": "USAir",
    "fat": "132",
    "f": 132,
    "sqrt+10": 21.5,
    "px": 31,
    "PX": 75,
    "cat": "A1",
    "phase": "approach",
    "certainty": "probable",
    "FIELD16": "",
    "meta": "mechanical",
    "cause": "rudder failure",
    "interesting notes": "",
    "FIELD20": "",
    "Cause sources": "",
    "FIELD22": ""
  },
  {
    "code": 57,
    "date": "13-Sep-1994",
    "plane_type": "DHC-6 Twin Otter 300",
    "location": "near Abuja Intern",
    "country": "Nigeria",
    "registration": "5N-ATQ",
    "operator": "Aerocontractors",
    "fat": "2",
    "f": 2,
    "sqrt+10": 11.4,
    "px": 21,
    "PX": 15,
    "cat": "A1",
    "phase": "approach",
    "certainty": "probable",
    "FIELD16": "",
    "meta": "human_error",
    "cause": "pilot & crew error",
    "interesting notes": "",
    "FIELD20": "",
    "Cause sources": "",
    "FIELD22": ""
  },
  {
    "code": 58,
    "date": "18-Sep-1994",
    "plane_type": "BAC One-Eleven 515FB",
    "location": "Tamanrasset-Aguemar Airport",
    "country": "Algeria",
    "registration": "5N-IMO",
    "operator": "Oriental Airlines",
    "fat": "5",
    "f": 5,
    "sqrt+10": 12.2,
    "px": 22,
    "PX": 15,
    "cat": "A1",
    "phase": "approach",
    "certainty": "suspected",
    "FIELD16": "",
    "meta": "weather",
    "cause": "low visibility",
    "interesting notes": "",
    "FIELD20": "",
    "Cause sources": "",
    "FIELD22": ""
  },
  {
    "code": 59,
    "date": "26-Sep-1994",
    "plane_type": "Douglas C-47D",
    "location": "Bahia Negra ",
    "country": "Paraguay",
    "registration": "FAP2009",
    "operator": "TAM Paraguay",
    "fat": "1",
    "f": 1,
    "sqrt+10": 11,
    "px": 21,
    "PX": 15,
    "cat": "A1",
    "phase": "takeoff",
    "certainty": "confirmed",
    "FIELD16": "",
    "meta": "mechanical",
    "cause": "engine failure",
    "interesting notes": "",
    "FIELD20": "",
    "Cause sources": "",
    "FIELD22": ""
  },
  {
    "code": 60,
    "date": "26-Sep-1994",
    "plane_type": "Yakovlev 40",
    "location": "near Vanavara",
    "country": "Russia",
    "registration": "RA-87468",
    "operator": "Cheremshanka Airlines",
    "fat": "28",
    "f": 28,
    "sqrt+10": 15.3,
    "px": 25,
    "PX": 35,
    "cat": "A1",
    "phase": "en route",
    "certainty": "probable",
    "FIELD16": "",
    "meta": "human_error",
    "cause": "pilot & crew error",
    "interesting notes": "",
    "FIELD20": "",
    "Cause sources": "",
    "FIELD22": ""
  },
  {
    "code": 61,
    "date": "29-Sep-1994",
    "plane_type": "Rockwell 1121B Jet Commander",
    "location": "near Córdoba-Paja",
    "country": "Argentina",
    "registration": "LV-WEN",
    "operator": "Radeair",
    "fat": "8",
    "f": 8,
    "sqrt+10": 12.8,
    "px": 23,
    "PX": 25,
    "cat": "A1",
    "phase": "takeoff",
    "certainty": "confirmed",
    "FIELD16": "",
    "meta": "human_error",
    "cause": "pilot & crew error",
    "interesting notes": "",
    "FIELD20": "",
    "Cause sources": "",
    "FIELD22": ""
  },
  {
    "code": 62,
    "date": "12-Oct-1994",
    "plane_type": "Fokker F-28 Fellowship",
    "location": "near Natanz",
    "country": "Iran",
    "registration": "EP-PAV",
    "operator": "Iran Aseman Airlines",
    "fat": "66",
    "f": 66,
    "sqrt+10": 18.1,
    "px": 28,
    "PX": 55,
    "cat": "A1",
    "phase": "en route",
    "certainty": "confirmed",
    "FIELD16": "",
    "meta": "mechanical",
    "cause": "engine loss",
    "interesting notes": "",
    "FIELD20": "",
    "Cause sources": "",
    "FIELD22": ""
  },
  {
    "code": 63,
    "date": "31-Oct-1994",
    "plane_type": "ATR 72-212",
    "location": "near Roselawn, IN",
    "country": "USA",
    "registration": "N401AM",
    "operator": "Simmons Airlines",
    "fat": "68",
    "f": 68,
    "sqrt+10": 18.2,
    "px": 28,
    "PX": 55,
    "cat": "A1",
    "phase": "approach",
    "certainty": "confirmed",
    "FIELD16": "",
    "meta": "weather",
    "cause": "atmospheric icing",
    "interesting notes": "",
    "FIELD20": "",
    "Cause sources": "",
    "FIELD22": ""
  },
  {
    "code": 64,
    "date": "5-Nov-1994",
    "plane_type": "Yakovlev 40",
    "location": "Saposoa Airp",
    "country": "Peru",
    "registration": "OB-1569",
    "operator": "Servicios Aéreos Amazonicos",
    "fat": "6",
    "f": 6,
    "sqrt+10": 12.4,
    "px": 22,
    "PX": 25,
    "cat": "A1",
    "phase": "landing",
    "certainty": "probable",
    "FIELD16": "",
    "meta": "human_error",
    "cause": "pedestrian on runway",
    "interesting notes": "",
    "FIELD20": "",
    "Cause sources": "",
    "FIELD22": ""
  },
  {
    "code": 65,
    "date": "22-Nov-1994",
    "plane_type": "IRMA/BN-2A-20 Islander",
    "location": "near Tabubil",
    "country": "Papua New Guinea",
    "registration": "P2-SWC",
    "operator": "Southwest Air",
    "fat": "7",
    "f": 7,
    "sqrt+10": 12.6,
    "px": 23,
    "PX": 25,
    "cat": "A1",
    "phase": "en route",
    "certainty": "probable",
    "FIELD16": "",
    "meta": "weather",
    "cause": "bad weather",
    "interesting notes": "",
    "FIELD20": "",
    "Cause sources": "",
    "FIELD22": ""
  },
  {
    "code": 66,
    "date": "10-Dec-1994",
    "plane_type": "Boeing 747-283B",
    "location": "near Minami Daito Island",
    "country": "Japan",
    "registration": "EI-BWF",
    "operator": "Philippine Airlines",
    "fat": "1",
    "f": 1,
    "sqrt+10": 11,
    "px": 21,
    "PX": 15,
    "cat": "C2",
    "phase": "en route",
    "certainty": "confirmed",
    "FIELD16": "",
    "meta": "criminal",
    "cause": "bombing",
    "interesting notes": "",
    "FIELD20": "",
    "Cause sources": "",
    "FIELD22": ""
  },
  {
    "code": 67,
    "date": "13-Dec-1994",
    "plane_type": "British Aerospace 3201 Jetstre",
    "location": "near Raleigh/Durh",
    "country": "USA",
    "registration": "N918AE",
    "operator": "Flagship Airlines, opf. American Eagle",
    "fat": "15",
    "f": 15,
    "sqrt+10": 13.9,
    "px": 24,
    "PX": 25,
    "cat": "A1",
    "phase": "approach",
    "certainty": "confirmed",
    "FIELD16": "",
    "meta": "human_error",
    "cause": "pilot error",
    "interesting notes": "",
    "FIELD20": "",
    "Cause sources": "",
    "FIELD22": ""
  },
  {
    "code": 68,
    "date": "17-Dec-1994",
    "plane_type": "DHC-6 Twin Otter 200",
    "location": "near Olsobip",
    "country": "Papua New Guinea",
    "registration": "P2-MFS",
    "operator": "MAF",
    "fat": "28",
    "f": 28,
    "sqrt+10": 15.3,
    "px": 25,
    "PX": 35,
    "cat": "A1",
    "phase": "en route",
    "certainty": "suspected",
    "FIELD16": "",
    "meta": "human_error",
    "cause": "pilot error",
    "interesting notes": "",
    "FIELD20": "",
    "Cause sources": "",
    "FIELD22": ""
  },
  {
    "code": 69,
    "date": "26-Dec-1994",
    "plane_type": "Airbus 1300B2-1c",
    "location": "Marseille-Marignane Airport",
    "country": "France",
    "registration": "F-GBEC",
    "operator": "Air France",
    "fat": "7",
    "f": 7,
    "sqrt+10": 12.6,
    "px": 23,
    "PX": 25,
    "cat": "H1",
    "phase": "grounded",
    "certainty": "confirmed",
    "FIELD16": "",
    "meta": "criminal",
    "cause": "hostage situation",
    "interesting notes": "",
    "FIELD20": "",
    "Cause sources": "",
    "FIELD22": ""
  },
  {
    "code": 70,
    "date": "29-Dec-1994",
    "plane_type": "Boeing 737-4Y0",
    "location": "near Van Ferit Melen Airport",
    "country": "Turkey",
    "registration": "TC-JES",
    "operator": "Turkish Airlines",
    "fat": "57",
    "f": 57,
    "sqrt+10": 17.5,
    "px": 28,
    "PX": 55,
    "cat": "A1",
    "phase": "approach",
    "certainty": "confirmed",
    "FIELD16": "",
    "meta": "weather",
    "cause": "low visibility",
    "interesting notes": "",
    "FIELD20": "",
    "Cause sources": "",
    "FIELD22": ""
  },
  {
    "code": 71,
    "date": "10-Jan-1995",
    "plane_type": "DHC-6 Twin Otter 300",
    "location": "Molo Strait",
    "country": "Indonesia",
    "registration": "PK-NUK",
    "operator": "Merpati Nusantara",
    "fat": "14",
    "f": 14,
    "sqrt+10": 13.7,
    "px": 24,
    "PX": 25,
    "cat": "A1",
    "phase": "en route",
    "certainty": "probable",
    "FIELD16": "",
    "meta": "weather",
    "cause": "bad weather",
    "interesting notes": "",
    "FIELD20": "",
    "Cause sources": "",
    "FIELD22": ""
  },
  {
    "code": 72,
    "date": "10-Jan-1995",
    "plane_type": "Douglas DC-9-14",
    "location": "near Maria La Baja",
    "country": "Colombia",
    "registration": "HK-3839X",
    "operator": "Intercontinental de Aviación",
    "fat": "51",
    "f": 51,
    "sqrt+10": 17.1,
    "px": 27,
    "PX": 55,
    "cat": "A1",
    "phase": "approach",
    "certainty": "probable",
    "FIELD16": "",
    "meta": "human_error",
    "cause": "crew error",
    "interesting notes": "",
    "FIELD20": "",
    "Cause sources": "",
    "FIELD22": ""
  },
  {
    "code": 73,
    "date": "17-Jan-1995",
    "plane_type": "DHC-6 Twin Otter 300",
    "location": "Kathmandu-Tr",
    "country": "Nepal",
    "registration": "9N-ABI",
    "operator": "Royal Nepal Airlines",
    "fat": "2",
    "f": 2,
    "sqrt+10": 11.4,
    "px": 21,
    "PX": 15,
    "cat": "A1",
    "phase": "takeoff",
    "certainty": "suspected",
    "FIELD16": "",
    "meta": "mechanical",
    "cause": "mechanical failure",
    "interesting notes": "",
    "FIELD20": "",
    "Cause sources": "",
    "FIELD22": ""
  },
  {
    "code": 74,
    "date": "20-Jan-1995",
    "plane_type": "Let L-410UVP",
    "location": "near Krasnoyarsk airport",
    "country": "Russia",
    "registration": "RA-67120",
    "operator": "Abakan Airlines",
    "fat": "3",
    "f": 3,
    "sqrt+10": 11.7,
    "px": 22,
    "PX": 15,
    "cat": "A1",
    "phase": "takeoff",
    "certainty": "confirmed",
    "FIELD16": "",
    "meta": "mechanical",
    "cause": "engine failure",
    "interesting notes": "",
    "FIELD20": "",
    "Cause sources": "",
    "FIELD22": ""
  },
  {
    "code": 75,
    "date": "28-Jan-1995",
    "plane_type": "Beechcraft 200 Super King Air",
    "location": "near Cafunfo Airp",
    "country": "Papua New Guinea",
    "registration": "D2-ECH",
    "operator": "SAL – Sociedade de Aviaçao Ligeira",
    "fat": "2",
    "f": 2,
    "sqrt+10": 11.4,
    "px": 21,
    "PX": 15,
    "cat": "C1",
    "phase": "initial climb",
    "certainty": "confirmed",
    "FIELD16": "",
    "meta": "criminal",
    "cause": "shot down",
    "interesting notes": "",
    "FIELD20": "",
    "Cause sources": "",
    "FIELD22": ""
  },
  {
    "code": 76,
    "date": "31-Mar-1995",
    "plane_type": "Airbus A310-324",
    "location": "Balotesti, Romania",
    "country": "Romania",
    "registration": "YR-LCC",
    "operator": "TAROM",
    "fat": "60",
    "f": 60,
    "sqrt+10": 17.7,
    "px": 28,
    "PX": 55,
    "cat": "A1",
    "phase": "en route",
    "certainty": "confirmed",
    "FIELD16": "",
    "meta": "human_error",
    "cause": "crew error",
    "interesting notes": "",
    "FIELD20": "",
    "Cause sources": "",
    "FIELD22": ""
  },
  {
    "code": 77,
    "date": "1-May-1995",
    "plane_type": "Swearingen SA227-CC Metro 23",
    "location": "Sioux Falls, ON",
    "country": "Canada",
    "registration": "C-GYYB",
    "operator": "Bearskin Airlines",
    "fat": "3",
    "f": 3,
    "sqrt+10": 11.7,
    "px": 22,
    "PX": 15,
    "cat": "A1",
    "phase": "approach",
    "certainty": "probable",
    "FIELD16": "",
    "meta": "human_error",
    "cause": "pilot error",
    "interesting notes": "",
    "FIELD20": "",
    "Cause sources": "",
    "FIELD22": ""
  },
  {
    "code": 78,
    "date": "24-May-1995",
    "plane_type": "Embraer 110P1 Bandeirante",
    "location": "near Leeds/Bradford Airport",
    "country": "UK",
    "registration": "G-OEAA",
    "operator": "Knight Air",
    "fat": "12",
    "f": 12,
    "sqrt+10": 13.5,
    "px": 23,
    "PX": 25,
    "cat": "A1",
    "phase": "en route",
    "certainty": "confirmed",
    "FIELD16": "",
    "meta": "mechanical",
    "cause": "instrument failure",
    "interesting notes": "",
    "FIELD20": "",
    "Cause sources": "",
    "FIELD22": ""
  },
  {
    "code": 79,
    "date": "9-Jun-1995",
    "plane_type": "de Havilland Canada DHC-8",
    "location": "Tararua Ranges, New Zealand",
    "country": "New Zealand",
    "registration": "ZK-NEY",
    "operator": "Ansett New Zealand",
    "fat": "4",
    "f": 4,
    "sqrt+10": 12,
    "px": 22,
    "PX": 15,
    "cat": "A1",
    "phase": "approach",
    "certainty": "confirmed",
    "FIELD16": "",
    "meta": "human_error",
    "cause": "pilot error",
    "interesting notes": "",
    "FIELD20": "",
    "Cause sources": "",
    "FIELD22": ""
  },
  {
    "code": 80,
    "date": "16-Jun-1995",
    "plane_type": "Antonov 2P",
    "location": "near Herpuchi",
    "country": "Russia",
    "registration": "RA-07743",
    "operator": "Aeroflot",
    "fat": "13",
    "f": 13,
    "sqrt+10": 13.6,
    "px": 24,
    "PX": 25,
    "cat": "A1",
    "phase": "en route",
    "certainty": "probable",
    "FIELD16": "",
    "meta": "weather",
    "cause": "low visibility",
    "interesting notes": "",
    "FIELD20": "",
    "Cause sources": "",
    "FIELD22": ""
  },
  {
    "code": 81,
    "date": "24-Jun-1995",
    "plane_type": "Tupolev 134A",
    "location": "Lagos-Murtal",
    "country": "Nigeria",
    "registration": "RA-65617",
    "operator": "Komi Avia, opf. Harka Air Services",
    "fat": "16",
    "f": 16,
    "sqrt+10": 14,
    "px": 24,
    "PX": 35,
    "cat": "A1",
    "phase": "landing",
    "certainty": "confirmed",
    "FIELD16": "",
    "meta": "weather",
    "cause": "bad weather",
    "interesting notes": "",
    "FIELD20": "",
    "Cause sources": "",
    "FIELD22": ""
  },
  {
    "code": 82,
    "date": "12-Jul-1995",
    "plane_type": "DHC-6 Twin Otter 300",
    "location": "near Alotau",
    "country": "Papua New Guinea",
    "registration": "P2-MBI",
    "operator": "Milne Bay Air",
    "fat": "15",
    "f": 15,
    "sqrt+10": 13.9,
    "px": 24,
    "PX": 25,
    "cat": "A1",
    "phase": "en route",
    "certainty": "confirmed",
    "FIELD16": "",
    "meta": "mechanical",
    "cause": "electrical fire",
    "interesting notes": "",
    "FIELD20": "",
    "Cause sources": "",
    "FIELD22": ""
  },
  {
    "code": 83,
    "date": "17-Jul-1995",
    "plane_type": "DHC-6 Twin Otter 300",
    "location": "Bintuni Airp",
    "country": "Indonesia",
    "registration": "PK-NUT",
    "operator": "Merpati Nusantara",
    "fat": "1",
    "f": 1,
    "sqrt+10": 11,
    "px": 21,
    "PX": 15,
    "cat": "A1",
    "phase": "takeoff",
    "certainty": "probable",
    "FIELD16": "",
    "meta": "weather",
    "cause": "wet runway",
    "interesting notes": "",
    "FIELD20": "",
    "Cause sources": "",
    "FIELD22": ""
  },
  {
    "code": 84,
    "date": "18-Jul-1995",
    "plane_type": "Douglas C-47A-80-DL",
    "location": "Antananarivo",
    "country": "Madagascar",
    "registration": "5R-MMG",
    "operator": "Madagascar AF",
    "fat": "36",
    "f": 36,
    "sqrt+10": 16,
    "px": 26,
    "PX": 45,
    "cat": "A1",
    "phase": "approach",
    "certainty": "suspected",
    "FIELD16": "",
    "meta": "human_error",
    "cause": "pilot error",
    "interesting notes": "",
    "FIELD20": "",
    "Cause sources": "",
    "FIELD22": ""
  },
  {
    "code": 85,
    "date": "9-Aug-1995",
    "plane_type": "Hawker Siddeley HS-748-216 Srs",
    "location": "Kumawa Mount",
    "country": "Indonesia",
    "registration": "PK-KHL",
    "operator": "Bouraq Indonesia",
    "fat": "10",
    "f": 10,
    "sqrt+10": 13.2,
    "px": 23,
    "PX": 25,
    "cat": "A1",
    "phase": "en route",
    "certainty": "suspected",
    "FIELD16": "",
    "meta": "human_error",
    "cause": "pilot & crew error",
    "interesting notes": "",
    "FIELD20": "",
    "Cause sources": "",
    "FIELD22": ""
  },
  {
    "code": 86,
    "date": "9-Aug-1995",
    "plane_type": "Boeing 737-2H6",
    "location": "San Vincent volcano",
    "country": "El Salvador",
    "registration": "N125GU",
    "operator": "Aviateca",
    "fat": "65",
    "f": 65,
    "sqrt+10": 18.1,
    "px": 28,
    "PX": 55,
    "cat": "A1",
    "phase": "approach",
    "certainty": "probable",
    "FIELD16": "",
    "meta": "human_error",
    "cause": "pilot & crew error",
    "interesting notes": "",
    "FIELD20": "",
    "Cause sources": "",
    "FIELD22": ""
  },
  {
    "code": 87,
    "date": "14-Aug-1995",
    "plane_type": "Embraer 110P1 Bandeirante",
    "location": "near Toez",
    "country": "Colombia",
    "registration": "HK-2594",
    "operator": "AIRES Colombia",
    "fat": "8",
    "f": 8,
    "sqrt+10": 12.8,
    "px": 23,
    "PX": 25,
    "cat": "A1",
    "phase": "en route",
    "certainty": "probable",
    "FIELD16": "",
    "meta": "human_error",
    "cause": "pilot & crew error",
    "interesting notes": "",
    "FIELD20": "",
    "Cause sources": "",
    "FIELD22": ""
  },
  {
    "code": 88,
    "date": "21-Aug-1995",
    "plane_type": "Embraer EMB-120 Brasília",
    "location": "Carroll County, GA",
    "country": "USA",
    "registration": "N256AS",
    "operator": "Atlantic Southeast Airlines",
    "fat": "9",
    "f": 9,
    "sqrt+10": 13,
    "px": 23,
    "PX": 25,
    "cat": "A1",
    "phase": "en route",
    "certainty": "confirmed",
    "FIELD16": "",
    "meta": "mechanical",
    "cause": "design flaw",
    "interesting notes": "",
    "FIELD20": "",
    "Cause sources": "",
    "FIELD22": ""
  },
  {
    "code": 89,
    "date": "9-Sep-1995",
    "plane_type": "CASA C-212 Aviocar 200",
    "location": "near La Macarena",
    "country": "Colombia",
    "registration": "FAC-1152",
    "operator": "SATENA",
    "fat": "22",
    "f": 22,
    "sqrt+10": 14.7,
    "px": 25,
    "PX": 35,
    "cat": "A1",
    "phase": "approach",
    "certainty": "probable",
    "FIELD16": "",
    "meta": "weather",
    "cause": "low visibility",
    "interesting notes": "",
    "FIELD20": "",
    "Cause sources": "",
    "FIELD22": ""
  },
  {
    "code": 90,
    "date": "11-Sep-1995",
    "plane_type": "Antonov 26B",
    "location": "near Jalalabad Ai",
    "country": "Afghanistan",
    "registration": "YA-BAO",
    "operator": "Ariana Afghan Airlines",
    "fat": "3",
    "f": 3,
    "sqrt+10": 11.7,
    "px": 22,
    "PX": 15,
    "cat": "A1",
    "phase": "approach",
    "certainty": "probable",
    "FIELD16": "",
    "meta": "human_error",
    "cause": "pilot & crew error",
    "interesting notes": "",
    "FIELD20": "",
    "Cause sources": "",
    "FIELD22": ""
  },
  {
    "code": 91,
    "date": "15-Sep-1995",
    "plane_type": "Fokker 50",
    "location": "Tawau Airport",
    "country": "Malaysia",
    "registration": "9M-MGH",
    "operator": "Malaysia Airlines",
    "fat": "34",
    "f": 34,
    "sqrt+10": 15.8,
    "px": 26,
    "PX": 45,
    "cat": "A1",
    "phase": "landing",
    "certainty": "probable",
    "FIELD16": "",
    "meta": "human_error",
    "cause": "pilot error",
    "interesting notes": "",
    "FIELD20": "",
    "Cause sources": "",
    "FIELD22": ""
  },
  {
    "code": 92,
    "date": "20-Sep-1995",
    "plane_type": "DHC-3 Otter",
    "location": "Salvesen Lak",
    "country": "Canada",
    "registration": "C-FGCV",
    "operator": "Walsten Air Service",
    "fat": "6",
    "f": 6,
    "sqrt+10": 12.4,
    "px": 22,
    "PX": 25,
    "cat": "A1",
    "phase": "approach",
    "certainty": "probable",
    "FIELD16": "",
    "meta": "mechanical",
    "cause": "engine failure",
    "interesting notes": "",
    "FIELD20": "",
    "Cause sources": "",
    "FIELD22": ""
  },
  {
    "code": 93,
    "date": "21-Sep-1995",
    "plane_type": "Antonov 24RV",
    "location": "Choho Geolog",
    "country": "Mongolia",
    "registration": "BNMAU-10103",
    "operator": "MIAT",
    "fat": "42",
    "f": 42,
    "sqrt+10": 16.5,
    "px": 26,
    "PX": 45,
    "cat": "A1",
    "phase": "approach",
    "certainty": "probable",
    "FIELD16": "",
    "meta": "human_error",
    "cause": "pilot error",
    "interesting notes": "",
    "FIELD20": "",
    "Cause sources": "",
    "FIELD22": ""
  },
  {
    "code": 94,
    "date": "8-Nov-1995",
    "plane_type": "Fokker F-27 Friendship 400M",
    "location": "near Villa Dolore",
    "country": "Argentina",
    "registration": "TC-72",
    "operator": "LADE",
    "fat": "53",
    "f": 53,
    "sqrt+10": 17.3,
    "px": 27,
    "PX": 55,
    "cat": "A1",
    "phase": "en route",
    "certainty": "probable",
    "FIELD16": "",
    "meta": "weather",
    "cause": "low visibility",
    "interesting notes": "",
    "FIELD20": "",
    "Cause sources": "",
    "FIELD22": ""
  },
  {
    "code": 95,
    "date": "13-Nov-1995",
    "plane_type": "Boeing 737-F29",
    "location": "Kaduna Airport",
    "country": "Nigeria",
    "registration": "5N-AUA",
    "operator": "Nigeria Airways",
    "fat": "11",
    "f": 11,
    "sqrt+10": 13.3,
    "px": 23,
    "PX": 25,
    "cat": "A1",
    "phase": "landing",
    "certainty": "probable",
    "FIELD16": "",
    "meta": "human_error",
    "cause": "pilot error",
    "interesting notes": "",
    "FIELD20": "",
    "Cause sources": "",
    "FIELD22": ""
  },
  {
    "code": 96,
    "date": "3-Dec-1995",
    "plane_type": "Boeing 737-200",
    "location": "Near Douala Intl Airport",
    "country": "Cameroon",
    "registration": "TJ-CBE",
    "operator": "Cameroon Airlines",
    "fat": "71",
    "f": 71,
    "sqrt+10": 18.4,
    "px": 28,
    "PX": 55,
    "cat": "A1",
    "phase": "approach",
    "certainty": "probable",
    "FIELD16": "",
    "meta": "mechanical",
    "cause": "mechanical failure",
    "interesting notes": "",
    "FIELD20": "",
    "Cause sources": "",
    "FIELD22": ""
  },
  {
    "code": 97,
    "date": "5-Dec-1995",
    "plane_type": "Tupolev 134b-3",
    "location": "near Nakhichevan Airport",
    "country": "Azerbaijan",
    "registration": "4K-65703",
    "operator": "Azerbaijan Airlines",
    "fat": "52",
    "f": 52,
    "sqrt+10": 17.2,
    "px": 27,
    "PX": 55,
    "cat": "A1",
    "phase": "initial climb",
    "certainty": "confirmed",
    "FIELD16": "",
    "meta": "mechanical",
    "cause": "maintenance error",
    "interesting notes": "",
    "FIELD20": "",
    "Cause sources": "",
    "FIELD22": ""
  },
  {
    "code": 98,
    "date": "7-Dec-1995",
    "plane_type": "Beech 1900D",
    "location": "near Belle-Anse",
    "country": "Haiti",
    "registration": "F-OHRK",
    "operator": "Air Saint Martin",
    "fat": "20",
    "f": 20,
    "sqrt+10": 14.5,
    "px": 24,
    "PX": 35,
    "cat": "A1",
    "phase": "en route",
    "certainty": "probable",
    "FIELD16": "",
    "meta": "human_error",
    "cause": "pilot & atc error",
    "interesting notes": "",
    "FIELD20": "",
    "Cause sources": "",
    "FIELD22": ""
  },
  {
    "code": 99,
    "date": "7-Dec-1995",
    "plane_type": "Tupolev 154B",
    "location": "near Grossevichi",
    "country": "Russia",
    "registration": "RA-85164",
    "operator": "Khabarovsk United Air",
    "fat": "98",
    "f": 98,
    "sqrt+10": 19.9,
    "px": 30,
    "PX": 65,
    "cat": "A1",
    "phase": "en route",
    "certainty": "confirmed",
    "FIELD16": "",
    "meta": "mechanical",
    "cause": "fuel imbalance",
    "interesting notes": "",
    "FIELD20": "",
    "Cause sources": "",
    "FIELD22": ""
  },
  {
    "code": 100,
    "date": "13-Dec-1995",
    "plane_type": "Antonov An-24B",
    "location": "near Verona Airport",
    "country": "Italy",
    "registration": "YA-AMR",
    "operator": "Banat Air",
    "fat": "49",
    "f": 49,
    "sqrt+10": 17,
    "px": 27,
    "PX": 45,
    "cat": "A1",
    "phase": "takeoff",
    "certainty": "probable",
    "FIELD16": "",
    "meta": "human_error",
    "cause": "pilot error",
    "interesting notes": "",
    "FIELD20": "",
    "Cause sources": "",
    "FIELD22": ""
  },
  {
    "code": 101,
    "date": "18-Dec-1995",
    "plane_type": "Lockheed L-188C Electra",
    "location": "Cahungula",
    "country": "Angola",
    "registration": "9Q-CRR",
    "operator": "Trans Service Airlift",
    "fat": "141",
    "f": 141,
    "sqrt+10": 21.9,
    "px": 32,
    "PX": 75,
    "cat": "A1",
    "phase": "unknown",
    "certainty": "probable",
    "FIELD16": "",
    "meta": "human_error",
    "cause": "overload",
    "interesting notes": "",
    "FIELD20": "",
    "Cause sources": "",
    "FIELD22": ""
  },
  {
    "code": 102,
    "date": "20-Dec-1995",
    "plane_type": "Boeing 757-223",
    "location": "near Buga, Valle del Cauca",
    "country": "Colombia",
    "registration": "N651AA",
    "operator": "American Airlines",
    "fat": "159",
    "f": 159,
    "sqrt+10": 22.6,
    "px": 33,
    "PX": 75,
    "cat": "A1",
    "phase": "approach",
    "certainty": "confirmed",
    "FIELD16": "",
    "meta": "human_error",
    "cause": "crew error",
    "interesting notes": "",
    "FIELD20": "",
    "Cause sources": "",
    "FIELD22": ""
  },
  {
    "code": 103,
    "date": "8-Jan-1996",
    "plane_type": "Antonov An-32B",
    "location": "N'Dolo Airport",
    "country": "DR Congo (then Zaire)",
    "registration": "RA-262222",
    "operator": "Moscow Airways",
    "fat": "0+237",
    "f": 237,
    "sqrt+10": 25.4,
    "px": 35,
    "PX": 85,
    "cat": "A1",
    "phase": "initial climb",
    "certainty": "probable",
    "FIELD16": "",
    "meta": "human_error",
    "cause": "overload",
    "interesting notes": "",
    "FIELD20": "",
    "Cause sources": "",
    "FIELD22": ""
  },
  {
    "code": 104,
    "date": "6-Feb-1996",
    "plane_type": "Boeing 757-225",
    "location": "near Puerto Plata",
    "country": "Dominican Republic",
    "registration": "TC-GEN",
    "operator": "Birgenair",
    "fat": "189",
    "f": 189,
    "sqrt+10": 23.7,
    "px": 34,
    "PX": 75,
    "cat": "A1",
    "phase": "en route",
    "certainty": "suspected",
    "FIELD16": "",
    "meta": "human_error",
    "cause": "pilot error",
    "interesting notes": "",
    "FIELD20": "",
    "Cause sources": "",
    "FIELD22": ""
  },
  {
    "code": 105,
    "date": "12-Feb-1996",
    "plane_type": "GAF Nomad N.24A",
    "location": "Port-au-Prince-Mais Gate Airport",
    "country": "Haiti",
    "registration": "N224E",
    "operator": "Haiti Express Airways",
    "fat": "10",
    "f": 10,
    "sqrt+10": 13.2,
    "px": 23,
    "PX": 25,
    "cat": "A1",
    "phase": "initial climb",
    "certainty": "suspected",
    "FIELD16": "",
    "meta": "mechanical",
    "cause": "mechanical failure",
    "interesting notes": "",
    "FIELD20": "",
    "Cause sources": "",
    "FIELD22": ""
  },
  {
    "code": 106,
    "date": "29-Feb-1996",
    "plane_type": "Boeing 737-222",
    "location": "near Arequipa",
    "country": "Peru",
    "registration": "OB-1451",
    "operator": "Faucett",
    "fat": "123",
    "f": 123,
    "sqrt+10": 21.1,
    "px": 31,
    "PX": 65,
    "cat": "A1",
    "phase": "approach",
    "certainty": "confirmed",
    "FIELD16": "",
    "meta": "human_error",
    "cause": "pilot error",
    "interesting notes": "",
    "FIELD20": "",
    "Cause sources": "",
    "FIELD22": ""
  },
  {
    "code": 107,
    "date": "2-Mar-1996",
    "plane_type": "Learjet 25D",
    "location": "near São Paulo-Gu",
    "country": "Brazil",
    "registration": "PT-LSD",
    "operator": "Madrid Táxi Aéreo",
    "fat": "9",
    "f": 9,
    "sqrt+10": 13,
    "px": 23,
    "PX": 25,
    "cat": "A1",
    "phase": "approach",
    "certainty": "suspected",
    "FIELD16": "",
    "meta": "human_error",
    "cause": "pilot & crew error",
    "interesting notes": "",
    "FIELD20": "",
    "Cause sources": "",
    "FIELD22": ""
  },
  {
    "code": 108,
    "date": "5-Apr-1996",
    "plane_type": "Dornier 228-212",
    "location": "near Matsu Airpor",
    "country": "Taiwan",
    "registration": "B-12257",
    "operator": "Formosa Airlines",
    "fat": "6",
    "f": 6,
    "sqrt+10": 12.4,
    "px": 22,
    "PX": 25,
    "cat": "A1",
    "phase": "approach",
    "certainty": "probable",
    "FIELD16": "",
    "meta": "human_error",
    "cause": "pilot & crew error",
    "interesting notes": "",
    "FIELD20": "",
    "Cause sources": "",
    "FIELD22": ""
  },
  {
    "code": 109,
    "date": "3-May-1996",
    "plane_type": "Antonov 24RV",
    "location": "near Haj Yousif",
    "country": "Sudan",
    "registration": "ST-FAG",
    "operator": "Federal Airlines",
    "fat": "53",
    "f": 53,
    "sqrt+10": 17.3,
    "px": 27,
    "PX": 55,
    "cat": "A1",
    "phase": "landing",
    "certainty": "confirmed",
    "FIELD16": "",
    "meta": "weather",
    "cause": "sandstorm",
    "interesting notes": "",
    "FIELD20": "",
    "Cause sources": "",
    "FIELD22": ""
  },
  {
    "code": 110,
    "date": "10-May-1996",
    "plane_type": "DHC-6 Twin Otter 200",
    "location": "Santa Maria",
    "country": "Mexico",
    "registration": "XA-SWJ",
    "operator": "Aeroservicios Emp. de Durango",
    "fat": "16",
    "f": 16,
    "sqrt+10": 14,
    "px": 24,
    "PX": 35,
    "cat": "A1",
    "phase": "approach",
    "certainty": "probable",
    "FIELD16": "",
    "meta": "weather",
    "cause": "bad weather",
    "interesting notes": "",
    "FIELD20": "",
    "Cause sources": "",
    "FIELD22": ""
  },
  {
    "code": 111,
    "date": "11-May-1996",
    "plane_type": "McDonnell Douglas DC-9",
    "location": "Everglades",
    "country": "USA",
    "registration": "N904VJ",
    "operator": "ValuJet",
    "fat": "110",
    "f": 110,
    "sqrt+10": 20.5,
    "px": 30,
    "PX": 65,
    "cat": "A1",
    "phase": "en route",
    "certainty": "suspected",
    "FIELD16": "",
    "meta": "mechanical",
    "cause": "cargo fire",
    "interesting notes": "",
    "FIELD20": "",
    "Cause sources": "",
    "FIELD22": ""
  },
  {
    "code": 112,
    "date": "13-Jun-1996",
    "plane_type": "McDonnell Douglas DC-10-30",
    "location": "Fukuoka Airport",
    "country": "Japan",
    "registration": "PK-GIE",
    "operator": "Garuda Indonesia",
    "fat": "3",
    "f": 3,
    "sqrt+10": 11.7,
    "px": 22,
    "PX": 15,
    "cat": "A1",
    "phase": "takeoff",
    "certainty": "suspected",
    "FIELD16": "",
    "meta": "human_error",
    "cause": "pilot error",
    "interesting notes": "",
    "FIELD20": "",
    "Cause sources": "",
    "FIELD22": ""
  },
  {
    "code": 113,
    "date": "21-Jun-1996",
    "plane_type": "Harbin Yunshuji Y-12-II",
    "location": "near Changhai Air",
    "country": "China",
    "registration": "B-3822",
    "operator": "China Flying Dragon",
    "fat": "2",
    "f": 2,
    "sqrt+10": 11.4,
    "px": 21,
    "PX": 15,
    "cat": "A1",
    "phase": "approach",
    "certainty": "confirmed",
    "FIELD16": "",
    "meta": "human_error",
    "cause": "pilot & crew error",
    "interesting notes": "",
    "FIELD20": "",
    "Cause sources": "",
    "FIELD22": ""
  },
  {
    "code": 114,
    "date": "6-Jul-1996",
    "plane_type": "McDonnell Douglas MD-88",
    "location": "near Pensacola, FL",
    "country": "USA",
    "registration": "N927DA",
    "operator": "Delta Airlines",
    "fat": "2",
    "f": 2,
    "sqrt+10": 11.4,
    "px": 21,
    "PX": 15,
    "cat": "A2",
    "phase": "takeoff",
    "certainty": "probable",
    "FIELD16": "",
    "meta": "human_error",
    "cause": "maintenance error",
    "interesting notes": "",
    "FIELD20": "",
    "Cause sources": "",
    "FIELD22": ""
  },
  {
    "code": 115,
    "date": "11-Jul-1996",
    "plane_type": "Let L-410UVP-E9D",
    "location": "near Kanda",
    "country": "India",
    "registration": "VT-ETC",
    "operator": "Archana Airways",
    "fat": "9",
    "f": 9,
    "sqrt+10": 13,
    "px": 23,
    "PX": 25,
    "cat": "A1",
    "phase": "approach",
    "certainty": "confirmed",
    "FIELD16": "",
    "meta": "human_error",
    "cause": "pilot & company error",
    "interesting notes": "",
    "FIELD20": "",
    "Cause sources": "",
    "FIELD22": ""
  },
  {
    "code": 116,
    "date": "17-Jul-1996",
    "plane_type": "Boeing 747-131",
    "location": "near East Moriches, NY",
    "country": "USA",
    "registration": "N93119",
    "operator": "Trans World Airlines",
    "fat": "230",
    "f": 230,
    "sqrt+10": 25.2,
    "px": 35,
    "PX": 85,
    "cat": "A1",
    "phase": "en route",
    "certainty": "confirmed",
    "FIELD16": "",
    "meta": "mechanical",
    "cause": "fuel tank explosion",
    "interesting notes": "",
    "FIELD20": "",
    "Cause sources": "",
    "FIELD22": ""
  },
  {
    "code": 117,
    "date": "24-Jul-1996",
    "plane_type": "Fokker F-27 Friendship 600",
    "location": "Myeik Airpor",
    "country": "Myanmar",
    "registration": "XY-AET",
    "operator": "Myanma Airways",
    "fat": "8",
    "f": 8,
    "sqrt+10": 12.8,
    "px": 23,
    "PX": 25,
    "cat": "A1",
    "phase": "landing",
    "certainty": "probable",
    "FIELD16": "",
    "meta": "weather",
    "cause": "bad weather",
    "interesting notes": "",
    "FIELD20": "",
    "Cause sources": "",
    "FIELD22": ""
  },
  {
    "code": 118,
    "date": "29-Aug-1996",
    "plane_type": "Tupolev Tu-154M",
    "location": "Operafjellet, Svalbard",
    "country": "Norway",
    "registration": "RA-85621",
    "operator": "Vnukovo Airlines",
    "fat": "141",
    "f": 141,
    "sqrt+10": 21.9,
    "px": 32,
    "PX": 75,
    "cat": "A1",
    "phase": "approach",
    "certainty": "suspected",
    "FIELD16": "",
    "meta": "human_error",
    "cause": "pilot & crew error",
    "interesting notes": "",
    "FIELD20": "",
    "Cause sources": "",
    "FIELD22": ""
  },
  {
    "code": 119,
    "date": "18-Sep-1996",
    "plane_type": "Fairchild C-123K Provider",
    "location": "Bahía Tortugas Airfield ",
    "country": "Mexico",
    "registration": "XA-SNB",
    "operator": "Krissalan de Aviacion",
    "fat": "5",
    "f": 5,
    "sqrt+10": 12.2,
    "px": 22,
    "PX": 15,
    "cat": "A1",
    "phase": "takeoff",
    "certainty": "unknown",
    "FIELD16": "",
    "meta": "unknown",
    "cause": "unknown",
    "interesting notes": "",
    "FIELD20": "",
    "Cause sources": "",
    "FIELD22": ""
  },
  {
    "code": 120,
    "date": "2-Oct-1996",
    "plane_type": "Boeing 757-23A",
    "location": "Pacific Ocean, near Lima",
    "country": "Pacific Ocean",
    "registration": "N52AW",
    "operator": "Aeroperú",
    "fat": "70",
    "f": 70,
    "sqrt+10": 18.4,
    "px": 28,
    "PX": 55,
    "cat": "A1",
    "phase": "en route",
    "certainty": "suspected",
    "FIELD16": "",
    "meta": "human_error",
    "cause": "maintenance error",
    "interesting notes": "",
    "FIELD20": "",
    "Cause sources": "",
    "FIELD22": ""
  },
  {
    "code": 121,
    "date": "26-Oct-1996",
    "plane_type": "Yakovlev 40K",
    "location": "Khanty-Mansiysk",
    "country": "Russia",
    "registration": "RA-88527",
    "operator": "Tyumenaviatrans",
    "fat": "5",
    "f": 5,
    "sqrt+10": 12.2,
    "px": 22,
    "PX": 15,
    "cat": "A1",
    "phase": "landing",
    "certainty": "confirmed",
    "FIELD16": "",
    "meta": "human_error",
    "cause": "pilot error",
    "interesting notes": "",
    "FIELD20": "",
    "Cause sources": "",
    "FIELD22": ""
  },
  {
    "code": 122,
    "date": "31-Oct-1996",
    "plane_type": "Fokker F28 Mk 0100",
    "location": "near São Paulo-Congonhas Airport",
    "country": "Brazil",
    "registration": "PT-MRK",
    "operator": "TAM Transportes Aéreos Regionais",
    "fat": "99+4",
    "f": 103,
    "sqrt+10": 20.1,
    "px": 30,
    "PX": 65,
    "cat": "A1",
    "phase": "initial climb",
    "certainty": "confirmed",
    "FIELD16": "",
    "meta": "mechanical",
    "cause": "engine failure",
    "interesting notes": "",
    "FIELD20": "",
    "Cause sources": "",
    "FIELD22": ""
  },
  {
    "code": 123,
    "date": "1-Nov-1996",
    "plane_type": "Embraer 110P1 Bandeirante",
    "location": "near Santa Elena",
    "country": "Guatemala",
    "registration": "TG-TPA",
    "operator": "TAPSA",
    "fat": "14",
    "f": 14,
    "sqrt+10": 13.7,
    "px": 24,
    "PX": 25,
    "cat": "A1",
    "phase": "approach",
    "certainty": "probable",
    "FIELD16": "",
    "meta": "weather",
    "cause": "low visibility",
    "interesting notes": "",
    "FIELD20": "",
    "Cause sources": "",
    "FIELD22": ""
  },
  {
    "code": 124,
    "date": "7-Nov-1996",
    "plane_type": "Boeing 727-231",
    "location": "near Ejirin",
    "country": "Nigeria",
    "registration": "5N-BBG",
    "operator": "ADC Airlines",
    "fat": "144",
    "f": 144,
    "sqrt+10": 22,
    "px": 32,
    "PX": 75,
    "cat": "A1",
    "phase": "approach",
    "certainty": "confirmed",
    "FIELD16": "",
    "meta": "human_error",
    "cause": "pilot & atc error",
    "interesting notes": "",
    "FIELD20": "",
    "Cause sources": "",
    "FIELD22": ""
  },
  {
    "code": 125,
    "date": "12-Nov-1996",
    "plane_type": "Ilyushin 76TD",
    "location": "Charkhi Dadri, Haryana",
    "country": "India",
    "registration": "UN-76435",
    "operator": "Kazakhstan Airlines",
    "fat": "37",
    "f": 37,
    "sqrt+10": 16.1,
    "px": 26,
    "PX": 45,
    "cat": "A1",
    "phase": "en route",
    "certainty": "confirmed",
    "FIELD16": "",
    "meta": "human_error",
    "cause": "pilot error, mid air collision",
    "interesting notes": "",
    "FIELD20": "",
    "Cause sources": "",
    "FIELD22": ""
  },
  {
    "code": 126,
    "date": "12-Nov-1996",
    "plane_type": "Boeing 747-168B",
    "location": "Charkhi Dadri, Haryana",
    "country": "India",
    "registration": "HZ-AIH",
    "operator": "Saudi Arabian Airlines",
    "fat": "312",
    "f": 312,
    "sqrt+10": 27.7,
    "px": 38,
    "PX": 100,
    "cat": "A1",
    "phase": "en route",
    "certainty": "confirmed",
    "FIELD16": "",
    "meta": "human_error",
    "cause": "pilot error, mid air collision",
    "interesting notes": "",
    "FIELD20": "",
    "Cause sources": "",
    "FIELD22": ""
  },
  {
    "code": 127,
    "date": "14-Nov-1996",
    "plane_type": "Antonov 2T",
    "location": "Most Pyssa",
    "country": "Russia",
    "registration": "RA-40309",
    "operator": "Komi Avia",
    "fat": "14",
    "f": 14,
    "sqrt+10": 13.7,
    "px": 24,
    "PX": 25,
    "cat": "A1",
    "phase": "initial climb",
    "certainty": "probable",
    "FIELD16": "",
    "meta": "human_error",
    "cause": "pilot & crew error",
    "interesting notes": "",
    "FIELD20": "",
    "Cause sources": "",
    "FIELD22": ""
  },
  {
    "code": 128,
    "date": "19-Nov-1996",
    "plane_type": "Beechcraft 1900",
    "location": "Quincy Municipal Airport, IL",
    "country": "USA",
    "registration": "N87GL",
    "operator": "United Express",
    "fat": "14",
    "f": 14,
    "sqrt+10": 13.7,
    "px": 24,
    "PX": 25,
    "cat": "A1",
    "phase": "landing",
    "certainty": "confirmed",
    "FIELD16": "",
    "meta": "human_error",
    "cause": "pilot error",
    "interesting notes": "",
    "FIELD20": "",
    "Cause sources": "",
    "FIELD22": ""
  },
  {
    "code": 129,
    "date": "23-Nov-1996",
    "plane_type": "Boeing 767-260ER",
    "location": "near Grande Comore",
    "country": "Comoros",
    "registration": "ET-AIZ",
    "operator": "Ethiopian Airlines",
    "fat": "125",
    "f": 125,
    "sqrt+10": 21.2,
    "px": 31,
    "PX": 65,
    "cat": "H1",
    "phase": "approach",
    "certainty": "confirmed",
    "FIELD16": "",
    "meta": "criminal",
    "cause": "hijack",
    "interesting notes": "",
    "FIELD20": "",
    "Cause sources": "",
    "FIELD22": ""
  },
  {
    "code": 130,
    "date": "30-Nov-1996",
    "plane_type": "DHC-6 Twin Otter 300",
    "location": "near Medellín",
    "country": "Colombia",
    "registration": "HK-2602",
    "operator": "ACES",
    "fat": "14",
    "f": 14,
    "sqrt+10": 13.7,
    "px": 24,
    "PX": 25,
    "cat": "A1",
    "phase": "en route",
    "certainty": "probable",
    "FIELD16": "",
    "meta": "human_error",
    "cause": "overload",
    "interesting notes": "",
    "FIELD20": "",
    "Cause sources": "",
    "FIELD22": ""
  },
  {
    "code": 131,
    "date": "7-Dec-1996",
    "plane_type": "CASA/Nurtanio NC-212-A4 Avioca",
    "location": "near Banjarmasin",
    "country": "Indonesia",
    "registration": "PK-VSO",
    "operator": "Dirgantara Air Services",
    "fat": "16+ 2",
    "f": 18,
    "sqrt+10": 14.2,
    "px": 24,
    "PX": 35,
    "cat": "A1",
    "phase": "approach",
    "certainty": "suspected",
    "FIELD16": "",
    "meta": "mechanical",
    "cause": "engine failure",
    "interesting notes": "",
    "FIELD20": "",
    "Cause sources": "",
    "FIELD22": ""
  },
  {
    "code": 132,
    "date": "7-Jan-1997",
    "plane_type": "DHC-6 Twin Otter 300",
    "location": "near Apia-Fagali'",
    "country": "Samoa",
    "registration": "5W-FAU",
    "operator": "Polynesian Airlines",
    "fat": "3",
    "f": 3,
    "sqrt+10": 11.7,
    "px": 22,
    "PX": 15,
    "cat": "A1",
    "phase": "en route",
    "certainty": "probable",
    "FIELD16": "",
    "meta": "human_error",
    "cause": "pilot error",
    "interesting notes": "",
    "FIELD20": "",
    "Cause sources": "",
    "FIELD22": ""
  },
  {
    "code": 133,
    "date": "9-Jan-1997",
    "plane_type": "Embraer EMB-120 Brasília",
    "location": "near Monroe, MI",
    "country": "USA",
    "registration": "N265CA",
    "operator": "Comair",
    "fat": "29",
    "f": 29,
    "sqrt+10": 15.4,
    "px": 25,
    "PX": 35,
    "cat": "A1",
    "phase": "approach",
    "certainty": "probable",
    "FIELD16": "",
    "meta": "weather",
    "cause": "atmospheric icing",
    "interesting notes": "",
    "FIELD20": "",
    "Cause sources": "",
    "FIELD22": ""
  },
  {
    "code": 134,
    "date": "31-Jan-1997",
    "plane_type": "Embraer 110P1A Bandeirante",
    "location": "near Yola Airport",
    "country": "Nigeria",
    "registration": "5N-AXS",
    "operator": "Skypower Express",
    "fat": "5",
    "f": 5,
    "sqrt+10": 12.2,
    "px": 22,
    "PX": 15,
    "cat": "A1",
    "phase": "approach",
    "certainty": "confirmed",
    "FIELD16": "",
    "meta": "human_error",
    "cause": "pilot error",
    "interesting notes": "",
    "FIELD20": "",
    "Cause sources": "",
    "FIELD22": ""
  },
  {
    "code": 135,
    "date": "14-Feb-1997",
    "plane_type": "Boeing 737-2C3",
    "location": "Carajás Airp",
    "country": "Brazil",
    "registration": "PP-CJO",
    "operator": "Varig",
    "fat": "1",
    "f": 1,
    "sqrt+10": 11,
    "px": 21,
    "PX": 15,
    "cat": "A1",
    "phase": "landing",
    "certainty": "probable",
    "FIELD16": "",
    "meta": "weather",
    "cause": "low visibility",
    "interesting notes": "",
    "FIELD20": "",
    "Cause sources": "",
    "FIELD22": ""
  },
  {
    "code": 136,
    "date": "18-Mar-1997",
    "plane_type": "Antonov AN-24",
    "location": "Cherkessk",
    "country": "Russia",
    "registration": "RA-46516",
    "operator": "Stavropolskaya Aktsionernaya Avia",
    "fat": "50",
    "f": 50,
    "sqrt+10": 17.1,
    "px": 27,
    "PX": 45,
    "cat": "A1",
    "phase": "en route",
    "certainty": "confirmed",
    "FIELD16": "",
    "meta": "human_error",
    "cause": "maintenance error",
    "interesting notes": "",
    "FIELD20": "",
    "Cause sources": "",
    "FIELD22": ""
  },
  {
    "code": 137,
    "date": "19-Apr-1997",
    "plane_type": "British Aerospace ATP",
    "location": "near Tanjung Pand",
    "country": "Indonesia",
    "registration": "PK-MTX",
    "operator": "Merpati Nusantara",
    "fat": "15",
    "f": 15,
    "sqrt+10": 13.9,
    "px": 24,
    "PX": 25,
    "cat": "A1",
    "phase": "approach",
    "certainty": "probable",
    "FIELD16": "",
    "meta": "human_error",
    "cause": "pilot error",
    "interesting notes": "",
    "FIELD20": "",
    "Cause sources": "",
    "FIELD22": ""
  },
  {
    "code": 138,
    "date": "8-May-1997",
    "plane_type": "Boeing 737-31B",
    "location": "Shenzhen, Guangdon",
    "country": "China",
    "registration": "B-2925",
    "operator": "China Southern Airlines",
    "fat": "35",
    "f": 35,
    "sqrt+10": 15.9,
    "px": 26,
    "PX": 45,
    "cat": "A1",
    "phase": "landing",
    "certainty": "confirmed",
    "FIELD16": "",
    "meta": "weather",
    "cause": "thunderstorm",
    "interesting notes": "",
    "FIELD20": "",
    "Cause sources": "",
    "FIELD22": ""
  },
  {
    "code": 139,
    "date": "6-Jun-1997",
    "plane_type": "Vickers 781D Viscount",
    "location": "near Irumu",
    "country": "DR Congo",
    "registration": "9Q-CWL",
    "operator": "Bazair",
    "fat": "23",
    "f": 23,
    "sqrt+10": 14.8,
    "px": 25,
    "PX": 35,
    "cat": "A1",
    "phase": "en route",
    "certainty": "unknown",
    "FIELD16": "",
    "meta": "unknown",
    "cause": "unknown",
    "interesting notes": "",
    "FIELD20": "",
    "Cause sources": "",
    "FIELD22": ""
  },
  {
    "code": 140,
    "date": "10-Jun-1997",
    "plane_type": "Harbin Yunshuji Y-12 II",
    "location": "Mandalgobi A",
    "country": "Mongolia",
    "registration": "JU-1020",
    "operator": "MIAT",
    "fat": "7",
    "f": 7,
    "sqrt+10": 12.6,
    "px": 23,
    "PX": 25,
    "cat": "A1",
    "phase": "approach",
    "certainty": "probable",
    "FIELD16": "",
    "meta": "human_error",
    "cause": "atc error",
    "interesting notes": "",
    "FIELD20": "",
    "Cause sources": "",
    "FIELD22": ""
  },
  {
    "code": 141,
    "date": "9-Jul-1997",
    "plane_type": "Fokker 100",
    "location": "near Suzano, SP",
    "country": "Brazil",
    "registration": "PT-WHK",
    "operator": "TAM",
    "fat": "1",
    "f": 1,
    "sqrt+10": 11,
    "px": 21,
    "PX": 15,
    "cat": "C2",
    "phase": "en route",
    "certainty": "confirmed",
    "FIELD16": "",
    "meta": "criminal",
    "cause": "attempted hijack",
    "interesting notes": "",
    "FIELD20": "",
    "Cause sources": "",
    "FIELD22": ""
  },
  {
    "code": 142,
    "date": "11-Jul-1997",
    "plane_type": "Antonov 24RV",
    "location": "near Santiago-Antonio Maceo Airport",
    "country": "Cuba",
    "registration": "CU-T1262",
    "operator": "Cubana de Aviación",
    "fat": "44",
    "f": 44,
    "sqrt+10": 16.6,
    "px": 27,
    "PX": 45,
    "cat": "A1",
    "phase": "en route",
    "certainty": "suspected",
    "FIELD16": "",
    "meta": "mechanical",
    "cause": "mechanical failure",
    "interesting notes": "",
    "FIELD20": "",
    "Cause sources": "",
    "FIELD22": ""
  },
  {
    "code": 143,
    "date": "17-Jul-1997",
    "plane_type": "Fokker F-27 Friendship 600",
    "location": "Bandung",
    "country": "Indonesia",
    "registration": "PK-YPM",
    "operator": "Trigana Air Service, opf. Sempati Air",
    "fat": "28",
    "f": 28,
    "sqrt+10": 15.3,
    "px": 25,
    "PX": 35,
    "cat": "A1",
    "phase": "en route",
    "certainty": "suspected",
    "FIELD16": "",
    "meta": "mechanical",
    "cause": "engine failure",
    "interesting notes": "",
    "FIELD20": "",
    "Cause sources": "",
    "FIELD22": ""
  },
  {
    "code": 144,
    "date": "29-Jul-1997",
    "plane_type": "BAC One-Eleven 203AE",
    "location": "Calabar Airp.",
    "country": "Nigeria",
    "registration": "5N-BAA",
    "operator": "ADC Airlines",
    "fat": "1",
    "f": 1,
    "sqrt+10": 11,
    "px": 21,
    "PX": 15,
    "cat": "A1",
    "phase": "landing",
    "certainty": "suspected",
    "FIELD16": "",
    "meta": "weather",
    "cause": "low visibility",
    "interesting notes": "",
    "FIELD20": "",
    "Cause sources": "",
    "FIELD22": ""
  },
  {
    "code": 145,
    "date": "30-Jul-1997",
    "plane_type": "ATR-42-512",
    "location": "Florence-Per",
    "country": "Italy",
    "registration": "F-GPYE",
    "operator": "Air Littoral",
    "fat": "1",
    "f": 1,
    "sqrt+10": 11,
    "px": 21,
    "PX": 15,
    "cat": "A1",
    "phase": "landing",
    "certainty": "probable",
    "FIELD16": "",
    "meta": "human_error",
    "cause": "pilot error",
    "interesting notes": "",
    "FIELD20": "",
    "Cause sources": "",
    "FIELD22": ""
  },
  {
    "code": 146,
    "date": "6-Aug-1997",
    "plane_type": "Boeing 747-3B5",
    "location": "Nimitz Hill",
    "country": "Guam",
    "registration": "HL7468",
    "operator": "Korean Air",
    "fat": "228",
    "f": 228,
    "sqrt+10": 25.1,
    "px": 35,
    "PX": 85,
    "cat": "A1",
    "phase": "approach",
    "certainty": "probable",
    "FIELD16": "",
    "meta": "human_error",
    "cause": "pilot & crew error",
    "interesting notes": "",
    "FIELD20": "",
    "Cause sources": "",
    "FIELD22": ""
  },
  {
    "code": 147,
    "date": "10-Aug-1997",
    "plane_type": "Dornier 228-212",
    "location": "near Matsu Airpor",
    "country": "Taiwan",
    "registration": "B-12256",
    "operator": "Formosa Airlines",
    "fat": "16",
    "f": 16,
    "sqrt+10": 14,
    "px": 24,
    "PX": 35,
    "cat": "A1",
    "phase": "approach",
    "certainty": "probable",
    "FIELD16": "",
    "meta": "weather",
    "cause": "low visibility",
    "interesting notes": "",
    "FIELD20": "",
    "Cause sources": "",
    "FIELD22": ""
  },
  {
    "code": 148,
    "date": "3-Sep-1997",
    "plane_type": "Tupolev Tu-134",
    "location": "Phnom Penh-Pochentong Airport",
    "country": "Cambodia",
    "registration": "VNA120",
    "operator": "Vietnam Airlines",
    "fat": "65",
    "f": 65,
    "sqrt+10": 18.1,
    "px": 28,
    "PX": 55,
    "cat": "A1",
    "phase": "approach",
    "certainty": "confirmed",
    "FIELD16": "",
    "meta": "human_error",
    "cause": "pilot error",
    "interesting notes": "",
    "FIELD20": "",
    "Cause sources": "",
    "FIELD22": ""
  },
  {
    "code": 149,
    "date": "6-Sep-1997",
    "plane_type": "Dornier Du 228",
    "location": "Lambir Hills National Park",
    "country": "Malaysia",
    "registration": "9M-MIA",
    "operator": "Royal Brunei Airlines",
    "fat": "10",
    "f": 10,
    "sqrt+10": 13.2,
    "px": 23,
    "PX": 25,
    "cat": "A1",
    "phase": "approach",
    "certainty": "suspected",
    "FIELD16": "",
    "meta": "human_error",
    "cause": "pilot error",
    "interesting notes": "",
    "FIELD20": "",
    "Cause sources": "",
    "FIELD22": ""
  },
  {
    "code": 150,
    "date": "12-Sep-1997",
    "plane_type": "DHC-6 Twin Otter 300",
    "location": "Minembwe Mou",
    "country": "DR Congo",
    "registration": "9Q-CRY",
    "operator": "TMK Air Commuter",
    "fat": "19",
    "f": 19,
    "sqrt+10": 14.4,
    "px": 24,
    "PX": 35,
    "cat": "A1",
    "phase": "en route",
    "certainty": "suspected",
    "FIELD16": "",
    "meta": "human_error",
    "cause": "pilot error",
    "interesting notes": "",
    "FIELD20": "",
    "Cause sources": "",
    "FIELD22": ""
  },
  {
    "code": 151,
    "date": "26-Sep-1997",
    "plane_type": "Airbus A300",
    "location": "near Medan-Polonia Airport",
    "country": "Indonesia",
    "registration": "PK-GAI",
    "operator": "Garuda Indonesia",
    "fat": "234",
    "f": 234,
    "sqrt+10": 25.3,
    "px": 35,
    "PX": 85,
    "cat": "A1",
    "phase": "approach",
    "certainty": "probable",
    "FIELD16": "",
    "meta": "human_error",
    "cause": "atc error & bad weather",
    "interesting notes": "",
    "FIELD20": "",
    "Cause sources": "",
    "FIELD22": ""
  },
  {
    "code": 152,
    "date": "10-Oct-1997",
    "plane_type": "Douglas DC-9-32",
    "location": "Nuevo Berlin",
    "country": "Argentina",
    "registration": "LV-WEG",
    "operator": "Austral Líneas Aéreas",
    "fat": "74",
    "f": 74,
    "sqrt+10": 18.6,
    "px": 29,
    "PX": 55,
    "cat": "A1",
    "phase": "en route",
    "certainty": "suspected",
    "FIELD16": "",
    "meta": "human_error",
    "cause": "pilot error",
    "interesting notes": "",
    "FIELD20": "",
    "Cause sources": "",
    "FIELD22": ""
  },
  {
    "code": 153,
    "date": "9-Dec-1997",
    "plane_type": "Embraer 110P1 Bandeirante",
    "location": "Little Grand",
    "country": "Canada",
    "registration": "C-GVRO",
    "operator": "Sowind Air",
    "fat": "4",
    "f": 4,
    "sqrt+10": 12,
    "px": 22,
    "PX": 15,
    "cat": "A1",
    "phase": "approach",
    "certainty": "probable",
    "FIELD16": "",
    "meta": "weather",
    "cause": "low visibility",
    "interesting notes": "",
    "FIELD20": "",
    "Cause sources": "",
    "FIELD22": ""
  },
  {
    "code": 154,
    "date": "13-Dec-1997",
    "plane_type": "Swearingen SA226-TC Metro II",
    "location": "La Vertiente",
    "country": "Bolivia",
    "registration": "CP-1635",
    "operator": "STAP-Serv. y Transportes Aéreos Petroleros, opf. SAVE",
    "fat": "10",
    "f": 10,
    "sqrt+10": 13.2,
    "px": 23,
    "PX": 25,
    "cat": "A1",
    "phase": "initial climb",
    "certainty": "suspected",
    "FIELD16": "",
    "meta": "mechanical",
    "cause": "engine failure",
    "interesting notes": "",
    "FIELD20": "",
    "Cause sources": "",
    "FIELD22": ""
  },
  {
    "code": 155,
    "date": "15-Dec-1997",
    "plane_type": "Tupolev 154B-1",
    "location": "near Sharjah Airp",
    "country": "UAE",
    "registration": "EY-85281",
    "operator": "Tajikistan Airlines",
    "fat": "85",
    "f": 85,
    "sqrt+10": 19.2,
    "px": 29,
    "PX": 65,
    "cat": "A1",
    "phase": "approach",
    "certainty": "confirmed",
    "FIELD16": "",
    "meta": "human_error",
    "cause": "pilot error",
    "interesting notes": "",
    "FIELD20": "",
    "Cause sources": "",
    "FIELD22": ""
  },
  {
    "code": 156,
    "date": "17-Dec-1997",
    "plane_type": "Yakovlev Yak-42",
    "location": "near Thessaloniki",
    "country": "Greece",
    "registration": "UR-42334",
    "operator": "Aerosvit",
    "fat": "70",
    "f": 70,
    "sqrt+10": 18.4,
    "px": 28,
    "PX": 55,
    "cat": "A1",
    "phase": "en route",
    "certainty": "probable",
    "FIELD16": "",
    "meta": "human_error",
    "cause": "pilot error",
    "interesting notes": "",
    "FIELD20": "",
    "Cause sources": "",
    "FIELD22": ""
  },
  {
    "code": 157,
    "date": "19-Dec-1997",
    "plane_type": "Boeing 737-36N",
    "location": "near Palembang",
    "country": "Indonesia",
    "registration": "9V-TRF",
    "operator": "SilkAir",
    "fat": "104",
    "f": 104,
    "sqrt+10": 20.2,
    "px": 30,
    "PX": 65,
    "cat": "A1",
    "phase": "en route",
    "certainty": "suspected",
    "FIELD16": "",
    "meta": "criminal",
    "cause": "intentional pilot error",
    "interesting notes": "",
    "FIELD20": "",
    "Cause sources": "",
    "FIELD22": ""
  },
  {
    "code": 158,
    "date": "28-Dec-1997",
    "plane_type": "Boeing 747-122",
    "location": "Pacific ocean ",
    "country": "Pacific ocean",
    "registration": "N4723U",
    "operator": "United Airlines",
    "fat": "1",
    "f": 1,
    "sqrt+10": 11,
    "px": 21,
    "PX": 15,
    "cat": "A1",
    "phase": "en route",
    "certainty": "suspected",
    "FIELD16": "",
    "meta": "human_error",
    "cause": "pilot error",
    "interesting notes": "",
    "FIELD20": "",
    "Cause sources": "",
    "FIELD22": ""
  },
  {
    "code": 159,
    "date": "27-Jan-1998",
    "plane_type": "Fokker F-27 Friendship 600",
    "location": "Thandwe Airp",
    "country": "Myanmar",
    "registration": "XY-AES",
    "operator": "Myanma Airways",
    "fat": "16",
    "f": 16,
    "sqrt+10": 14,
    "px": 24,
    "PX": 35,
    "cat": "A1",
    "phase": "takeoff",
    "certainty": "suspected",
    "FIELD16": "",
    "meta": "mechanical",
    "cause": "engine failure",
    "interesting notes": "",
    "FIELD20": "",
    "Cause sources": "",
    "FIELD22": ""
  },
  {
    "code": 160,
    "date": "2-Feb-1998",
    "plane_type": "Douglas DC-9-32",
    "location": "Mount Sumagaya",
    "country": "Philippines",
    "registration": "RP-C1507",
    "operator": "Cebu Pacific Air",
    "fat": "104",
    "f": 104,
    "sqrt+10": 20.2,
    "px": 30,
    "PX": 65,
    "cat": "A1",
    "phase": "en route",
    "certainty": "suspected",
    "FIELD16": "",
    "meta": "human_error",
    "cause": "pilot error",
    "interesting notes": "",
    "FIELD20": "",
    "Cause sources": "",
    "FIELD22": ""
  },
  {
    "code": 161,
    "date": "16-Feb-1998",
    "plane_type": "Airbus A300",
    "location": "Taipei-Chiang Kai Shek International Airport",
    "country": "Taiwan",
    "registration": "B-1814",
    "operator": "China Airlines",
    "fat": "196+6",
    "f": 202,
    "sqrt+10": 24.2,
    "px": 34,
    "PX": 85,
    "cat": "A1",
    "phase": "approach",
    "certainty": "probable",
    "FIELD16": "",
    "meta": "human_error",
    "cause": "pilot & crew error",
    "interesting notes": "",
    "FIELD20": "",
    "Cause sources": "",
    "FIELD22": ""
  },
  {
    "code": 162,
    "date": "18-Mar-1998",
    "plane_type": "Saab 340B",
    "location": "near Hsinchu",
    "country": "Taiwan",
    "registration": "B-12255",
    "operator": "Formosa Airlines",
    "fat": "13",
    "f": 13,
    "sqrt+10": 13.6,
    "px": 24,
    "PX": 25,
    "cat": "A1",
    "phase": "en route",
    "certainty": "probable",
    "FIELD16": "",
    "meta": "human_error",
    "cause": "pilot & crew error",
    "interesting notes": "",
    "FIELD20": "",
    "Cause sources": "",
    "FIELD22": ""
  },
  {
    "code": 163,
    "date": "19-Mar-1998",
    "plane_type": "Boeing 727-228",
    "location": "Sharki Baratayi Mountain, near Charasyab",
    "country": "Afghanistan",
    "registration": "YA-FAZ",
    "operator": "Ariana Afghan Airlines",
    "fat": "45",
    "f": 45,
    "sqrt+10": 16.7,
    "px": 27,
    "PX": 45,
    "cat": "A1",
    "phase": "en route",
    "certainty": "suspected",
    "FIELD16": "",
    "meta": "weather",
    "cause": "bad weather",
    "interesting notes": "",
    "FIELD20": "",
    "Cause sources": "",
    "FIELD22": ""
  },
  {
    "code": 164,
    "date": "22-Mar-1998",
    "plane_type": "Airbus A320",
    "location": "Bacolod City Domestic Airport",
    "country": "Philippines",
    "registration": "RP-C3222",
    "operator": "Philippine Airlines",
    "fat": "0+3",
    "f": 3,
    "sqrt+10": 11.7,
    "px": 22,
    "PX": 15,
    "cat": "A1",
    "phase": "landing",
    "certainty": "probable",
    "FIELD16": "",
    "meta": "human_error",
    "cause": "pilot error",
    "interesting notes": "",
    "FIELD20": "",
    "Cause sources": "",
    "FIELD22": ""
  },
  {
    "code": 165,
    "date": "28-Mar-1998",
    "plane_type": "Antonov 32",
    "location": "near Piura Airpor",
    "country": "Peru",
    "registration": "OB-1389",
    "operator": "Peruvian AF",
    "fat": "21+ 1",
    "f": 22,
    "sqrt+10": 14.7,
    "px": 25,
    "PX": 35,
    "cat": "A1",
    "phase": "approach",
    "certainty": "confirmed",
    "FIELD16": "",
    "meta": "mechanical",
    "cause": "engine failure",
    "interesting notes": "",
    "FIELD20": "",
    "Cause sources": "",
    "FIELD22": ""
  },
  {
    "code": 166,
    "date": "20-Apr-1998",
    "plane_type": "Boeing 727",
    "location": "Bogota",
    "country": "Colombia",
    "registration": "HC-BSU",
    "operator": "Air France",
    "fat": "53",
    "f": 53,
    "sqrt+10": 17.3,
    "px": 27,
    "PX": 55,
    "cat": "A1",
    "phase": "en route",
    "certainty": "probable",
    "FIELD16": "",
    "meta": "human_error",
    "cause": "pilot & crew error",
    "interesting notes": "",
    "FIELD20": "",
    "Cause sources": "",
    "FIELD22": ""
  },
  {
    "code": 167,
    "date": "5-May-1998",
    "plane_type": "Boeing 737-282",
    "location": "near Andoas",
    "country": "Peru",
    "registration": "FAP-351",
    "operator": "Fuerza Aérea del Peru - FAP, opf. Occidental Petroleum",
    "fat": "75",
    "f": 75,
    "sqrt+10": 18.7,
    "px": 29,
    "PX": 55,
    "cat": "A1",
    "phase": "approach",
    "certainty": "suspected",
    "FIELD16": "",
    "meta": "weather",
    "cause": "low visibility",
    "interesting notes": "",
    "FIELD20": "",
    "Cause sources": "",
    "FIELD22": ""
  },
  {
    "code": 168,
    "date": "26-May-1998",
    "plane_type": "Harbin Y-12",
    "location": "near Erdenet, Orkhon",
    "country": "Mongolia",
    "registration": "JU-1017",
    "operator": "MIAT Mongolian Airlines",
    "fat": "28",
    "f": 28,
    "sqrt+10": 15.3,
    "px": 25,
    "PX": 35,
    "cat": "A1",
    "phase": "en route",
    "certainty": "suspected",
    "FIELD16": "",
    "meta": "human_error",
    "cause": "overload",
    "interesting notes": "",
    "FIELD20": "",
    "Cause sources": "",
    "FIELD22": ""
  },
  {
    "code": 169,
    "date": "18-Jun-1998",
    "plane_type": "Swearingen SA226-TC Metro II",
    "location": "Montreal-Mir",
    "country": "Canada",
    "registration": "C-GQAL",
    "operator": "Propair",
    "fat": "11",
    "f": 11,
    "sqrt+10": 13.3,
    "px": 23,
    "PX": 25,
    "cat": "A1",
    "phase": "approach",
    "certainty": "probable",
    "FIELD16": "",
    "meta": "human_error",
    "cause": "pilot & crew error",
    "interesting notes": "",
    "FIELD20": "",
    "Cause sources": "",
    "FIELD22": ""
  },
  {
    "code": 170,
    "date": "27-Jul-1998",
    "plane_type": "Consolidated PBY-5A Catalina",
    "location": "Southampton",
    "country": "UK",
    "registration": "VP-BPS",
    "operator": "Plane Sailing",
    "fat": "2",
    "f": 2,
    "sqrt+10": 11.4,
    "px": 21,
    "PX": 15,
    "cat": "A1",
    "phase": "landing",
    "certainty": "suspected",
    "FIELD16": "",
    "meta": "human_error",
    "cause": "maintenance error",
    "interesting notes": "",
    "FIELD20": "",
    "Cause sources": "",
    "FIELD22": ""
  },
  {
    "code": 171,
    "date": "29-Jul-1998",
    "plane_type": "Embraer 110P1 Bandeirante",
    "location": "near Manaus, AM",
    "country": "Brazil",
    "registration": "PT-LGN",
    "operator": "Selva Taxi Aéreo",
    "fat": "12",
    "f": 12,
    "sqrt+10": 13.5,
    "px": 23,
    "PX": 25,
    "cat": "A1",
    "phase": "en route",
    "certainty": "probable",
    "FIELD16": "",
    "meta": "human_error",
    "cause": "overload",
    "interesting notes": "",
    "FIELD20": "",
    "Cause sources": "",
    "FIELD22": ""
  },
  {
    "code": 172,
    "date": "30-Jul-1998",
    "plane_type": "HAL/Dornier 228-201",
    "location": "Cochin Airport",
    "country": "India",
    "registration": "VT-EJW",
    "operator": "Alliance Air",
    "fat": "6+ 3",
    "f": 9,
    "sqrt+10": 13,
    "px": 23,
    "PX": 25,
    "cat": "A1",
    "phase": "initial climb",
    "certainty": "confirmed",
    "FIELD16": "",
    "meta": "mechanical",
    "cause": "mechanical failure",
    "interesting notes": "",
    "FIELD20": "",
    "Cause sources": "",
    "FIELD22": ""
  },
  {
    "code": 173,
    "date": "30-Jul-1998",
    "plane_type": "Beechcraft 1900D",
    "location": "Baie de Quiberon",
    "country": "France",
    "registration": "F-GSJM",
    "operator": "Proteus Air",
    "fat": "14",
    "f": 14,
    "sqrt+10": 13.7,
    "px": 24,
    "PX": 25,
    "cat": "A1",
    "phase": "approach",
    "certainty": "probable",
    "FIELD16": "",
    "meta": "human_error",
    "cause": "pilot error",
    "interesting notes": "",
    "FIELD20": "",
    "Cause sources": "",
    "FIELD22": ""
  },
  {
    "code": 174,
    "date": "21-Aug-1998",
    "plane_type": "DHC-6 Twin Otter 300",
    "location": "near Ghorepani",
    "country": "Nepal",
    "registration": "9N-ACC",
    "operator": "Nepalese CAA, opf. Lumbini Airways",
    "fat": "18",
    "f": 18,
    "sqrt+10": 14.2,
    "px": 24,
    "PX": 35,
    "cat": "A1",
    "phase": "en route",
    "certainty": "unknown",
    "FIELD16": "",
    "meta": "unknown",
    "cause": "unknown",
    "interesting notes": "",
    "FIELD20": "",
    "Cause sources": "",
    "FIELD22": ""
  },
  {
    "code": 175,
    "date": "24-Aug-1998",
    "plane_type": "Fokker F-27",
    "location": "near Tachilek,",
    "country": "Myanmar",
    "registration": "XY-AEN",
    "operator": "Myanma Airways",
    "fat": "36",
    "f": 36,
    "sqrt+10": 16,
    "px": 26,
    "PX": 45,
    "cat": "A1",
    "phase": "approach",
    "certainty": "suspected",
    "FIELD16": "",
    "meta": "human_error",
    "cause": "pilot error",
    "interesting notes": "",
    "FIELD20": "",
    "Cause sources": "",
    "FIELD22": ""
  },
  {
    "code": 176,
    "date": "29-Aug-1998",
    "plane_type": "Tupolev 154M",
    "location": "Quito-Mariscal Sucre Airpor",
    "country": "Ecuador",
    "registration": "CU-T1264",
    "operator": "Cubana",
    "fat": "70+ 10",
    "f": 80,
    "sqrt+10": 18.9,
    "px": 29,
    "PX": 55,
    "cat": "A1",
    "phase": "takeoff",
    "certainty": "suspected",
    "FIELD16": "",
    "meta": "human_error",
    "cause": "maintenance error",
    "interesting notes": "",
    "FIELD20": "",
    "Cause sources": "",
    "FIELD22": ""
  },
  {
    "code": 177,
    "date": "2-Sep-1998",
    "plane_type": "Antonov 26B",
    "location": "near Malanje Airport",
    "country": "Angola",
    "registration": "RA-26028",
    "operator": "Permtransavia, opf. Prestavia",
    "fat": "24",
    "f": 24,
    "sqrt+10": 14.9,
    "px": 25,
    "PX": 35,
    "cat": "A1",
    "phase": "en route",
    "certainty": "suspected",
    "FIELD16": "",
    "meta": "mechanical",
    "cause": "engine failure",
    "interesting notes": "",
    "FIELD20": "",
    "Cause sources": "",
    "FIELD22": ""
  },
  {
    "code": 178,
    "date": "2-Sep-1998",
    "plane_type": "Douglas MD-11",
    "location": "Atlantic Ocean near St Margarets Bay, Nova Scotia",
    "country": "Canada",
    "registration": "HB-IWF",
    "operator": "Swissair",
    "fat": "229",
    "f": 229,
    "sqrt+10": 25.1,
    "px": 35,
    "PX": 85,
    "cat": "A1",
    "phase": "en route",
    "certainty": "suspected",
    "FIELD16": "",
    "meta": "mechanical",
    "cause": "design flaw",
    "interesting notes": "",
    "FIELD20": "",
    "Cause sources": "",
    "FIELD22": ""
  },
  {
    "code": 179,
    "date": "25-Sep-1998",
    "plane_type": "BAe 146",
    "location": "Boumahfouda, Morocco",
    "country": "Morocco",
    "registration": "EC-GEO",
    "operator": "PauknAir",
    "fat": "38",
    "f": 38,
    "sqrt+10": 16.2,
    "px": 26,
    "PX": 45,
    "cat": "A1",
    "phase": "approach",
    "certainty": "suspected",
    "FIELD16": "",
    "meta": "human_error",
    "cause": "pilot error",
    "interesting notes": "",
    "FIELD20": "",
    "Cause sources": "",
    "FIELD22": ""
  },
  {
    "code": 180,
    "date": "29-Sep-1998",
    "plane_type": "Antonov An-24rv",
    "location": "off coast of Mannar District, Sri Lanka",
    "country": "Sri Lanka",
    "registration": "EW-46465",
    "operator": "Lionair",
    "fat": "55",
    "f": 55,
    "sqrt+10": 17.4,
    "px": 27,
    "PX": 55,
    "cat": "C1",
    "phase": "en route",
    "certainty": "suspected",
    "FIELD16": "",
    "meta": "criminal",
    "cause": "shot down",
    "interesting notes": "",
    "FIELD20": "",
    "Cause sources": "",
    "FIELD22": ""
  },
  {
    "code": 181,
    "date": "10-Oct-1998",
    "plane_type": "Boeing 727-30",
    "location": "near Kindu",
    "country": "DR Congo",
    "registration": "9Q-CSG",
    "operator": "Lignes Aériennes Congolaises",
    "fat": "41",
    "f": 41,
    "sqrt+10": 16.4,
    "px": 26,
    "PX": 45,
    "cat": "C1",
    "phase": "en route",
    "certainty": "confirmed",
    "FIELD16": "",
    "meta": "criminal",
    "cause": "shot down",
    "interesting notes": "",
    "FIELD20": "",
    "Cause sources": "",
    "FIELD22": ""
  },
  {
    "code": 182,
    "date": "1-Nov-1998",
    "plane_type": "Douglas C-47A-90-DL (DC-3C)",
    "location": "near Quetzaltenango",
    "country": "Guatemala",
    "registration": "N3FY",
    "operator": "Living Water Teaching Ministries",
    "fat": "11",
    "f": 11,
    "sqrt+10": 13.3,
    "px": 23,
    "PX": 25,
    "cat": "A1",
    "phase": "approach",
    "certainty": "probable",
    "FIELD16": "",
    "meta": "weather",
    "cause": "low visibility",
    "interesting notes": "",
    "FIELD20": "",
    "Cause sources": "",
    "FIELD22": ""
  },
  {
    "code": 183,
    "date": "11-Dec-1998",
    "plane_type": "Airbus A310-324",
    "location": "Surat Thani",
    "country": "Thailand",
    "registration": "HS-TIA",
    "operator": "Thai Airways International",
    "fat": "101",
    "f": 101,
    "sqrt+10": 20,
    "px": 30,
    "PX": 65,
    "cat": "A1",
    "phase": "approach",
    "certainty": "suspected",
    "FIELD16": "",
    "meta": "weather",
    "cause": "bad weather",
    "interesting notes": "",
    "FIELD20": "",
    "Cause sources": "",
    "FIELD22": ""
  },
  {
    "code": 184,
    "date": "24-Feb-1999",
    "plane_type": "Tupolev Tu-154M",
    "location": "Ruian",
    "country": "China",
    "registration": "B-2622",
    "operator": "China Southwest Airlines",
    "fat": "61",
    "f": 61,
    "sqrt+10": 17.8,
    "px": 28,
    "PX": 55,
    "cat": "A1",
    "phase": "approach",
    "certainty": "confirmed",
    "FIELD16": "",
    "meta": "mechanical",
    "cause": "mechanical failure",
    "interesting notes": "",
    "FIELD20": "",
    "Cause sources": "",
    "FIELD22": ""
  },
  {
    "code": 185,
    "date": "25-Feb-1999",
    "plane_type": "Dornier 328-110",
    "location": "Genoa-Cristo",
    "country": "Italy",
    "registration": "D-CPRR",
    "operator": "Minerva Airlines",
    "fat": "4",
    "f": 4,
    "sqrt+10": 12,
    "px": 22,
    "PX": 15,
    "cat": "A1",
    "phase": "landing",
    "certainty": "suspected",
    "FIELD16": "",
    "meta": "weather",
    "cause": "bad weather",
    "interesting notes": "",
    "FIELD20": "",
    "Cause sources": "",
    "FIELD22": ""
  },
  {
    "code": 186,
    "date": "8-Apr-1999",
    "plane_type": "DHC-6 Twin Otter 300",
    "location": "near Málaga",
    "country": "Colombia",
    "registration": "HK-2760",
    "operator": "Aerotaca",
    "fat": "5",
    "f": 5,
    "sqrt+10": 12.2,
    "px": 22,
    "PX": 15,
    "cat": "A1",
    "phase": "en route",
    "certainty": "probable",
    "FIELD16": "",
    "meta": "weather",
    "cause": "low visibility",
    "interesting notes": "",
    "FIELD20": "",
    "Cause sources": "",
    "FIELD22": ""
  },
  {
    "code": 187,
    "date": "7-Apr-1999",
    "plane_type": "Boeing 737-400",
    "location": "near Ceyhan",
    "country": "Turkey",
    "registration": "TC-JEP",
    "operator": "Turkish Airlines",
    "fat": "6",
    "f": 6,
    "sqrt+10": 12.4,
    "px": 22,
    "PX": 25,
    "cat": "A1",
    "phase": "en route",
    "certainty": "probable",
    "FIELD16": "",
    "meta": "weather",
    "cause": "bad weather",
    "interesting notes": "",
    "FIELD20": "",
    "Cause sources": "",
    "FIELD22": ""
  },
  {
    "code": 188,
    "date": "8-May-1999",
    "plane_type": "DHC-6 Twin Otter 300",
    "location": "near Port Vila",
    "country": "Vanuatu",
    "registration": "YJ-RV9",
    "operator": "Vanair",
    "fat": "7",
    "f": 7,
    "sqrt+10": 12.6,
    "px": 23,
    "PX": 25,
    "cat": "A1",
    "phase": "en route",
    "certainty": "probable",
    "FIELD16": "",
    "meta": "weather",
    "cause": "bad weather",
    "interesting notes": "",
    "FIELD20": "",
    "Cause sources": "",
    "FIELD22": ""
  },
  {
    "code": 189,
    "date": "1-Jun-1999",
    "plane_type": "Douglas Md-82",
    "location": "Little Rock National Airport",
    "country": "USA",
    "registration": "N215AA",
    "operator": "American Airlines",
    "fat": "11",
    "f": 11,
    "sqrt+10": 13.3,
    "px": 23,
    "PX": 25,
    "cat": "A1",
    "phase": "landing",
    "certainty": "confirmed",
    "FIELD16": "",
    "meta": "human_error",
    "cause": "pilot & crew error",
    "interesting notes": "",
    "FIELD20": "",
    "Cause sources": "",
    "FIELD22": ""
  },
  {
    "code": 190,
    "date": "17-Jun-1999",
    "plane_type": "Embraer 110P2 Bandeirante",
    "location": "near Goroka",
    "country": "papua New Guinea",
    "registration": "P2-ALX",
    "operator": "Airlink",
    "fat": "17",
    "f": 17,
    "sqrt+10": 14.1,
    "px": 24,
    "PX": 35,
    "cat": "A1",
    "phase": "en route",
    "certainty": "suspected",
    "FIELD16": "",
    "meta": "human_error",
    "cause": "pilot error",
    "interesting notes": "",
    "FIELD20": "",
    "Cause sources": "",
    "FIELD22": ""
  },
  {
    "code": 191,
    "date": "4-Jul-1999",
    "plane_type": "Douglas DC-6A",
    "location": "Villavicencio-La Vanguardia Airport",
    "country": "Colombia",
    "registration": "HK-1776",
    "operator": "LANC Colombia",
    "fat": "4",
    "f": 4,
    "sqrt+10": 12,
    "px": 22,
    "PX": 15,
    "cat": "A1",
    "phase": "landing",
    "certainty": "confirmed",
    "FIELD16": "",
    "meta": "human_error",
    "cause": "pilot & crew error",
    "interesting notes": "",
    "FIELD20": "",
    "Cause sources": "",
    "FIELD22": ""
  },
  {
    "code": 192,
    "date": "24-Jul-1999",
    "plane_type": "Embraer 110P1 Bandeirante",
    "location": "near Delailasakau",
    "country": "Fiji",
    "registration": "DQ-AFN",
    "operator": "Air Fiji",
    "fat": "17",
    "f": 17,
    "sqrt+10": 14.1,
    "px": 24,
    "PX": 35,
    "cat": "A1",
    "phase": "en route",
    "certainty": "probable",
    "FIELD16": "",
    "meta": "human_error",
    "cause": "pilot error",
    "interesting notes": "",
    "FIELD20": "",
    "Cause sources": "",
    "FIELD22": ""
  },
  {
    "code": 193,
    "date": "7-Aug-1999",
    "plane_type": "Dornier 228-201",
    "location": "Santo Antão",
    "country": "Cape Verde",
    "registration": "D4-CBC",
    "operator": "TACV",
    "fat": "18",
    "f": 18,
    "sqrt+10": 14.2,
    "px": 24,
    "PX": 35,
    "cat": "A1",
    "phase": "en route",
    "certainty": "suspected",
    "FIELD16": "",
    "meta": "weather",
    "cause": "low visibility",
    "interesting notes": "",
    "FIELD20": "",
    "Cause sources": "",
    "FIELD22": ""
  },
  {
    "code": 194,
    "date": "12-Aug-1999",
    "plane_type": "Beechcraft 1900D",
    "location": "near Sept-Îles Ai.",
    "country": "Canada",
    "registration": "C-FLIH",
    "operator": "Regionnair",
    "fat": "1",
    "f": 1,
    "sqrt+10": 11,
    "px": 21,
    "PX": 15,
    "cat": "A1",
    "phase": "approach",
    "certainty": "probable",
    "FIELD16": "",
    "meta": "human_error",
    "cause": "pilot error",
    "interesting notes": "",
    "FIELD20": "",
    "Cause sources": "",
    "FIELD22": ""
  },
  {
    "code": 195,
    "date": "22-Aug-1999",
    "plane_type": "Douglas MD-11",
    "location": "Chek Lap Kok International Airport",
    "country": "Hong Kong",
    "registration": "B-150",
    "operator": "China Airlines",
    "fat": "3",
    "f": 3,
    "sqrt+10": 11.7,
    "px": 22,
    "PX": 15,
    "cat": "A1",
    "phase": "landing",
    "certainty": "suspected",
    "FIELD16": "",
    "meta": "human_error",
    "cause": "pilot error",
    "interesting notes": "",
    "FIELD20": "",
    "Cause sources": "",
    "FIELD22": ""
  },
  {
    "code": 196,
    "date": "24-Aug-1999",
    "plane_type": "MD-90-30",
    "location": "Hualien Airp",
    "country": "Taiwan",
    "registration": "B-17912",
    "operator": "Uni Air",
    "fat": "1",
    "f": 1,
    "sqrt+10": 11,
    "px": 21,
    "PX": 15,
    "cat": "A1",
    "phase": "landing",
    "certainty": "probable",
    "FIELD16": "",
    "meta": "human_error",
    "cause": "passenger error",
    "interesting notes": "",
    "FIELD20": "",
    "Cause sources": "",
    "FIELD22": ""
  },
  {
    "code": 197,
    "date": "26-Aug-1999",
    "plane_type": "Yakovlev 40",
    "location": "near Turtkul Airport",
    "country": "Uzbekistan",
    "registration": "UK-87848",
    "operator": "Uzbekistan Airways",
    "fat": "2",
    "f": 2,
    "sqrt+10": 11.4,
    "px": 21,
    "PX": 15,
    "cat": "A1",
    "phase": "landing",
    "certainty": "probable",
    "FIELD16": "",
    "meta": "human_error",
    "cause": "pilot error",
    "interesting notes": "",
    "FIELD20": "",
    "Cause sources": "",
    "FIELD22": ""
  },
  {
    "code": 198,
    "date": "31-Aug-1999",
    "plane_type": "Boeing 737-204C",
    "location": "Buenos Aires-Jorge Newbery Airport",
    "country": "Argentina",
    "registration": "LV-WRZ",
    "operator": "LAPA",
    "fat": "63+2",
    "f": 65,
    "sqrt+10": 18.1,
    "px": 28,
    "PX": 55,
    "cat": "A1",
    "phase": "takeoff",
    "certainty": "probable",
    "FIELD16": "",
    "meta": "human_error",
    "cause": "pilot & crew error",
    "interesting notes": "",
    "FIELD20": "",
    "Cause sources": "",
    "FIELD22": ""
  },
  {
    "code": 199,
    "date": "5-Sep-1999",
    "plane_type": "British Aerospace BAe-748-501",
    "location": "near Kathmandu-Tr",
    "country": "Nepal",
    "registration": "9N-AEG",
    "operator": "Necon Air",
    "fat": "15",
    "f": 15,
    "sqrt+10": 13.9,
    "px": 24,
    "PX": 25,
    "cat": "A1",
    "phase": "approach",
    "certainty": "probable",
    "FIELD16": "",
    "meta": "human_error",
    "cause": "pilot error",
    "interesting notes": "",
    "FIELD20": "",
    "Cause sources": "",
    "FIELD22": ""
  },
  {
    "code": 200,
    "date": "31-Oct-1999",
    "plane_type": "Boeing 767-366ER",
    "location": "near Nantucket Island, MA",
    "country": "USA",
    "registration": "SU-GAP",
    "operator": "EgyptAir",
    "fat": "217",
    "f": 217,
    "sqrt+10": 24.7,
    "px": 35,
    "PX": 85,
    "cat": "A1",
    "phase": "en route",
    "certainty": "probable",
    "FIELD16": "",
    "meta": "human_error",
    "cause": "crew error",
    "interesting notes": "",
    "FIELD20": "",
    "Cause sources": "",
    "FIELD22": ""
  },
  {
    "code": 201,
    "date": "9-Nov-1999",
    "plane_type": "Douglas DC-9-31F",
    "location": "near Uruapan",
    "country": "Mexico",
    "registration": "XA-TKN",
    "operator": "TAESA",
    "fat": "18",
    "f": 18,
    "sqrt+10": 14.2,
    "px": 24,
    "PX": 35,
    "cat": "A1",
    "phase": "en route",
    "certainty": "probable",
    "FIELD16": "",
    "meta": "human_error",
    "cause": "pilot & crew error",
    "interesting notes": "",
    "FIELD20": "",
    "Cause sources": "",
    "FIELD22": ""
  },
  {
    "code": 202,
    "date": "12-Nov-1999",
    "plane_type": "ATR-42-312",
    "location": "near Mitrovica",
    "country": "Serbia",
    "registration": "F-OHFV",
    "operator": "SiFly, opf. UN",
    "fat": "24",
    "f": 24,
    "sqrt+10": 14.9,
    "px": 25,
    "PX": 35,
    "cat": "A1",
    "phase": "en route",
    "certainty": "probable",
    "FIELD16": "",
    "meta": "human_error",
    "cause": "pilot & crew error",
    "interesting notes": "",
    "FIELD20": "",
    "Cause sources": "",
    "FIELD22": ""
  },
  {
    "code": 203,
    "date": "7-Dec-1999",
    "plane_type": "Let L-410UVP-E",
    "location": "Kasibu",
    "country": "Philippines",
    "registration": "RP-C3883",
    "operator": "Asian Spirit",
    "fat": "17",
    "f": 17,
    "sqrt+10": 14.1,
    "px": 24,
    "PX": 35,
    "cat": "A1",
    "phase": "en route",
    "certainty": "unknown",
    "FIELD16": "",
    "meta": "unknown",
    "cause": "unknown",
    "interesting notes": "",
    "FIELD20": "",
    "Cause sources": "",
    "FIELD22": ""
  },
  {
    "code": 204,
    "date": "11-Dec-1999",
    "plane_type": "British Aerospace ATP",
    "location": "Sao Jorge Is",
    "country": "Portugal",
    "registration": "CS-TGM",
    "operator": "SATA Air Açores",
    "fat": "35",
    "f": 35,
    "sqrt+10": 15.9,
    "px": 26,
    "PX": 45,
    "cat": "A1",
    "phase": "en route",
    "certainty": "probable",
    "FIELD16": "",
    "meta": "weather",
    "cause": "low visibility",
    "interesting notes": "",
    "FIELD20": "",
    "Cause sources": "",
    "FIELD22": ""
  },
  {
    "code": 205,
    "date": "21-Dec-1999",
    "plane_type": "Douglas DC-10-30",
    "location": "Guatemala City-La Aurora Airport",
    "country": "Guatemala",
    "registration": "F-GTDI",
    "operator": "Cubana de Aviación",
    "fat": "16+2",
    "f": 18,
    "sqrt+10": 14.2,
    "px": 24,
    "PX": 35,
    "cat": "A1",
    "phase": "landing",
    "certainty": "probable",
    "FIELD16": "",
    "meta": "weather",
    "cause": "wet runway",
    "interesting notes": "",
    "FIELD20": "",
    "Cause sources": "",
    "FIELD22": ""
  },
  {
    "code": 206,
    "date": "24-Dec-1999",
    "plane_type": "Airbus A300B2-101",
    "location": "Kandahar Airport",
    "country": "Afghanistan",
    "registration": "VT-EDW",
    "operator": "Indian Airlines",
    "fat": "1",
    "f": 1,
    "sqrt+10": 11,
    "px": 21,
    "PX": 15,
    "cat": "H2",
    "phase": "en route",
    "certainty": "confirmed",
    "FIELD16": "",
    "meta": "criminal",
    "cause": "hostage situation",
    "interesting notes": "",
    "FIELD20": "",
    "Cause sources": "",
    "FIELD22": ""
  },
  {
    "code": 207,
    "date": "25-Dec-1999",
    "plane_type": "Yakovlev 42D",
    "location": "Bejuma",
    "country": "Venezuela",
    "registration": "CU-T1285",
    "operator": "Cubana de Aviación",
    "fat": "22",
    "f": 22,
    "sqrt+10": 14.7,
    "px": 25,
    "PX": 35,
    "cat": "A1",
    "phase": "approach",
    "certainty": "probable",
    "FIELD16": "",
    "meta": "human_error",
    "cause": "pilot error",
    "interesting notes": "",
    "FIELD20": "",
    "Cause sources": "",
    "FIELD22": ""
  },
  {
    "code": 208,
    "date": "25-Dec-1999",
    "plane_type": "DHC-6 Twin Otter 300",
    "location": "near Simra Airpor",
    "country": "Nepal",
    "registration": "9N-AFL",
    "operator": "Skyline Airways",
    "fat": "10",
    "f": 10,
    "sqrt+10": 13.2,
    "px": 23,
    "PX": 25,
    "cat": "A1",
    "phase": "en route",
    "certainty": "unknown",
    "FIELD16": "",
    "meta": "unknown",
    "cause": "unknown",
    "interesting notes": "",
    "FIELD20": "",
    "Cause sources": "",
    "FIELD22": ""
  },
  {
    "code": 209,
    "date": "5-Jan-2000",
    "plane_type": "Embraer 110P1A Bandeirante",
    "location": "Abuja Intern...",
    "country": "Nigeria",
    "registration": "5N-AXL",
    "operator": "Skypower Express",
    "fat": "1+ 1",
    "f": 2,
    "sqrt+10": 11.4,
    "px": 21,
    "PX": 15,
    "cat": "A1",
    "phase": "approach",
    "certainty": "suspected",
    "FIELD16": "",
    "meta": "mechanical",
    "cause": "engine loss",
    "interesting notes": "",
    "FIELD20": "",
    "Cause sources": "",
    "FIELD22": ""
  },
  {
    "code": 210,
    "date": "10-Jan-2000",
    "plane_type": "Saab 340B",
    "location": "near Nassenwil",
    "country": "Switzerland",
    "registration": "HB-AKK",
    "operator": "Crossair",
    "fat": "10",
    "f": 10,
    "sqrt+10": 13.2,
    "px": 23,
    "PX": 25,
    "cat": "A1",
    "phase": "en route",
    "certainty": "suspected",
    "FIELD16": "",
    "meta": "human_error",
    "cause": "pilot error",
    "interesting notes": "",
    "FIELD20": "",
    "Cause sources": "",
    "FIELD22": ""
  },
  {
    "code": 211,
    "date": "13-Jan-2000",
    "plane_type": "Shorts 360-300",
    "location": "near Marsa el-Brega",
    "country": "Libya",
    "registration": "HB-AAM",
    "operator": "Avisto, opf. Sirte Oil Company",
    "fat": "22",
    "f": 22,
    "sqrt+10": 14.7,
    "px": 25,
    "PX": 35,
    "cat": "A1",
    "phase": "approach",
    "certainty": "",
    "FIELD16": "",
    "meta": "unknown",
    "cause": "",
    "interesting notes": "",
    "FIELD20": "",
    "Cause sources": "",
    "FIELD22": ""
  },
  {
    "code": 212,
    "date": "15-Jan-2000",
    "plane_type": "Let L-410UVP-E",
    "location": "San José",
    "country": "Costa Rica",
    "registration": "YS-09-C",
    "operator": "TACSA",
    "fat": "5",
    "f": 5,
    "sqrt+10": 12.2,
    "px": 22,
    "PX": 15,
    "cat": "A1",
    "phase": "en route",
    "certainty": "suspected",
    "FIELD16": "",
    "meta": "human_error",
    "cause": "pilot error",
    "interesting notes": "",
    "FIELD20": "",
    "Cause sources": "",
    "FIELD22": ""
  },
  {
    "code": 213,
    "date": "30-Jan-2000",
    "plane_type": "Airbus A310-304",
    "location": "near Abidjan-Feli...",
    "country": "Kenya",
    "registration": "5Y-BEN",
    "operator": "Kenya Airways",
    "fat": "169",
    "f": 169,
    "sqrt+10": 23,
    "px": 33,
    "PX": 75,
    "cat": "A1",
    "phase": "takeoff",
    "certainty": "confirmed",
    "FIELD16": "",
    "meta": "human_error",
    "cause": "pilot error",
    "interesting notes": "",
    "FIELD20": "",
    "Cause sources": "",
    "FIELD22": ""
  },
  {
    "code": 214,
    "date": "31-Jan-2000",
    "plane_type": "MD-83",
    "location": "near Anacapa Isla...",
    "country": "USA",
    "registration": "N963AS",
    "operator": "Alaska Airlines",
    "fat": "88",
    "f": 88,
    "sqrt+10": 19.4,
    "px": 29,
    "PX": 65,
    "cat": "A1",
    "phase": "en route",
    "certainty": "confirmed",
    "FIELD16": "",
    "meta": "human_error",
    "cause": "maintenance error",
    "interesting notes": "",
    "FIELD20": "",
    "Cause sources": "",
    "FIELD22": ""
  },
  {
    "code": 215,
    "date": "8-Feb-2000",
    "plane_type": "Embraer 110P2 Bandeirante",
    "location": "Maputo",
    "country": "Mozambique",
    "registration": "C9-AUH",
    "operator": "Sabin Air",
    "fat": "0+ 1",
    "f": 1,
    "sqrt+10": 11,
    "px": 21,
    "PX": 15,
    "cat": "A1",
    "phase": "initial climb",
    "certainty": "unknown",
    "FIELD16": "",
    "meta": "unknown",
    "cause": "unknown",
    "interesting notes": "",
    "FIELD20": "",
    "Cause sources": "",
    "FIELD22": ""
  },
  {
    "code": 216,
    "date": "9-Mar-2000",
    "plane_type": "Yakovlev 40D",
    "location": "Moskva-Shere...",
    "country": "Russia",
    "registration": "RA-88170",
    "operator": "Vologoda Air",
    "fat": "9",
    "f": 9,
    "sqrt+10": 13,
    "px": 23,
    "PX": 25,
    "cat": "A1",
    "phase": "initial climb",
    "certainty": "confirmed",
    "FIELD16": "",
    "meta": "weather",
    "cause": "bad weather",
    "interesting notes": "",
    "FIELD20": "",
    "Cause sources": "",
    "FIELD22": ""
  },
  {
    "code": 217,
    "date": "17-Mar-2000",
    "plane_type": "DHC-6 Twin Otter 300",
    "location": "near Carreto",
    "country": "Panama",
    "registration": "HP-1267APP",
    "operator": "AeroPerlas",
    "fat": "10",
    "f": 10,
    "sqrt+10": 13.2,
    "px": 23,
    "PX": 25,
    "cat": "A1",
    "phase": "en route",
    "certainty": "unknown",
    "FIELD16": "",
    "meta": "unknown",
    "cause": "unknown",
    "interesting notes": "",
    "FIELD20": "",
    "Cause sources": "",
    "FIELD22": ""
  },
  {
    "code": 218,
    "date": "25-Mar-2000",
    "plane_type": "Antonov 32",
    "location": "Huambo Airpo...",
    "country": "Angola",
    "registration": "D2-MAJ",
    "operator": "Uralex",
    "fat": "3",
    "f": 3,
    "sqrt+10": 11.7,
    "px": 22,
    "PX": 15,
    "cat": "A1",
    "phase": "takeoff",
    "certainty": "confirmed",
    "FIELD16": "",
    "meta": "mechanical",
    "cause": "brake failure",
    "interesting notes": "",
    "FIELD20": "",
    "Cause sources": "",
    "FIELD22": ""
  },
  {
    "code": 219,
    "date": "19-Apr-2000",
    "plane_type": "Boeing 737-2H4",
    "location": "near Davao",
    "country": "Philippines",
    "registration": "RP-C3010",
    "operator": "Air Philippines",
    "fat": "131",
    "f": 131,
    "sqrt+10": 21.4,
    "px": 31,
    "PX": 75,
    "cat": "A1",
    "phase": "approach",
    "certainty": "suspected",
    "FIELD16": "",
    "meta": "human_error",
    "cause": "pilot error",
    "interesting notes": "",
    "FIELD20": "",
    "Cause sources": "",
    "FIELD22": ""
  },
  {
    "code": 220,
    "date": "17-May-2000",
    "plane_type": "Beechcraft 1900C-1",
    "location": "near Moanda Airpo...",
    "country": "gabon",
    "registration": "TR-LFK",
    "operator": "Avirex",
    "fat": "3",
    "f": 3,
    "sqrt+10": 11.7,
    "px": 22,
    "PX": 15,
    "cat": "A1",
    "phase": "approach",
    "certainty": "suspected",
    "FIELD16": "",
    "meta": "weather",
    "cause": "bad weather",
    "interesting notes": "",
    "FIELD20": "",
    "Cause sources": "",
    "FIELD22": ""
  },
  {
    "code": 221,
    "date": "21-May-2000",
    "plane_type": "British Aerospace 3102 Jetstre",
    "location": "near Wilkes-Barre...",
    "country": "usa",
    "registration": "N16EJ",
    "operator": "East Coast Aviation Services",
    "fat": "19",
    "f": 19,
    "sqrt+10": 14.4,
    "px": 24,
    "PX": 35,
    "cat": "A1",
    "phase": "approach",
    "certainty": "confirmed",
    "FIELD16": "",
    "meta": "human_error",
    "cause": "pilot error",
    "interesting notes": "crew failed to take on enough fuel",
    "FIELD20": "",
    "Cause sources": "",
    "FIELD22": ""
  },
  {
    "code": 222,
    "date": "5-Jun-2000",
    "plane_type": "Fokker F-27 Friendship 600",
    "location": "Accra-Kotoka...",
    "country": "Ghana",
    "registration": "G 524",
    "operator": "Ghana Airlink",
    "fat": "7",
    "f": 7,
    "sqrt+10": 12.6,
    "px": 23,
    "PX": 25,
    "cat": "A1",
    "phase": "landing",
    "certainty": "suspected",
    "FIELD16": "",
    "meta": "human_error",
    "cause": "pilot error",
    "interesting notes": "",
    "FIELD20": "",
    "Cause sources": "",
    "FIELD22": ""
  },
  {
    "code": 223,
    "date": "22-Jun-2000",
    "plane_type": "Xian Yunshuji Y-7-100C",
    "location": "near Wuhan",
    "country": "China",
    "registration": "B-3479",
    "operator": "Wuhan Airlines",
    "fat": "42+ 7",
    "f": 49,
    "sqrt+10": 17,
    "px": 27,
    "PX": 45,
    "cat": "A1",
    "phase": "approach",
    "certainty": "confirmed",
    "FIELD16": "",
    "meta": "weather",
    "cause": "bad weather",
    "interesting notes": "",
    "FIELD20": "",
    "Cause sources": "",
    "FIELD22": ""
  },
  {
    "code": 224,
    "date": "8-Jul-2000",
    "plane_type": "British Aerospace 3201 Jetstre",
    "location": "near Chulum Juarez",
    "country": "Mexico",
    "registration": "N912FJ",
    "operator": "Aerocaribe",
    "fat": "19",
    "f": 19,
    "sqrt+10": 14.4,
    "px": 24,
    "PX": 35,
    "cat": "A1",
    "phase": "en route",
    "certainty": "confirmed",
    "FIELD16": "",
    "meta": "weather",
    "cause": "bad weather",
    "interesting notes": "",
    "FIELD20": "",
    "Cause sources": "",
    "FIELD22": ""
  },
  {
    "code": 225,
    "date": "17-Jul-2000",
    "plane_type": "Boeing 737-2A8",
    "location": "near Patna Airpor...",
    "country": "India",
    "registration": "VT-EGD",
    "operator": "Alliance Air",
    "fat": "55+ 5",
    "f": 60,
    "sqrt+10": 17.7,
    "px": 28,
    "PX": 55,
    "cat": "A1",
    "phase": "approach",
    "certainty": "confirmed",
    "FIELD16": "",
    "meta": "human_error",
    "cause": "pilot error",
    "interesting notes": "",
    "FIELD20": "",
    "Cause sources": "",
    "FIELD22": ""
  },
  {
    "code": 226,
    "date": "25-Jul-2000",
    "plane_type": "Aérospatiale / BAC Concorde 10",
    "location": "Gonesse",
    "country": "France",
    "registration": "F-BTSC",
    "operator": "Air France",
    "fat": "109+ 4",
    "f": 113,
    "sqrt+10": 20.6,
    "px": 31,
    "PX": 65,
    "cat": "A1",
    "phase": "takeoff",
    "certainty": "confirmed",
    "FIELD16": "",
    "meta": "mechanical",
    "cause": "mechanical failure",
    "interesting notes": "tyre exploded after colliding with strip of metal left on the runway by a previous plane, triggering a chain reaction",
    "FIELD20": "",
    "Cause sources": "http://aviation-safety.net/database/record.php?id=20000725-0",
    "FIELD22": ""
  },
  {
    "code": 227,
    "date": "27-Jul-2000",
    "plane_type": "DHC-6 Twin Otter 300",
    "location": "Jarayakhali ...",
    "country": "Nepal",
    "registration": "9N-ABP",
    "operator": "Royal Nepal Airlines",
    "fat": "25",
    "f": 25,
    "sqrt+10": 15,
    "px": 25,
    "PX": 35,
    "cat": "A1",
    "phase": "en route",
    "certainty": "suspected",
    "FIELD16": "",
    "meta": "human_error",
    "cause": "pilot error",
    "interesting notes": "",
    "FIELD20": "",
    "Cause sources": "",
    "FIELD22": ""
  },
  {
    "code": 228,
    "date": "12-Aug-2000",
    "plane_type": "Antonov 26B",
    "location": "near Tshikapa",
    "country": "Congo",
    "registration": "",
    "operator": "STAER Airlines",
    "fat": "27",
    "f": 27,
    "sqrt+10": 15.2,
    "px": 25,
    "PX": 35,
    "cat": "A1",
    "phase": "unknown",
    "certainty": "unknown",
    "FIELD16": "",
    "meta": "unknown",
    "cause": "unknown",
    "interesting notes": "",
    "FIELD20": "",
    "Cause sources": "",
    "FIELD22": ""
  },
  {
    "code": 229,
    "date": "23-Aug-2000",
    "plane_type": "Airbus A320-212",
    "location": "near Bahrain Inte...",
    "country": "Bahrain",
    "registration": "A4O-EK",
    "operator": "Gulf Air",
    "fat": "143",
    "f": 143,
    "sqrt+10": 22,
    "px": 32,
    "PX": 75,
    "cat": "A1",
    "phase": "approach",
    "certainty": "confirmed",
    "FIELD16": "",
    "meta": "human_error",
    "cause": "pilot error",
    "interesting notes": "",
    "FIELD20": "",
    "Cause sources": "",
    "FIELD22": ""
  },
  {
    "code": 230,
    "date": "4-Sep-2000",
    "plane_type": "Beechcraft 200 Super King Air",
    "location": "near Burketown, QLD",
    "country": "Australia",
    "registration": "VH-SKC",
    "operator": "Central Air",
    "fat": "8",
    "f": 8,
    "sqrt+10": 12.8,
    "px": 23,
    "PX": 25,
    "cat": "A1",
    "phase": "en route",
    "certainty": "confirmed",
    "FIELD16": "",
    "meta": "mechanical",
    "cause": "mechanical failure",
    "interesting notes": "passengers and crew suffocated due to lack of oxygen",
    "FIELD20": "",
    "Cause sources": "",
    "FIELD22": ""
  },
  {
    "code": 231,
    "date": "27-Sep-2000",
    "plane_type": "Boeing 737",
    "location": "Jinan Airpor...",
    "country": "China",
    "registration": "",
    "operator": "China Xinhua Airlines",
    "fat": "1",
    "f": 1,
    "sqrt+10": 11,
    "px": 21,
    "PX": 15,
    "cat": "H2",
    "phase": "en route",
    "certainty": "confirmed",
    "FIELD16": "",
    "meta": "criminal",
    "cause": "hijack",
    "interesting notes": "",
    "FIELD20": "",
    "Cause sources": "",
    "FIELD22": ""
  },
  {
    "code": 232,
    "date": "19-Oct-2000",
    "plane_type": "Harbin Yunshuji Y-12-II",
    "location": "near Sam Neua",
    "country": "Laos",
    "registration": "RDPL-34130",
    "operator": "Lao Aviation",
    "fat": "8",
    "f": 8,
    "sqrt+10": 12.8,
    "px": 23,
    "PX": 25,
    "cat": "A1",
    "phase": "approach",
    "certainty": "probably",
    "FIELD16": "",
    "meta": "weather",
    "cause": "bad weather",
    "interesting notes": "",
    "FIELD20": "",
    "Cause sources": "http://aviation-safety.net/database/record.php?id=20001019-0",
    "FIELD22": ""
  },
  {
    "code": 233,
    "date": "31-Oct-2000",
    "plane_type": "Boeing 747-412",
    "location": "Taipei-Chian...",
    "country": "taiwan",
    "registration": "9V-SPK",
    "operator": "Singapore Airlines",
    "fat": "83",
    "f": 83,
    "sqrt+10": 19.1,
    "px": 29,
    "PX": 65,
    "cat": "A1",
    "phase": "takeoff",
    "certainty": "certain",
    "FIELD16": "",
    "meta": "human_error",
    "cause": "crew errors",
    "interesting notes": "series of errors led the plane to take off on the wrong (closed) runway",
    "FIELD20": "",
    "Cause sources": "",
    "FIELD22": ""
  },
  {
    "code": 234,
    "date": "7-Nov-2000",
    "plane_type": "Antonov 32B",
    "location": "Luabo Airport",
    "country": "congo",
    "registration": "ER-AFA",
    "operator": "Renan, opf. Malu Aviation",
    "fat": "2",
    "f": 2,
    "sqrt+10": 11.4,
    "px": 21,
    "PX": 15,
    "cat": "A1",
    "phase": "takeoff",
    "certainty": "certain",
    "FIELD16": "",
    "meta": "mechanical",
    "cause": "mechnical failure",
    "interesting notes": "",
    "FIELD20": "",
    "Cause sources": "http://aviation-safety.net/database/record.php?id=20001107-0",
    "FIELD22": ""
  },
  {
    "code": 235,
    "date": "15-Nov-2000",
    "plane_type": "Antonov 26",
    "location": "near Luanda-4 de ...",
    "country": "angola",
    "registration": "D2-FCG",
    "operator": "ASA Pesada",
    "fat": "57",
    "f": 57,
    "sqrt+10": 17.5,
    "px": 28,
    "PX": 55,
    "cat": "A1",
    "phase": "takeoff",
    "certainty": "probably",
    "FIELD16": "",
    "meta": "mechanical",
    "cause": "mechnical failure",
    "interesting notes": "",
    "FIELD20": "",
    "Cause sources": "http://aviation-safety.net/database/record.php?id=20001109-0",
    "FIELD22": ""
  },
  {
    "code": 236,
    "date": "25-Jan-2001",
    "plane_type": "Douglas C-47A-65-DL (DC-3C)",
    "location": "Ciudad Bolívar",
    "country": "venezuela",
    "registration": "YV-224C",
    "operator": "Rutaca",
    "fat": "24",
    "f": 24,
    "sqrt+10": 14.9,
    "px": 25,
    "PX": 35,
    "cat": "A1",
    "phase": "en route",
    "certainty": "suspected",
    "FIELD16": "",
    "meta": "mechanical",
    "cause": "engine failure",
    "interesting notes": "",
    "FIELD20": "",
    "Cause sources": "",
    "FIELD22": ""
  },
  {
    "code": 237,
    "date": "10-Feb-2001",
    "plane_type": "GAF Nomad N.24A",
    "location": "near Jacobcondre",
    "country": "Suriname",
    "registration": "PZ-TBP",
    "operator": "Gum Air",
    "fat": "10",
    "f": 10,
    "sqrt+10": 13.2,
    "px": 23,
    "PX": 25,
    "cat": "A1",
    "phase": "approach",
    "certainty": "suspected",
    "FIELD16": "",
    "meta": "human_error",
    "cause": "pilot error",
    "interesting notes": "",
    "FIELD20": "",
    "Cause sources": "",
    "FIELD22": ""
  },
  {
    "code": 238,
    "date": "17-Mar-2001",
    "plane_type": "Beechcraft 1900C-1",
    "location": "Quilemba",
    "country": "Angola",
    "registration": "S9-CAE",
    "operator": "SAL Express",
    "fat": "16",
    "f": 16,
    "sqrt+10": 14,
    "px": 24,
    "PX": 35,
    "cat": "A1",
    "phase": "approach",
    "certainty": "suspected",
    "FIELD16": "",
    "meta": "weather",
    "cause": "low visibility",
    "interesting notes": "",
    "FIELD20": "",
    "Cause sources": "",
    "FIELD22": ""
  },
  {
    "code": 239,
    "date": "24-Mar-2001",
    "plane_type": "de Havilland Canada DHC-6 Twin Otter 300",
    "location": "Saint-Barthélémy",
    "country": "Guadeloupe",
    "registration": "F-OGES",
    "operator": "Air Caraïbes",
    "fat": "19+1",
    "f": 20,
    "sqrt+10": 14.5,
    "px": 24,
    "PX": 35,
    "cat": "A1",
    "phase": "approach",
    "certainty": "suspected",
    "FIELD16": "",
    "meta": "human_error",
    "cause": "pilot error",
    "interesting notes": "",
    "FIELD20": "",
    "Cause sources": "",
    "FIELD22": ""
  },
  {
    "code": 240,
    "date": "29-Mar-2001",
    "plane_type": "Gulfstream Aerospace G-1159 Gulfstream III",
    "location": "Near Aspen Airport, CO",
    "country": "USA",
    "registration": "N303GA",
    "operator": "Avjet Corp",
    "fat": "18",
    "f": 18,
    "sqrt+10": 14.2,
    "px": 24,
    "PX": 35,
    "cat": "A1",
    "phase": "approach",
    "certainty": "suspected",
    "FIELD16": "",
    "meta": "weather",
    "cause": "bad weather",
    "interesting notes": "",
    "FIELD20": "",
    "Cause sources": "",
    "FIELD22": ""
  },
  {
    "code": 241,
    "date": "17-May-2001",
    "plane_type": "Yakovlev 40",
    "location": "near Sari",
    "country": "USA",
    "registration": "EP-TQP",
    "operator": "Faraz Qeshm",
    "fat": "30",
    "f": 30,
    "sqrt+10": 15.5,
    "px": 25,
    "PX": 35,
    "cat": "A1",
    "phase": "en route",
    "certainty": "suspected",
    "FIELD16": "",
    "meta": "weather",
    "cause": "bad weather",
    "interesting notes": "",
    "FIELD20": "",
    "Cause sources": "",
    "FIELD22": ""
  },
  {
    "code": 242,
    "date": "4-Jul-2001",
    "plane_type": "Tupolev 154M",
    "location": "near Burdakovka",
    "country": "Russia",
    "registration": "RA-85845",
    "operator": "Vladivostokavia",
    "fat": "145",
    "f": 145,
    "sqrt+10": 22,
    "px": 32,
    "PX": 75,
    "cat": "A1",
    "phase": "approach",
    "certainty": "probable",
    "FIELD16": "",
    "meta": "human_error",
    "cause": "pilot error",
    "interesting notes": "",
    "FIELD20": "",
    "Cause sources": "",
    "FIELD22": ""
  },
  {
    "code": 243,
    "date": "23-Aug-2001",
    "plane_type": "Antonov 28",
    "location": "Mugogo",
    "country": "DR Congo",
    "registration": "3C-LLA",
    "operator": "Victoria Air",
    "fat": "4",
    "f": 4,
    "sqrt+10": 12,
    "px": 22,
    "PX": 15,
    "cat": "A1",
    "phase": "en route",
    "certainty": "suspected",
    "FIELD16": "",
    "meta": "human_error",
    "cause": "overloaded",
    "interesting notes": "",
    "FIELD20": "",
    "Cause sources": "",
    "FIELD22": ""
  },
  {
    "code": 244,
    "date": "29-Aug-2001",
    "plane_type": "CASA CN-235-200",
    "location": "Malaga Airport",
    "country": "Spain",
    "registration": "EC-FBC",
    "operator": "Binter Méditerraneo",
    "fat": "4",
    "f": 4,
    "sqrt+10": 12,
    "px": 22,
    "PX": 15,
    "cat": "A1",
    "phase": "approach",
    "certainty": "suspected",
    "FIELD16": "",
    "meta": "mechanical",
    "cause": "mechanical failure",
    "interesting notes": "",
    "FIELD20": "",
    "Cause sources": "",
    "FIELD22": ""
  },
  {
    "code": 245,
    "date": "11-Sep-2001",
    "plane_type": "Boeing 757-222",
    "location": "near Shanksville, PA",
    "country": "USA",
    "registration": "N591UA",
    "operator": "United Airlines",
    "fat": "44",
    "f": 44,
    "sqrt+10": 16.6,
    "px": 27,
    "PX": 45,
    "cat": "H1",
    "phase": "en route",
    "certainty": "confirmed",
    "FIELD16": "",
    "meta": "criminal",
    "cause": "intentionally crashed",
    "interesting notes": "",
    "FIELD20": "",
    "Cause sources": "",
    "FIELD22": ""
  },
  {
    "code": 246,
    "date": "11-Sep-2001",
    "plane_type": "Boeing 757-223",
    "location": "Washington, DC",
    "country": "USA",
    "registration": "N644AA",
    "operator": "American Airlines",
    "fat": "64+ 125",
    "f": 189,
    "sqrt+10": 23.7,
    "px": 34,
    "PX": 75,
    "cat": "H1",
    "phase": "en route",
    "certainty": "confirmed",
    "FIELD16": "",
    "meta": "criminal",
    "cause": "intentionally crashed",
    "interesting notes": "",
    "FIELD20": "",
    "Cause sources": "",
    "FIELD22": ""
  },
  {
    "code": 247,
    "date": "11-Sep-2001",
    "plane_type": "Boeing 767-222",
    "location": "New York, NY",
    "country": "USA",
    "registration": "N612UA",
    "operator": "United Airlines",
    "fat": "65+ 900",
    "f": 965,
    "sqrt+10": 41.1,
    "px": 51,
    "PX": 200,
    "cat": "H1",
    "phase": "en route",
    "certainty": "confirmed",
    "FIELD16": "",
    "meta": "criminal",
    "cause": "intentionally crashed",
    "interesting notes": "",
    "FIELD20": "",
    "Cause sources": "",
    "FIELD22": ""
  },
  {
    "code": 248,
    "date": "11-Sep-2001",
    "plane_type": "Boeing 767-223ER",
    "location": "New York, NY",
    "country": "USA",
    "registration": "N334AA",
    "operator": "American Airlines",
    "fat": "92+ 1600",
    "f": 1692,
    "sqrt+10": 51.1,
    "px": 61,
    "PX": 200,
    "cat": "H1",
    "phase": "en route",
    "certainty": "confirmed",
    "FIELD16": "",
    "meta": "criminal",
    "cause": "intentionally crashed",
    "interesting notes": "",
    "FIELD20": "",
    "Cause sources": "",
    "FIELD22": ""
  },
  {
    "code": 249,
    "date": "12-Sep-2001",
    "plane_type": "Let L-410UVP-E",
    "location": "near Chichén Itzá",
    "country": "Mexico",
    "registration": "XA-ACM",
    "operator": "Aero Ferinco",
    "fat": "19",
    "f": 19,
    "sqrt+10": 14.4,
    "px": 24,
    "PX": 35,
    "cat": "A1",
    "phase": "initial climb",
    "certainty": "suspected",
    "FIELD16": "",
    "meta": "mechanical",
    "cause": "mechanical failure",
    "interesting notes": "",
    "FIELD20": "",
    "Cause sources": "",
    "FIELD22": ""
  },
  {
    "code": 250,
    "date": "15-Sep-2001",
    "plane_type": "Fokker 100",
    "location": "near Belo Horizon",
    "country": "Brazil",
    "registration": "PT-MRN",
    "operator": "TAM Brasil",
    "fat": "1",
    "f": 1,
    "sqrt+10": 11,
    "px": 21,
    "PX": 15,
    "cat": "A2",
    "phase": "en route",
    "certainty": "probable",
    "FIELD16": "",
    "meta": "mechanical",
    "cause": "engine failure",
    "interesting notes": "",
    "FIELD20": "",
    "Cause sources": "",
    "FIELD22": ""
  },
  {
    "code": 251,
    "date": "18-Sep-2001",
    "plane_type": "Let L-410UVP-E",
    "location": "Guatemala City-La Aurora Airport",
    "country": "Guatemala",
    "registration": "TG-CFE",
    "operator": "Atlantic Airlines",
    "fat": "8",
    "f": 8,
    "sqrt+10": 12.8,
    "px": 23,
    "PX": 25,
    "cat": "A1",
    "phase": "takeoff",
    "certainty": "suspected",
    "FIELD16": "",
    "meta": "human_error",
    "cause": "pilot error",
    "interesting notes": "",
    "FIELD20": "",
    "Cause sources": "",
    "FIELD22": ""
  },
  {
    "code": 252,
    "date": "4-Oct-2001",
    "plane_type": "Tupolev 154M",
    "location": "Black Sea off Adler, Russia",
    "country": "Atlantic Ocean",
    "registration": "RA-85693",
    "operator": "Sibir Airlines",
    "fat": "7",
    "f": 7,
    "sqrt+10": 12.6,
    "px": 23,
    "PX": 25,
    "cat": "C1",
    "phase": "en route",
    "certainty": "confirmed",
    "FIELD16": "",
    "meta": "human_error",
    "cause": "pilot error",
    "interesting notes": "",
    "FIELD20": "",
    "Cause sources": "",
    "FIELD22": ""
  },
  {
    "code": 253,
    "date": "8-Oct-2001",
    "plane_type": "McDonnell Douglas MD-87",
    "location": "Milano-Linate Airport",
    "country": "Italy",
    "registration": "SE-DMA",
    "operator": "Scandinavian Airlines System - SAS",
    "fat": "110+ 4",
    "f": 114,
    "sqrt+10": 20.7,
    "px": 31,
    "PX": 65,
    "cat": "A1",
    "phase": "takeoff",
    "certainty": "suspected",
    "FIELD16": "",
    "meta": "human_error",
    "cause": "pilot error",
    "interesting notes": "",
    "FIELD20": "",
    "Cause sources": "",
    "FIELD22": ""
  },
  {
    "code": 254,
    "date": "12-Nov-2001",
    "plane_type": "Airbus A300B4-605R",
    "location": "Belle Harbor, NY",
    "country": "USA",
    "registration": "N14053",
    "operator": "American Airlines",
    "fat": "260+ 5",
    "f": 265,
    "sqrt+10": 26.3,
    "px": 36,
    "PX": 85,
    "cat": "A1",
    "phase": "en route",
    "certainty": "suspected",
    "FIELD16": "",
    "meta": "human_error",
    "cause": "pilot error",
    "interesting notes": "",
    "FIELD20": "",
    "Cause sources": "",
    "FIELD22": ""
  },
  {
    "code": 255,
    "date": "19-Nov-2001",
    "plane_type": "Ilyushin 18V",
    "location": "near Kalyazin",
    "country": "Russia",
    "registration": "RA-75840",
    "operator": "IRS Aero",
    "fat": "27",
    "f": 27,
    "sqrt+10": 15.2,
    "px": 25,
    "PX": 35,
    "cat": "A1",
    "phase": "en route",
    "certainty": "suspected",
    "FIELD16": "",
    "meta": "human_error",
    "cause": "pilot error",
    "interesting notes": "",
    "FIELD20": "",
    "Cause sources": "",
    "FIELD22": ""
  },
  {
    "code": 256,
    "date": "24-Nov-2001",
    "plane_type": "Avro RJ.100",
    "location": "near Zürich-Kloten",
    "country": "Switzerland",
    "registration": "HB-IXM",
    "operator": "Crossair",
    "fat": "24",
    "f": 24,
    "sqrt+10": 14.9,
    "px": 25,
    "PX": 35,
    "cat": "A1",
    "phase": "approach",
    "certainty": "probable",
    "FIELD16": "",
    "meta": "human_error",
    "cause": "pilot error",
    "interesting notes": "",
    "FIELD20": "",
    "Cause sources": "",
    "FIELD22": ""
  },
  {
    "code": 257,
    "date": "16-Dec-2001",
    "plane_type": "Let L-410UVP-E",
    "location": "near San Antonio",
    "country": "Colombia",
    "registration": "HK-4175X",
    "operator": "Heliandes",
    "fat": "16",
    "f": 16,
    "sqrt+10": 14,
    "px": 24,
    "PX": 35,
    "cat": "A1",
    "phase": "en route",
    "certainty": "suspected",
    "FIELD16": "",
    "meta": "human_error",
    "cause": "pilot error",
    "interesting notes": "",
    "FIELD20": "",
    "Cause sources": "",
    "FIELD22": ""
  },
  {
    "code": 258,
    "date": "16-Jan-2002",
    "plane_type": "Boeing 737-3Q8",
    "location": "near Yokyakarta",
    "country": "Indonesia",
    "registration": "PK-GWA",
    "operator": "Garuda",
    "fat": "1",
    "f": 1,
    "sqrt+10": 11,
    "px": 21,
    "PX": 15,
    "cat": "A1",
    "phase": "approach",
    "certainty": "probable",
    "FIELD16": "",
    "meta": "weather",
    "cause": "bad weather",
    "interesting notes": "landed in Bengawan Solo River. Some attempts to restart engines \"were contrary to the procedures contained in the Boeing 737 Operations Manual\"",
    "FIELD20": "",
    "Cause sources": "",
    "FIELD22": ""
  },
  {
    "code": 259,
    "date": "17-Jan-2002",
    "plane_type": "Fairchild FH-227E",
    "location": "Cerro El Tigre",
    "country": "Colombia",
    "registration": "HC-AYM",
    "operator": "Petroproduccion",
    "fat": "26",
    "f": 26,
    "sqrt+10": 15.1,
    "px": 25,
    "PX": 35,
    "cat": "A1",
    "phase": "en route",
    "certainty": "suggested",
    "FIELD16": "",
    "meta": "human_error",
    "cause": "pilot & crew error",
    "interesting notes": "",
    "FIELD20": "",
    "Cause sources": "",
    "FIELD22": ""
  },
  {
    "code": 260,
    "date": "28-Jan-2002",
    "plane_type": "Boeing 727-134",
    "location": "near Ipiales",
    "country": "Colombia",
    "registration": "HC-BLF",
    "operator": "TAME Ecuador",
    "fat": "94",
    "f": 94,
    "sqrt+10": 19.7,
    "px": 30,
    "PX": 65,
    "cat": "A1",
    "phase": "approach",
    "certainty": "suggested",
    "FIELD16": "",
    "meta": "human_error",
    "cause": "pilot error",
    "interesting notes": "crew had necessary information, however \"The approach procedure called for a speed of 180 kts, but the actual speed was much higher at 230 knots\"",
    "FIELD20": "",
    "Cause sources": "",
    "FIELD22": ""
  },
  {
    "code": 261,
    "date": "12-Feb-2002",
    "plane_type": "Tupolev 154M",
    "location": "near Sarab-e Do Rah",
    "country": "Iran",
    "registration": "EP-MBS",
    "operator": "Iran Air Tours",
    "fat": "119",
    "f": 119,
    "sqrt+10": 20.9,
    "px": 31,
    "PX": 65,
    "cat": "A1",
    "phase": "approach",
    "certainty": "suggested",
    "FIELD16": "",
    "meta": "weather",
    "cause": "bad weather",
    "interesting notes": "crashed into Kuh-e Sefid Mountain at 9100 feet. approach was 'non-prescription' = an emergency landing??",
    "FIELD20": "",
    "Cause sources": "http://en.wikipedia.org/wiki/Iran_Airtour#cite_note-7",
    "FIELD22": ""
  },
  {
    "code": 262,
    "date": "14-Mar-2002",
    "plane_type": "Antonov 2R",
    "location": "El Huerto",
    "country": "Cuba",
    "registration": "CU-T1020",
    "operator": "Aerotaxi",
    "fat": "16",
    "f": 16,
    "sqrt+10": 14,
    "px": 24,
    "PX": 35,
    "cat": "A1",
    "phase": "en route",
    "certainty": "confirmed",
    "FIELD16": "",
    "meta": "mechanical",
    "cause": "wing loss",
    "interesting notes": "",
    "FIELD20": "",
    "Cause sources": "",
    "FIELD22": ""
  },
  {
    "code": 263,
    "date": "15-Apr-2002",
    "plane_type": "Boeing 767-2J6ER",
    "location": "near Pusan-Kimhae...",
    "country": "South Korea",
    "registration": "B-2552",
    "operator": "Air China",
    "fat": "129",
    "f": 129,
    "sqrt+10": 21.4,
    "px": 31,
    "PX": 75,
    "cat": "A1",
    "phase": "approach",
    "certainty": "confirmed",
    "FIELD16": "",
    "meta": "weather",
    "cause": "bad weather",
    "interesting notes": "",
    "FIELD20": "",
    "Cause sources": "",
    "FIELD22": ""
  },
  {
    "code": 264,
    "date": "4-May-2002",
    "plane_type": "BAC One-Eleven 525FT",
    "location": "Kano",
    "country": "Nigeria",
    "registration": "5N-ESF",
    "operator": "EAS Airlines",
    "fat": "71+ 78",
    "f": 149,
    "sqrt+10": 22.2,
    "px": 32,
    "PX": 75,
    "cat": "A1",
    "phase": "en route",
    "certainty": "probably",
    "FIELD16": "",
    "meta": "mechanical",
    "cause": "aircraft stalled",
    "interesting notes": "Aircraft had been grounded for 52 days due to engine problems. A new engine was fitted 10 hours before crash",
    "FIELD20": "",
    "Cause sources": "",
    "FIELD22": ""
  },
  {
    "code": 265,
    "date": "7-May-2002",
    "plane_type": "Boeing 737-566",
    "location": "near Tunis-Cartha...",
    "country": "Tunisia",
    "registration": "SU-GBI",
    "operator": "EgyptAir",
    "fat": "14",
    "f": 14,
    "sqrt+10": 13.7,
    "px": 24,
    "PX": 25,
    "cat": "A1",
    "phase": "approach",
    "certainty": "unknown",
    "FIELD16": "",
    "meta": "unknown",
    "cause": "unknown",
    "interesting notes": "",
    "FIELD20": "",
    "Cause sources": "",
    "FIELD22": ""
  },
  {
    "code": 266,
    "date": "7-May-2002",
    "plane_type": "MD-82",
    "location": "near Dalian",
    "country": "China",
    "registration": "B-2138",
    "operator": "China Northern",
    "fat": "112",
    "f": 112,
    "sqrt+10": 20.6,
    "px": 31,
    "PX": 65,
    "cat": "A1",
    "phase": "approach",
    "certainty": "probable",
    "FIELD16": "",
    "meta": "criminal",
    "cause": "fire in the cabin/ insurance fraud",
    "interesting notes": "\"Authorities blamed the crash on a fire set by a passenger who bought seven insurance policies before boarding the flight, insuring his life for $170,000.\"",
    "FIELD20": "",
    "Cause sources": "",
    "FIELD22": ""
  },
  {
    "code": 267,
    "date": "25-May-2002",
    "plane_type": "DHC-6 Twin Otter 300",
    "location": "near Nabire",
    "country": "Indonesia",
    "registration": "PK-YPZ",
    "operator": "Trigana Air Service",
    "fat": "6",
    "f": 6,
    "sqrt+10": 12.4,
    "px": 22,
    "PX": 25,
    "cat": "A1",
    "phase": "en route",
    "certainty": "confirmed",
    "FIELD16": "",
    "meta": "weather",
    "cause": "bad weather",
    "interesting notes": "",
    "FIELD20": "",
    "Cause sources": "",
    "FIELD22": ""
  },
  {
    "code": 268,
    "date": "25-May-2002",
    "plane_type": "Boeing 747-209B",
    "location": "near Penghu islan...",
    "country": "Pacific Ocean (Taiwan Straight)",
    "registration": "B-18255",
    "operator": "China Airlines",
    "fat": "225",
    "f": 225,
    "sqrt+10": 25,
    "px": 35,
    "PX": 85,
    "cat": "A1",
    "phase": "en route",
    "certainty": "probably",
    "FIELD16": "",
    "meta": "mechanical",
    "cause": "structural failure",
    "interesting notes": "",
    "FIELD20": "",
    "Cause sources": "",
    "FIELD22": ""
  },
  {
    "code": 269,
    "date": "1-Jul-2002",
    "plane_type": "Tupolev 154M",
    "location": "Überlingen",
    "country": "Germany",
    "registration": "RA-85816",
    "operator": "Bashkirskie Avialinii",
    "fat": "69",
    "f": 69,
    "sqrt+10": 18.3,
    "px": 28,
    "PX": 55,
    "cat": "A1",
    "phase": "en route",
    "certainty": "confirmed",
    "FIELD16": "",
    "meta": "human_error",
    "cause": "atc error",
    "interesting notes": "",
    "FIELD20": "",
    "Cause sources": "",
    "FIELD22": ""
  },
  {
    "code": 270,
    "date": "17-Jul-2002",
    "plane_type": "DHC-6 Twin Otter 300",
    "location": "near Surkhet Airp...",
    "country": "Nepal",
    "registration": "9N-AGF",
    "operator": "Skyline Airways",
    "fat": "4",
    "f": 4,
    "sqrt+10": 12,
    "px": 22,
    "PX": 15,
    "cat": "A1",
    "phase": "en route",
    "certainty": "confirmed",
    "FIELD16": "",
    "meta": "weather",
    "cause": "bad weather",
    "interesting notes": "",
    "FIELD20": "",
    "Cause sources": "",
    "FIELD22": ""
  },
  {
    "code": 271,
    "date": "22-Aug-2002",
    "plane_type": "DHC-6 Twin Otter 300",
    "location": "near Pokhara",
    "country": "Nepal",
    "registration": "9N-AFR",
    "operator": "Shangri-La Air",
    "fat": "18",
    "f": 18,
    "sqrt+10": 14.2,
    "px": 24,
    "PX": 35,
    "cat": "A1",
    "phase": "approach",
    "certainty": "confirmed",
    "FIELD16": "",
    "meta": "weather",
    "cause": "bad weather",
    "interesting notes": "",
    "FIELD20": "",
    "Cause sources": "",
    "FIELD22": ""
  },
  {
    "code": 272,
    "date": "29-Aug-2002",
    "plane_type": "Antonov 28",
    "location": "near Ayan",
    "country": "Russia",
    "registration": "RA-28932",
    "operator": "Vostok Aviakompania",
    "fat": "16",
    "f": 16,
    "sqrt+10": 14,
    "px": 24,
    "PX": 35,
    "cat": "A1",
    "phase": "approach",
    "certainty": "confirmed",
    "FIELD16": "",
    "meta": "weather",
    "cause": "bad weather",
    "interesting notes": "",
    "FIELD20": "",
    "Cause sources": "",
    "FIELD22": ""
  },
  {
    "code": 273,
    "date": "30-Aug-2002",
    "plane_type": "Embraer 120ER Brasilia",
    "location": "near Rio Branco-P...",
    "country": "Brazil",
    "registration": "PT-WRQ",
    "operator": "Rico Linhas Aéreas",
    "fat": "23",
    "f": 23,
    "sqrt+10": 14.8,
    "px": 25,
    "PX": 35,
    "cat": "A1",
    "phase": "approach",
    "certainty": "suspected",
    "FIELD16": "",
    "meta": "weather",
    "cause": "bad weather",
    "interesting notes": "",
    "FIELD20": "",
    "Cause sources": "",
    "FIELD22": ""
  },
  {
    "code": 274,
    "date": "28-Sep-2002",
    "plane_type": "DHC-3 Otter",
    "location": "near Natashquan, QC",
    "country": "Canada",
    "registration": "C-FLGA",
    "operator": "Deraps Aviation",
    "fat": "3",
    "f": 3,
    "sqrt+10": 11.7,
    "px": 22,
    "PX": 15,
    "cat": "A1",
    "phase": "approach",
    "certainty": "probably",
    "FIELD16": "",
    "meta": "human_error",
    "cause": "pilot error",
    "interesting notes": "",
    "FIELD20": "",
    "Cause sources": "",
    "FIELD22": ""
  },
  {
    "code": 275,
    "date": "25-Oct-2002",
    "plane_type": "Beechcraft A100 King Air",
    "location": "near Eveleth-Virg...",
    "country": "US",
    "registration": "N41BE",
    "operator": "Aviation Charter",
    "fat": "8",
    "f": 8,
    "sqrt+10": 12.8,
    "px": 23,
    "PX": 25,
    "cat": "A1",
    "phase": "approach",
    "certainty": "probably",
    "FIELD16": "",
    "meta": "human_error",
    "cause": "crew error",
    "interesting notes": "failed to maintain adequate air speed",
    "FIELD20": "",
    "Cause sources": "",
    "FIELD22": ""
  },
  {
    "code": 276,
    "date": "6-Nov-2002",
    "plane_type": "Fokker 50",
    "location": "near Niederanven",
    "country": "Luxembourg",
    "registration": "LX-LGB",
    "operator": "Luxair",
    "fat": "20",
    "f": 20,
    "sqrt+10": 14.5,
    "px": 24,
    "PX": 35,
    "cat": "A1",
    "phase": "approach",
    "certainty": "suggested",
    "FIELD16": "",
    "meta": "human_error",
    "cause": "crew error",
    "interesting notes": "",
    "FIELD20": "",
    "Cause sources": "",
    "FIELD22": ""
  },
  {
    "code": 277,
    "date": "11-Nov-2002",
    "plane_type": "Fokker F-27 Friendship 600",
    "location": "near Manila-Ninoy...",
    "country": "Philippines",
    "registration": "RP-C6888",
    "operator": "Laoag",
    "fat": "19",
    "f": 19,
    "sqrt+10": 14.4,
    "px": 24,
    "PX": 35,
    "cat": "A1",
    "phase": "en route",
    "certainty": "confirmed",
    "FIELD16": "",
    "meta": "mechanical",
    "cause": "engine trouble",
    "interesting notes": "",
    "FIELD20": "",
    "Cause sources": "",
    "FIELD22": ""
  },
  {
    "code": 278,
    "date": "28-Nov-2002",
    "plane_type": "Let L-410UVP-E20",
    "location": "Mara Intrepi...",
    "country": "Kenya",
    "registration": "5Y-ONT",
    "operator": "Eagle Aviation",
    "fat": "1",
    "f": 1,
    "sqrt+10": 11,
    "px": 21,
    "PX": 15,
    "cat": "A1",
    "phase": "approach",
    "certainty": "probably",
    "FIELD16": "",
    "meta": "mechanical",
    "cause": "engine trouble",
    "interesting notes": "",
    "FIELD20": "",
    "Cause sources": "",
    "FIELD22": ""
  },
  {
    "code": 279,
    "date": "23-Dec-2002",
    "plane_type": "Antonov 140",
    "location": "near Baghrabad",
    "country": "Iran",
    "registration": "UR-14003",
    "operator": "Aeromist Kharkiv",
    "fat": "44",
    "f": 44,
    "sqrt+10": 16.6,
    "px": 27,
    "PX": 45,
    "cat": "A1",
    "phase": "en route",
    "certainty": "confirmed",
    "FIELD16": "",
    "meta": "human_error",
    "cause": "pilot error",
    "interesting notes": "pilot failed to use GPS",
    "FIELD20": "",
    "Cause sources": "",
    "FIELD22": ""
  },
  {
    "code": 280,
    "date": "27-Dec-2002",
    "plane_type": "Let L-410UVP",
    "location": "near Anjouan",
    "country": "Comoros",
    "registration": "9XR-RB",
    "operator": "Ocean Airlines",
    "fat": "1",
    "f": 1,
    "sqrt+10": 11,
    "px": 21,
    "PX": 15,
    "cat": "A1",
    "phase": "approach",
    "certainty": "confirmed",
    "FIELD16": "",
    "meta": "weather",
    "cause": "thunderstorm",
    "interesting notes": "",
    "FIELD20": "",
    "Cause sources": "",
    "FIELD22": ""
  },
  {
    "code": 281,
    "date": "8-Jan-2003",
    "plane_type": "Beechcraft 1900D",
    "location": "Charlotte-Do...",
    "country": "US",
    "registration": "N233YV",
    "operator": "Air Midwest, opf. US Airways Express",
    "fat": "21",
    "f": 21,
    "sqrt+10": 14.6,
    "px": 25,
    "PX": 35,
    "cat": "A1",
    "phase": "takeoff",
    "certainty": "confirmed",
    "FIELD16": "",
    "meta": "human_error",
    "cause": "airline error",
    "interesting notes": "",
    "FIELD20": "",
    "Cause sources": "",
    "FIELD22": ""
  },
  {
    "code": 282,
    "date": "8-Jan-2003",
    "plane_type": "Avro RJ.100",
    "location": "Diyarbakir A...",
    "country": "Turkey",
    "registration": "TC-THG",
    "operator": "THY",
    "fat": "75",
    "f": 75,
    "sqrt+10": 18.7,
    "px": 29,
    "PX": 55,
    "cat": "A1",
    "phase": "approach",
    "certainty": "confirmed",
    "FIELD16": "",
    "meta": "weather",
    "cause": "low visibility",
    "interesting notes": "",
    "FIELD20": "",
    "Cause sources": "",
    "FIELD22": ""
  },
  {
    "code": 283,
    "date": "9-Jan-2003",
    "plane_type": "Fokker F-28 Fellowship 1000",
    "location": "near Chachapoyas",
    "country": "Peru",
    "registration": "OB-1396",
    "operator": "TANS",
    "fat": "46",
    "f": 46,
    "sqrt+10": 16.8,
    "px": 27,
    "PX": 45,
    "cat": "A1",
    "phase": "approach",
    "certainty": "suggested",
    "FIELD16": "",
    "meta": "human_error",
    "cause": "pilot & crew error",
    "interesting notes": "",
    "FIELD20": "",
    "Cause sources": "",
    "FIELD22": ""
  },
  {
    "code": 284,
    "date": "6-Mar-2003",
    "plane_type": "Boeing 737-2T4",
    "location": "Tamanrasset ...",
    "country": "Algeria",
    "registration": "7T-VEZ",
    "operator": "Air Algérie",
    "fat": "102",
    "f": 102,
    "sqrt+10": 20.1,
    "px": 30,
    "PX": 65,
    "cat": "A1",
    "phase": "initial climb",
    "certainty": "probable",
    "FIELD16": "",
    "meta": "mechanical",
    "cause": "engine loss",
    "interesting notes": "",
    "FIELD20": "",
    "Cause sources": "",
    "FIELD22": ""
  },
  {
    "code": 285,
    "date": "27-Mar-2003",
    "plane_type": "DHC-6 Twin Otter 300",
    "location": "near Mulia",
    "country": "Indonesia",
    "registration": "PK-WAY",
    "operator": "Air Regional",
    "fat": "4",
    "f": 4,
    "sqrt+10": 12,
    "px": 22,
    "PX": 15,
    "cat": "A1",
    "phase": "en route",
    "certainty": "unknown",
    "FIELD16": "",
    "meta": "unknown",
    "cause": "unknown",
    "interesting notes": "",
    "FIELD20": "",
    "Cause sources": "",
    "FIELD22": ""
  },
  {
    "code": 286,
    "date": "26-May-2003",
    "plane_type": "Yakovlev 42D",
    "location": "near Maçka",
    "country": "Turkey",
    "registration": "UR-42352",
    "operator": "UM Air",
    "fat": "75",
    "f": 75,
    "sqrt+10": 18.7,
    "px": 29,
    "PX": 55,
    "cat": "A1",
    "phase": "approach",
    "certainty": "probably",
    "FIELD16": "",
    "meta": "human_error",
    "cause": "crew error",
    "interesting notes": "",
    "FIELD20": "",
    "Cause sources": "",
    "FIELD22": ""
  },
  {
    "code": 287,
    "date": "22-Jun-2003",
    "plane_type": "Canadair CL-600-2B19 Regional",
    "location": "near Brest-Guipav...",
    "country": "France",
    "registration": "F-GRJS",
    "operator": "Brit Air",
    "fat": "1",
    "f": 1,
    "sqrt+10": 11,
    "px": 21,
    "PX": 15,
    "cat": "A1",
    "phase": "approach",
    "certainty": "confirmed",
    "FIELD16": "",
    "meta": "human_error",
    "cause": "crew error",
    "interesting notes": "",
    "FIELD20": "",
    "Cause sources": "",
    "FIELD22": ""
  },
  {
    "code": 288,
    "date": "8-Jul-2003",
    "plane_type": "Boeing 737-2J8C",
    "location": "near Port Sudan",
    "country": "Sudan",
    "registration": "ST-AFK",
    "operator": "Sudan Airways",
    "fat": "116",
    "f": 116,
    "sqrt+10": 20.8,
    "px": 31,
    "PX": 65,
    "cat": "A1",
    "phase": "approach",
    "certainty": "confirmed",
    "FIELD16": "",
    "meta": "mechanical",
    "cause": "engine failure",
    "interesting notes": "",
    "FIELD20": "",
    "Cause sources": "",
    "FIELD22": ""
  },
  {
    "code": 289,
    "date": "13-Jul-2003",
    "plane_type": "Let L-410UVP-E",
    "location": "near San Cristobal",
    "country": "Venezuela",
    "registration": "YV-1060CP",
    "operator": "Ruiban and Duran",
    "fat": "4",
    "f": 4,
    "sqrt+10": 12,
    "px": 22,
    "PX": 15,
    "cat": "A1",
    "phase": "en route",
    "certainty": "unknown",
    "FIELD16": "",
    "meta": "unknown",
    "cause": "unknown",
    "interesting notes": "",
    "FIELD20": "",
    "Cause sources": "",
    "FIELD22": ""
  },
  {
    "code": 290,
    "date": "19-Jul-2003",
    "plane_type": "Swearingen SA226-TC Metro II",
    "location": "Mount Kenya",
    "country": "Kenya",
    "registration": "ZS-OYI",
    "operator": "Ryan Blake Air Charter",
    "fat": "14",
    "f": 14,
    "sqrt+10": 13.7,
    "px": 24,
    "PX": 25,
    "cat": "A1",
    "phase": "en route",
    "certainty": "unknown",
    "FIELD16": "",
    "meta": "unknown",
    "cause": "unknown",
    "interesting notes": "",
    "FIELD20": "",
    "Cause sources": "",
    "FIELD22": ""
  },
  {
    "code": 291,
    "date": "24-Aug-2003",
    "plane_type": "Let L-410UVP-E3",
    "location": "near Cap Haitien ...",
    "country": "Haiti",
    "registration": "HH-PRV",
    "operator": "Tropical Airways",
    "fat": "21",
    "f": 21,
    "sqrt+10": 14.6,
    "px": 25,
    "PX": 35,
    "cat": "A1",
    "phase": "en route",
    "certainty": "probably",
    "FIELD16": "",
    "meta": "mechanical",
    "cause": "baggage door opened during flight",
    "interesting notes": "",
    "FIELD20": "",
    "Cause sources": "",
    "FIELD22": ""
  },
  {
    "code": 292,
    "date": "8-Nov-2003",
    "plane_type": "Shorts SC.7 Skyvan 3M-100",
    "location": "near Ogle",
    "country": "Guyana",
    "registration": "8R-GMC",
    "operator": "Trans Guyana Airways",
    "fat": "2",
    "f": 2,
    "sqrt+10": 11.4,
    "px": 21,
    "PX": 15,
    "cat": "A1",
    "phase": "takeoff",
    "certainty": "certain",
    "FIELD16": "",
    "meta": "mechanical",
    "cause": "engine failure",
    "interesting notes": "",
    "FIELD20": "",
    "Cause sources": "",
    "FIELD22": ""
  },
  {
    "code": 293,
    "date": "16-Dec-2003",
    "plane_type": "DHC-3 Turbo Otter",
    "location": "near Jellicoe, ON",
    "country": "Canada",
    "registration": "C-GOFF",
    "operator": "Huron Air",
    "fat": "2",
    "f": 2,
    "sqrt+10": 11.4,
    "px": 21,
    "PX": 15,
    "cat": "A1",
    "phase": "takeoff",
    "certainty": "probably",
    "FIELD16": "",
    "meta": "human_error",
    "cause": "pilot error",
    "interesting notes": "",
    "FIELD20": "",
    "Cause sources": "",
    "FIELD22": ""
  },
  {
    "code": 294,
    "date": "25-Dec-2003",
    "plane_type": "Boeing 727-223",
    "location": "Cotonou Airp...",
    "country": "Benin",
    "registration": "3X-GDO",
    "operator": "UTA",
    "fat": "141",
    "f": 141,
    "sqrt+10": 21.9,
    "px": 32,
    "PX": 75,
    "cat": "A1",
    "phase": "takeoff",
    "certainty": "confirmed",
    "FIELD16": "",
    "meta": "human_error",
    "cause": "overloaded",
    "interesting notes": "",
    "FIELD20": "",
    "Cause sources": "",
    "FIELD22": ""
  },
  {
    "code": 295,
    "date": "3-Jan-2004",
    "plane_type": "Boeing 737-3Q8",
    "location": "near Sharm el Sheikh",
    "country": "Egypt",
    "registration": "SU-ZCF",
    "operator": "Flash Airlines",
    "fat": "148",
    "f": 148,
    "sqrt+10": 22.2,
    "px": 32,
    "PX": 75,
    "cat": "A1",
    "phase": "en route",
    "certainty": "unknown",
    "FIELD16": "",
    "meta": "unknown",
    "cause": "unknown",
    "interesting notes": "",
    "FIELD20": "",
    "Cause sources": "",
    "FIELD22": ""
  },
  {
    "code": 296,
    "date": "13-Jan-2004",
    "plane_type": "Yakovlev 40",
    "location": "Tashkent Air...",
    "country": "Uzbekistan",
    "registration": "UK-87985",
    "operator": "Uzbekistan Airways",
    "fat": "37",
    "f": 37,
    "sqrt+10": 16.1,
    "px": 26,
    "PX": 45,
    "cat": "A1",
    "phase": "landing",
    "certainty": "probably",
    "FIELD16": "",
    "meta": "weather",
    "cause": "bad weather",
    "interesting notes": "",
    "FIELD20": "",
    "Cause sources": "",
    "FIELD22": ""
  },
  {
    "code": 297,
    "date": "28-Jan-2004",
    "plane_type": "Beechcraft 1900D",
    "location": "near Ghardaia-Nou...",
    "country": "Algeria",
    "registration": "7T-VIN",
    "operator": "Tassili Airlines",
    "fat": "1",
    "f": 1,
    "sqrt+10": 11,
    "px": 21,
    "PX": 15,
    "cat": "A1",
    "phase": "approach",
    "certainty": "probably",
    "FIELD16": "",
    "meta": "human_error",
    "cause": "another airplane on runway",
    "interesting notes": "",
    "FIELD20": "",
    "Cause sources": "",
    "FIELD22": ""
  },
  {
    "code": 298,
    "date": "10-Feb-2004",
    "plane_type": "Fokker 50",
    "location": "near Sharjah Airp...",
    "country": "UAE",
    "registration": "EP-LCA",
    "operator": "Kish Air",
    "fat": "43",
    "f": 43,
    "sqrt+10": 16.6,
    "px": 27,
    "PX": 45,
    "cat": "A1",
    "phase": "approach",
    "certainty": "unknown",
    "FIELD16": "",
    "meta": "unknown",
    "cause": "unknown",
    "interesting notes": "",
    "FIELD20": "",
    "Cause sources": "",
    "FIELD22": ""
  },
  {
    "code": 299,
    "date": "11-May-2004",
    "plane_type": "Antonov 12BP",
    "location": "near Dalang",
    "country": "Sudan",
    "registration": "ST-SIG",
    "operator": "El Magal Aviation",
    "fat": "1",
    "f": 1,
    "sqrt+10": 11,
    "px": 21,
    "PX": 15,
    "cat": "A1",
    "phase": "unknown",
    "certainty": "probably",
    "FIELD16": "",
    "meta": "weather",
    "cause": "bad weather",
    "interesting notes": "",
    "FIELD20": "",
    "Cause sources": "",
    "FIELD22": ""
  },
  {
    "code": 300,
    "date": "14-May-2004",
    "plane_type": "Embraer 120ER Brasilia",
    "location": "near Manaus, AM",
    "country": "Brazil",
    "registration": "PT-WRO",
    "operator": "Rico Linhas Aéreas",
    "fat": "33",
    "f": 33,
    "sqrt+10": 15.7,
    "px": 26,
    "PX": 45,
    "cat": "A1",
    "phase": "approach",
    "certainty": "unknown",
    "FIELD16": "",
    "meta": "unknown",
    "cause": "unknown",
    "interesting notes": "",
    "FIELD20": "",
    "Cause sources": "",
    "FIELD22": ""
  },
  {
    "code": 301,
    "date": "8-Jun-2004",
    "plane_type": "Hawker Siddeley HS-748-232 Srs",
    "location": "near Libreville A...",
    "country": "Gabon",
    "registration": "TR-LFW",
    "operator": "Gabon Express",
    "fat": "19",
    "f": 19,
    "sqrt+10": 14.4,
    "px": 24,
    "PX": 35,
    "cat": "A1",
    "phase": "en route",
    "certainty": "suspected",
    "FIELD16": "",
    "meta": "mechanical",
    "cause": "loss of oil pressure, problem with hydraulics",
    "interesting notes": "",
    "FIELD20": "",
    "Cause sources": "",
    "FIELD22": ""
  },
  {
    "code": 302,
    "date": "26-Jun-2004",
    "plane_type": "Lisunov Li-2T",
    "location": "near Moskva-Myach...",
    "country": "Russia",
    "registration": "RA-1300K",
    "operator": "FLA RF",
    "fat": "2",
    "f": 2,
    "sqrt+10": 11.4,
    "px": 21,
    "PX": 15,
    "cat": "A1",
    "phase": "en route",
    "certainty": "probably",
    "FIELD16": "",
    "meta": "mechanical",
    "cause": "engine lost power",
    "interesting notes": "a dog was killed on the ground",
    "FIELD20": "",
    "Cause sources": "",
    "FIELD22": ""
  },
  {
    "code": 303,
    "date": "24-Aug-2004",
    "plane_type": "Tupolev 134A-3",
    "location": "near Buchalki",
    "country": "Russia",
    "registration": "RA-65080",
    "operator": "Volga-Aviaexpress",
    "fat": "44",
    "f": 44,
    "sqrt+10": 16.6,
    "px": 27,
    "PX": 45,
    "cat": "C1",
    "phase": "en route",
    "certainty": "confirmed",
    "FIELD16": "",
    "meta": "criminal",
    "cause": "collision; suicide bombing",
    "interesting notes": "",
    "FIELD20": "",
    "Cause sources": "",
    "FIELD22": ""
  },
  {
    "code": 304,
    "date": "24-Aug-2004",
    "plane_type": "Tupolev 154B-2",
    "location": "near Gluboki",
    "country": "Russia",
    "registration": "RA-85556",
    "operator": "Sibir Airlines",
    "fat": "46",
    "f": 46,
    "sqrt+10": 16.8,
    "px": 27,
    "PX": 45,
    "cat": "C1",
    "phase": "en route",
    "certainty": "confirmed",
    "FIELD16": "",
    "meta": "criminal",
    "cause": "collision; suicide bombing",
    "interesting notes": "suicide bomber bribed airline employee in charge of boardings to let her on the plane",
    "FIELD20": "",
    "Cause sources": "",
    "FIELD22": ""
  },
  {
    "code": 305,
    "date": "19-Oct-2004",
    "plane_type": "British Aerospace 3201 Jetstre",
    "location": "near Kirksville R...",
    "country": "US",
    "registration": "N875JX",
    "operator": "Corporate Airlines, opf. AmericanConnection",
    "fat": "13",
    "f": 13,
    "sqrt+10": 13.6,
    "px": 24,
    "PX": 25,
    "cat": "A1",
    "phase": "approach",
    "certainty": "confirmed",
    "FIELD16": "",
    "meta": "human_error",
    "cause": "pilot error",
    "interesting notes": "",
    "FIELD20": "",
    "Cause sources": "",
    "FIELD22": ""
  },
  {
    "code": 306,
    "date": "18-Nov-2004",
    "plane_type": "British Aerospace 3101 Jetstre",
    "location": "Caracas-Simo...",
    "country": "Venezuela",
    "registration": "YV-1083C",
    "operator": "Venezolana",
    "fat": "4",
    "f": 4,
    "sqrt+10": 12,
    "px": 22,
    "PX": 15,
    "cat": "A1",
    "phase": "landing",
    "certainty": "confirmed",
    "FIELD16": "",
    "meta": "weather",
    "cause": "low visibility",
    "interesting notes": "",
    "FIELD20": "",
    "Cause sources": "",
    "FIELD22": ""
  },
  {
    "code": 307,
    "date": "21-Nov-2004",
    "plane_type": "Canadair CL-600-2B19 Regional",
    "location": "near Baotou Airpo...",
    "country": "China",
    "registration": "B-3072",
    "operator": "China Yunnan",
    "fat": "53+ 2",
    "f": 55,
    "sqrt+10": 17.4,
    "px": 27,
    "PX": 55,
    "cat": "A1",
    "phase": "initial climb",
    "certainty": "confirmed",
    "FIELD16": "",
    "meta": "weather",
    "cause": "frost on wings",
    "interesting notes": "",
    "FIELD20": "",
    "Cause sources": "",
    "FIELD22": ""
  },
  {
    "code": 308,
    "date": "28-Nov-2004",
    "plane_type": "Canadair CL-600-2A12 Challenge",
    "location": "Montrose Cou...",
    "country": "US",
    "registration": "N873G",
    "operator": "Hop-A-Jet Inc, opf. Global Aviation",
    "fat": "3",
    "f": 3,
    "sqrt+10": 11.7,
    "px": 22,
    "PX": 15,
    "cat": "A1",
    "phase": "takeoff",
    "certainty": "probably",
    "FIELD16": "",
    "meta": "human_error",
    "cause": "crew error; ice on wings",
    "interesting notes": "",
    "FIELD20": "",
    "Cause sources": "",
    "FIELD22": ""
  },
  {
    "code": 309,
    "date": "30-Nov-2004",
    "plane_type": "MBB HFB-320 Hansa Jet",
    "location": "Howell Islan...",
    "country": "US",
    "registration": "N604GA",
    "operator": "Grand Aire Express",
    "fat": "2",
    "f": 2,
    "sqrt+10": 11.4,
    "px": 21,
    "PX": 15,
    "cat": "A1",
    "phase": "en route",
    "certainty": "probably",
    "FIELD16": "",
    "meta": "human_error",
    "cause": "pilot error/ maintenance error",
    "interesting notes": "",
    "FIELD20": "",
    "Cause sources": "",
    "FIELD22": ""
  },
  {
    "code": 310,
    "date": "30-Nov-2004",
    "plane_type": "MD-82",
    "location": "Solo City-Ad...",
    "country": "Indonesia",
    "registration": "PK-LMN",
    "operator": "Lion Air",
    "fat": "25",
    "f": 25,
    "sqrt+10": 15,
    "px": 25,
    "PX": 35,
    "cat": "A1",
    "phase": "landing",
    "certainty": "suspected",
    "FIELD16": "",
    "meta": "weather",
    "cause": "water on runway",
    "interesting notes": "",
    "FIELD20": "",
    "Cause sources": "",
    "FIELD22": ""
  },
  {
    "code": 311,
    "date": "3-Feb-2005",
    "plane_type": "Boeing 737-242",
    "location": "near Kabul",
    "country": "Afghanistan",
    "registration": "EX-037",
    "operator": "Phoenix Aviation, opf. Kam Air",
    "fat": "104",
    "f": 104,
    "sqrt+10": 20.2,
    "px": 30,
    "PX": 65,
    "cat": "A1",
    "phase": "en route",
    "certainty": "suspected",
    "FIELD16": "",
    "meta": "weather",
    "cause": "low visibility",
    "interesting notes": "The wreckage was found after two days. all crew and passengers killed",
    "FIELD20": "",
    "Cause sources": "",
    "FIELD22": ""
  },
  {
    "code": 312,
    "date": "22-Feb-2005",
    "plane_type": "DHC-6 Twin Otter 300",
    "location": "near Wobegon",
    "country": "Papua New Guinea",
    "registration": "P2-MFQ",
    "operator": "MAF",
    "fat": "2",
    "f": 2,
    "sqrt+10": 11.4,
    "px": 21,
    "PX": 15,
    "cat": "A1",
    "phase": "approach",
    "certainty": "suspected",
    "FIELD16": "",
    "meta": "weather",
    "cause": "low visibility",
    "interesting notes": "runway can only be used in one direction for landing and takeoff because it has a 10 degrees slope. Both pilots died in the accident. The survivors were able to walk to the village.",
    "FIELD20": "",
    "Cause sources": "",
    "FIELD22": ""
  },
  {
    "code": 313,
    "date": "16-Mar-2005",
    "plane_type": "Antonov 24RV",
    "location": "near Varandey",
    "country": "Russia",
    "registration": "RA-46489",
    "operator": "Kuzbassaviafrakht, opf. Regional Airlines",
    "fat": "28",
    "f": 28,
    "sqrt+10": 15.3,
    "px": 25,
    "PX": 35,
    "cat": "A1",
    "phase": "approach",
    "certainty": "suggested",
    "FIELD16": "",
    "meta": "human_error",
    "cause": "pilot & crew error",
    "interesting notes": "",
    "FIELD20": "",
    "Cause sources": "",
    "FIELD22": ""
  },
  {
    "code": 314,
    "date": "26-Mar-2005",
    "plane_type": "Let L-410UVP-E",
    "location": "Providencia-...",
    "country": "Colombia",
    "registration": "HK-4146",
    "operator": "West Caribbean Airways",
    "fat": "9",
    "f": 9,
    "sqrt+10": 13,
    "px": 23,
    "PX": 25,
    "cat": "A1",
    "phase": "initial climb",
    "certainty": "probable",
    "FIELD16": "",
    "meta": "mechanical",
    "cause": "engine loss",
    "interesting notes": "",
    "FIELD20": "",
    "Cause sources": "",
    "FIELD22": ""
  },
  {
    "code": 315,
    "date": "12-Apr-2005",
    "plane_type": "DHC-6 Twin Otter 100",
    "location": "near Enarotali",
    "country": "Indonesia",
    "registration": "PK-LTZ",
    "operator": "GT Air",
    "fat": "17",
    "f": 17,
    "sqrt+10": 14.1,
    "px": 24,
    "PX": 35,
    "cat": "A1",
    "phase": "en route",
    "certainty": "unknown",
    "FIELD16": "",
    "meta": "unknown",
    "cause": "unknown",
    "interesting notes": "Plane did not arrive at destination. The wreckage was found 5 days later.",
    "FIELD20": "",
    "Cause sources": "",
    "FIELD22": ""
  },
  {
    "code": 316,
    "date": "20-Apr-2005",
    "plane_type": "Boeing 707-3J9C",
    "location": "Tehran-Mehra...",
    "country": "Iran",
    "registration": "EP-SHE",
    "operator": "Saha Air",
    "fat": "3",
    "f": 3,
    "sqrt+10": 11.7,
    "px": 22,
    "PX": 15,
    "cat": "A1",
    "phase": "landing",
    "certainty": "probable",
    "FIELD16": "",
    "meta": "mechanical",
    "cause": "landing gear",
    "interesting notes": "Three passengers were killed after they fell in the river during the evacuation.",
    "FIELD20": "",
    "Cause sources": "",
    "FIELD22": ""
  },
  {
    "code": 317,
    "date": "5-May-2005",
    "plane_type": "Antonov 26",
    "location": "near Kisangani",
    "country": "Congo (Democratic Republic)",
    "registration": "",
    "operator": "Aeroworld, opf. Kisangani Airlift",
    "fat": "10",
    "f": 10,
    "sqrt+10": 13.2,
    "px": 23,
    "PX": 25,
    "cat": "A1",
    "phase": "approach",
    "certainty": "suggested",
    "FIELD16": "",
    "meta": "human_error",
    "cause": "plane hit treetops then crashed",
    "interesting notes": "The identity of the plane has not been confirmed",
    "FIELD20": "",
    "Cause sources": "",
    "FIELD22": ""
  },
  {
    "code": 318,
    "date": "7-May-2005",
    "plane_type": "Swearingen SA227-DC Metro 23",
    "location": "near Lockhart Riv...",
    "country": "Australia",
    "registration": "VH-TFU",
    "operator": "Transair Australia, opf. Aero-Tropics",
    "fat": "15",
    "f": 15,
    "sqrt+10": 13.9,
    "px": 24,
    "PX": 25,
    "cat": "A1",
    "phase": "approach",
    "certainty": "probable",
    "FIELD16": "",
    "meta": "human_error",
    "cause": "pilot & crew error",
    "interesting notes": "plane flew into the side of a heavily timbered ridge in the Iron Range National Park. All killed. Crew was aware that copilot had no formal training and limited experience to act effectively during the type of landing planned",
    "FIELD20": "",
    "Cause sources": "",
    "FIELD22": ""
  },
  {
    "code": 319,
    "date": "25-May-2005",
    "plane_type": "Antonov 12BP",
    "location": "near Biega",
    "country": "Congo (Democratic Republic)",
    "registration": "9Q-CVG",
    "operator": "Victoria Air",
    "fat": "27",
    "f": 27,
    "sqrt+10": 15.2,
    "px": 25,
    "PX": 35,
    "cat": "A1",
    "phase": "en route",
    "certainty": "unknown",
    "FIELD16": "",
    "meta": "unknown",
    "cause": "unknown",
    "interesting notes": "Crashed some 30 minutes after takeoff in a mountainous and wooded area.",
    "FIELD20": "",
    "Cause sources": "",
    "FIELD22": ""
  },
  {
    "code": 320,
    "date": "2-Jun-2005",
    "plane_type": "Antonov 24B",
    "location": "Khartoum-Civ...",
    "country": "Sudan",
    "registration": "ST-WAL",
    "operator": "al-Majal Company, opf. Marsland Aviation",
    "fat": "7",
    "f": 7,
    "sqrt+10": 12.6,
    "px": 23,
    "PX": 25,
    "cat": "A1",
    "phase": "takeoff",
    "certainty": "probable",
    "FIELD16": "",
    "meta": "mechanical",
    "cause": "engine loss",
    "interesting notes": "",
    "FIELD20": "",
    "Cause sources": "",
    "FIELD22": ""
  },
  {
    "code": 321,
    "date": "16-Jul-2005",
    "plane_type": "Antonov 24B",
    "location": "near Baney",
    "country": "Equatorial Guinea",
    "registration": "3C-VQR",
    "operator": "Equatorial Express Airlines",
    "fat": "60",
    "f": 60,
    "sqrt+10": 17.7,
    "px": 28,
    "PX": 55,
    "cat": "A1",
    "phase": "en route",
    "certainty": "unknown",
    "FIELD16": "",
    "meta": "unknown",
    "cause": "unknown ( crashed into mountainous jungle area shortly after takeoff)",
    "interesting notes": "There are conflicting reports regarding the number of persons on board. Aircraft was reportedly not undergoing the 1000-hour maintenance checks.",
    "FIELD20": "",
    "Cause sources": "",
    "FIELD22": ""
  },
  {
    "code": 322,
    "date": "6-Aug-2005",
    "plane_type": "ATR-72-202",
    "location": "near Palermo-Punt...",
    "country": "italy",
    "registration": "TS-LBB",
    "operator": "Tuninter",
    "fat": "16",
    "f": 16,
    "sqrt+10": 14,
    "px": 24,
    "PX": 35,
    "cat": "A1",
    "phase": "en route",
    "certainty": "confirmed",
    "FIELD16": "",
    "meta": "human_error",
    "cause": "maintenance/crew error",
    "interesting notes": "",
    "FIELD20": "",
    "Cause sources": "",
    "FIELD22": ""
  },
  {
    "code": 323,
    "date": "14-Aug-2005",
    "plane_type": "Boeing 737-31S",
    "location": "near Grammatikos",
    "country": "Greece",
    "registration": "5B-DBY",
    "operator": "Helios Airways",
    "fat": "121",
    "f": 121,
    "sqrt+10": 21,
    "px": 31,
    "PX": 65,
    "cat": "A1",
    "phase": "en route",
    "certainty": "probable",
    "FIELD16": "",
    "meta": "human_error",
    "cause": "maintenance/crew error",
    "interesting notes": "captain and co-captain became unconscious from  lack of oxygen. Plane went into autopilot and continued to fly til running out of fuel. all on board were killed.",
    "FIELD20": "",
    "Cause sources": "",
    "FIELD22": ""
  },
  {
    "code": 324,
    "date": "16-Aug-2005",
    "plane_type": "MD-82",
    "location": "near Machiques",
    "country": "Venezuela",
    "registration": "HK-4374X",
    "operator": "West Caribbean Airways",
    "fat": "160",
    "f": 160,
    "sqrt+10": 22.6,
    "px": 33,
    "PX": 75,
    "cat": "A1",
    "phase": "en route",
    "certainty": "confirmed",
    "FIELD16": "",
    "meta": "human_error",
    "cause": "emergency response error",
    "interesting notes": "",
    "FIELD20": "",
    "Cause sources": "",
    "FIELD22": ""
  },
  {
    "code": 325,
    "date": "23-Aug-2005",
    "plane_type": "Boeing 737-244",
    "location": "near Pucallpa-Cap...",
    "country": "Peru",
    "registration": "OB-1809-P",
    "operator": "TANS",
    "fat": "40",
    "f": 40,
    "sqrt+10": 16.3,
    "px": 26,
    "PX": 45,
    "cat": "A1",
    "phase": "approach",
    "certainty": "confirmed",
    "FIELD16": "",
    "meta": "human_error",
    "cause": "pilot & crew error",
    "interesting notes": "It appeared that a copilot under instruction was in the right hand seat while the regular copilot was in the passenger cabin at the time of the accident.",
    "FIELD20": "",
    "Cause sources": "",
    "FIELD22": ""
  },
  {
    "code": 326,
    "date": "5-Sep-2005",
    "plane_type": "Antonov 26B",
    "location": "near Isiro-Matari...",
    "country": "Congo (Democratic Republic)",
    "registration": "ER-AZT",
    "operator": "Aerocom, opf. Galaxy Incorporation",
    "fat": "11",
    "f": 11,
    "sqrt+10": 13.3,
    "px": 23,
    "PX": 25,
    "cat": "A1",
    "phase": "approach",
    "certainty": "suspected",
    "FIELD16": "",
    "meta": "weather",
    "cause": "low visibility",
    "interesting notes": "In September 2004 the certificate of airworthiness of the plane expired, but continued operations in Congo.",
    "FIELD20": "",
    "Cause sources": "",
    "FIELD22": ""
  },
  {
    "code": 327,
    "date": "5-Sep-2005",
    "plane_type": "Boeing 737-230",
    "location": "Medan-Poloni...",
    "country": "Indonesia",
    "registration": "PK-RIM",
    "operator": "Mandala Airlines",
    "fat": "100+ 49",
    "f": 149,
    "sqrt+10": 22.2,
    "px": 32,
    "PX": 75,
    "cat": "A1",
    "phase": "initial climb",
    "certainty": "probable",
    "FIELD16": "",
    "meta": "human_error",
    "cause": "pilot & crew error",
    "interesting notes": "plane failed to become airborne. overran the runway and caught fire. 49 people on the ground people were killed.",
    "FIELD20": "",
    "Cause sources": "",
    "FIELD22": ""
  },
  {
    "code": 328,
    "date": "9-Sep-2005",
    "plane_type": "Antonov 26B",
    "location": "near Brazzaville",
    "country": "Congo (Democratic Republic)",
    "registration": "9Q-CFD",
    "operator": "Air Kasai",
    "fat": "13",
    "f": 13,
    "sqrt+10": 13.6,
    "px": 24,
    "PX": 25,
    "cat": "A1",
    "phase": "en route",
    "certainty": "unknown",
    "FIELD16": "",
    "meta": "unknown",
    "cause": "unknown",
    "interesting notes": "\"There is some uncertainty with regards to the exact identity of this plane.'",
    "FIELD20": "",
    "Cause sources": "",
    "FIELD22": ""
  },
  {
    "code": 329,
    "date": "22-Oct-2005",
    "plane_type": "Boeing 737-2L9",
    "location": "Lisa Village...",
    "country": "Nigeria",
    "registration": "5N-BFN",
    "operator": "Bellview Airlines",
    "fat": "117",
    "f": 117,
    "sqrt+10": 20.8,
    "px": 31,
    "PX": 65,
    "cat": "A1",
    "phase": "en route",
    "certainty": "suspected",
    "FIELD16": "",
    "meta": "human_error",
    "cause": "pilot error",
    "interesting notes": "The AIB, after an extensive investigation, could not identify conclusive evidence to explain the cause of the accident",
    "FIELD20": "",
    "Cause sources": "",
    "FIELD22": ""
  },
  {
    "code": 330,
    "date": "8-Dec-2005",
    "plane_type": "Boeing 737-7H4",
    "location": "Chicago-Midw...",
    "country": "usa",
    "registration": "N471WN",
    "operator": "Southwest Airlines",
    "fat": "0+ 1",
    "f": 1,
    "sqrt+10": 11,
    "px": 21,
    "PX": 15,
    "cat": "A2",
    "phase": "landing",
    "certainty": "probable",
    "FIELD16": "",
    "meta": "human_error",
    "cause": "pilot error",
    "interesting notes": "The airplane departed the end of the runway, rolled through a blast fence, a perimeter fence, and onto a roadway. A six-year-old boy in one of the vehicles was killed.",
    "FIELD20": "",
    "Cause sources": "",
    "FIELD22": ""
  },
  {
    "code": 331,
    "date": "10-Dec-2005",
    "plane_type": "DC-9-32",
    "location": "Port Harcour...",
    "country": "nigeria",
    "registration": "5N-BFD",
    "operator": "Sosoliso Airlines",
    "fat": "108",
    "f": 108,
    "sqrt+10": 20.4,
    "px": 30,
    "PX": 65,
    "cat": "A1",
    "phase": "landing",
    "certainty": "probable",
    "FIELD16": "",
    "meta": "human_error",
    "cause": "pilot & crew error",
    "interesting notes": "The aircraft disintegrated and caught fire after crashing into an exposed concrete drainage culvert on runway. The cockpit section was found a further 330m from the rest of the wreckage. total wreckage distance was 1120m.",
    "FIELD20": "",
    "Cause sources": "",
    "FIELD22": ""
  },
  {
    "code": 332,
    "date": "19-Dec-2005",
    "plane_type": "Grumman G-73T Turbo Mallard",
    "location": "Miami, FL",
    "country": "usa",
    "registration": "N2969",
    "operator": "Chalk's",
    "fat": "20",
    "f": 20,
    "sqrt+10": 14.5,
    "px": 24,
    "PX": 35,
    "cat": "A1",
    "phase": "initial climb",
    "certainty": "probable",
    "FIELD16": "",
    "meta": "human_error",
    "cause": "maintenance/crew error",
    "interesting notes": "while in flight the the right wing separated from the fuselage",
    "FIELD20": "",
    "Cause sources": "",
    "FIELD22": ""
  },
  {
    "code": 333,
    "date": "23-Dec-2005",
    "plane_type": "Antonov 140-100",
    "location": "near Nardaran",
    "country": "Azerbaijan",
    "registration": "4K-AZ48",
    "operator": "Azerbaijan Airlines",
    "fat": "23",
    "f": 23,
    "sqrt+10": 14.8,
    "px": 25,
    "PX": 35,
    "cat": "A1",
    "phase": "en route",
    "certainty": "suspected",
    "FIELD16": "",
    "meta": "mechanical",
    "cause": "unknown (crew reported system failure shortly before crashing)",
    "interesting notes": "crew had difficulty judging their flight parameters while flying over the Caspian Sea at night.",
    "FIELD20": "",
    "Cause sources": "",
    "FIELD22": ""
  },
  {
    "code": 334,
    "date": "31-Mar-2006",
    "plane_type": "Let L-410UVP-E20",
    "location": "near Rio Bonito, RJ",
    "country": "brazil",
    "registration": "PT-FSE",
    "operator": "TEAM",
    "fat": "19",
    "f": 19,
    "sqrt+10": 14.4,
    "px": 24,
    "PX": 35,
    "cat": "A1",
    "phase": "en route",
    "certainty": "probable",
    "FIELD16": "",
    "meta": "human_error",
    "cause": "pilot & crew error",
    "interesting notes": "",
    "FIELD20": "",
    "Cause sources": "",
    "FIELD22": ""
  },
  {
    "code": 335,
    "date": "16-Apr-2006",
    "plane_type": "Fokker F-27 Friendship 400M",
    "location": "Guayaramerín...",
    "country": "Bolivia",
    "registration": "FAB-91",
    "operator": "TAM",
    "fat": "1",
    "f": 1,
    "sqrt+10": 11,
    "px": 21,
    "PX": 15,
    "cat": "A1",
    "phase": "landing",
    "certainty": "suspected",
    "FIELD16": "",
    "meta": "weather",
    "cause": "bad weather",
    "interesting notes": "An 80-year old woman died of a heart attack a few hours after the accident.",
    "FIELD20": "",
    "Cause sources": "",
    "FIELD22": ""
  },
  {
    "code": 336,
    "date": "24-Apr-2006",
    "plane_type": "Antonov 32B",
    "location": "Lashkar Gah",
    "country": "Afghanistan",
    "registration": "ZS-PDV",
    "operator": "Valan International Cargo Charter, opf. US Department of State",
    "fat": "2+ 3",
    "f": 5,
    "sqrt+10": 12.2,
    "px": 22,
    "PX": 15,
    "cat": "A1",
    "phase": "landing",
    "certainty": "suspected",
    "FIELD16": "",
    "meta": "human_error",
    "cause": "unknown (Reportedly a truck crossed the runway when the airplane landed. The Antonov swerved off the runway, and slid headlong into a nearby nomad settlement.)",
    "interesting notes": "It was carrying a team from the US Bureau of International Narcotics and Law Enforcement Affairs",
    "FIELD20": "",
    "Cause sources": "",
    "FIELD22": ""
  },
  {
    "code": 337,
    "date": "3-May-2006",
    "plane_type": "Airbus A320-211",
    "location": "near Adler/Sochi ...",
    "country": "russia",
    "registration": "EK32009",
    "operator": "Armavia",
    "fat": "113",
    "f": 113,
    "sqrt+10": 20.6,
    "px": 31,
    "PX": 65,
    "cat": "A1",
    "phase": "approach",
    "certainty": "confirmed",
    "FIELD16": "",
    "meta": "human_error",
    "cause": "pilot error",
    "interesting notes": "pilot was in a 'a psychoemotional stress condition'",
    "FIELD20": "",
    "Cause sources": "",
    "FIELD22": ""
  },
  {
    "code": 338,
    "date": "21-Jun-2006",
    "plane_type": "DHC-6 Twin Otter 300",
    "location": "near Jumla Airpor...",
    "country": "Nepal",
    "registration": "9N-AEQ",
    "operator": "Yeti Airlines",
    "fat": "9",
    "f": 9,
    "sqrt+10": 13,
    "px": 23,
    "PX": 25,
    "cat": "A1",
    "phase": "approach",
    "certainty": "probable",
    "FIELD16": "",
    "meta": "human_error",
    "cause": "pilot & crew error",
    "interesting notes": "",
    "FIELD20": "",
    "Cause sources": "",
    "FIELD22": ""
  },
  {
    "code": 339,
    "date": "9-Jul-2006",
    "plane_type": "Airbus A310-324",
    "location": "Irkutsk Airp...",
    "country": "russia",
    "registration": "F-OGYP",
    "operator": "Sibir Airlines",
    "fat": "125",
    "f": 125,
    "sqrt+10": 21.2,
    "px": 31,
    "PX": 65,
    "cat": "A1",
    "phase": "landing",
    "certainty": "confirmed",
    "FIELD16": "",
    "meta": "human_error",
    "cause": "pilot & crew error",
    "interesting notes": "The airplane went off the runway at the high speed of ~180 km/h, hit the concrete fence and buildings, crashed and caught fire.",
    "FIELD20": "",
    "Cause sources": "",
    "FIELD22": ""
  },
  {
    "code": 340,
    "date": "10-Jul-2006",
    "plane_type": "Fokker F-27 Friendship 200",
    "location": "near Multan Airpo...",
    "country": "Pakistan",
    "registration": "AP-BAL",
    "operator": "PIA",
    "fat": "45",
    "f": 45,
    "sqrt+10": 16.7,
    "px": 27,
    "PX": 45,
    "cat": "A1",
    "phase": "initial climb",
    "certainty": "confirmed",
    "FIELD16": "",
    "meta": "human_error",
    "cause": "maintenance/crew error",
    "interesting notes": "",
    "FIELD20": "",
    "Cause sources": "",
    "FIELD22": ""
  },
  {
    "code": 341,
    "date": "3-Aug-2006",
    "plane_type": "Antonov 28",
    "location": "near Bukavu",
    "country": "Congo (Democratic Republic)",
    "registration": "9Q-COM",
    "operator": "TRACEP-Congo Aviation",
    "fat": "17",
    "f": 17,
    "sqrt+10": 14.1,
    "px": 24,
    "PX": 35,
    "cat": "A1",
    "phase": "en route",
    "certainty": "unknown",
    "FIELD16": "",
    "meta": "unknown",
    "cause": "unknown",
    "interesting notes": "in 2006 the Antonov Aviation Scientific Technical Complex informed the State Administration of Ukraine for Aviation Safety Oversight that the plane was not considered to be airworthy.",
    "FIELD20": "",
    "Cause sources": "",
    "FIELD22": ""
  },
  {
    "code": 342,
    "date": "22-Aug-2006",
    "plane_type": "Tupolev 154M",
    "location": "near Donetsk",
    "country": "russia",
    "registration": "RA-85185",
    "operator": "Pulkovo",
    "fat": "170",
    "f": 170,
    "sqrt+10": 23,
    "px": 33,
    "PX": 75,
    "cat": "A1",
    "phase": "en route",
    "certainty": "confirmed",
    "FIELD16": "",
    "meta": "human_error",
    "cause": "pilot error",
    "interesting notes": "",
    "FIELD20": "",
    "Cause sources": "",
    "FIELD22": ""
  },
  {
    "code": 343,
    "date": "27-Aug-2006",
    "plane_type": "Canadair CL-600-2B19 Regional",
    "location": "Lexington-Bl...",
    "country": "usa",
    "registration": "N431CA",
    "operator": "Comair, opf. Delta Connection",
    "fat": "49",
    "f": 49,
    "sqrt+10": 17,
    "px": 27,
    "PX": 45,
    "cat": "A1",
    "phase": "takeoff",
    "certainty": "confirmed",
    "FIELD16": "",
    "meta": "human_error",
    "cause": "pilot & crew error",
    "interesting notes": "",
    "FIELD20": "",
    "Cause sources": "",
    "FIELD22": ""
  },
  {
    "code": 344,
    "date": "1-Sep-2006",
    "plane_type": "Tupolev 154M",
    "location": "Mashad Airpo...",
    "country": "iran",
    "registration": "EP-MCF",
    "operator": "Iran Air Tours",
    "fat": "28",
    "f": 28,
    "sqrt+10": 15.3,
    "px": 25,
    "PX": 35,
    "cat": "A1",
    "phase": "landing",
    "certainty": "unknown",
    "FIELD16": "",
    "meta": "unknown",
    "cause": "unknown (some reports say a nose gear tyre burst)",
    "interesting notes": "",
    "FIELD20": "",
    "Cause sources": "",
    "FIELD22": ""
  },
  {
    "code": 345,
    "date": "29-Sep-2006",
    "plane_type": "Boeing 737-8EH",
    "location": "near Peixoto Azev...",
    "country": "brazil",
    "registration": "PR-GTD",
    "operator": "Gol",
    "fat": "154",
    "f": 154,
    "sqrt+10": 22.4,
    "px": 32,
    "PX": 75,
    "cat": "A1",
    "phase": "en route",
    "certainty": "probable",
    "FIELD16": "",
    "meta": "human_error",
    "cause": "atc error",
    "interesting notes": "air traffic controllers directed two planes flying in opposite directions to fly at the same altitude resulting in a mid air collision",
    "FIELD20": "",
    "Cause sources": "",
    "FIELD22": ""
  },
  {
    "code": 346,
    "date": "10-Oct-2006",
    "plane_type": "British Aerospace BAe-146-200A",
    "location": "Stord-Sørsto...",
    "country": "norway",
    "registration": "OY-CRG",
    "operator": "Atlantic Airways",
    "fat": "4",
    "f": 4,
    "sqrt+10": 12,
    "px": 22,
    "PX": 15,
    "cat": "A1",
    "phase": "landing",
    "certainty": "probable",
    "FIELD16": "",
    "meta": "mechanical",
    "cause": "mechanical failure",
    "interesting notes": "",
    "FIELD20": "",
    "Cause sources": "",
    "FIELD22": ""
  },
  {
    "code": 347,
    "date": "29-Oct-2006",
    "plane_type": "Boeing 737-2B7",
    "location": "Abuja Intern...",
    "country": "nigeria",
    "registration": "5N-BFK",
    "operator": "ADC Airlines",
    "fat": "96",
    "f": 96,
    "sqrt+10": 19.8,
    "px": 30,
    "PX": 65,
    "cat": "A1",
    "phase": "initial climb",
    "certainty": "confirmed",
    "FIELD16": "",
    "meta": "human_error",
    "cause": "pilot error",
    "interesting notes": "",
    "FIELD20": "",
    "Cause sources": "",
    "FIELD22": ""
  },
  {
    "code": 348,
    "date": "9-Nov-2006",
    "plane_type": "Let L-410UVP",
    "location": "near Walikale",
    "country": "Congo (Democratic Republic)",
    "registration": "9Q-CBQ",
    "operator": "Gomair",
    "fat": "0+ 1",
    "f": 1,
    "sqrt+10": 11,
    "px": 21,
    "PX": 15,
    "cat": "A1",
    "phase": "landing",
    "certainty": "suspected",
    "FIELD16": "",
    "meta": "mechanical",
    "cause": "engine loss",
    "interesting notes": "runway strip doubled as a traffic road. traffic resumed on road after plane took off. plane returned because of engine problems, during emergency landing it crashed into vehicles and killed 1 passenger in a vehicle).",
    "FIELD20": "",
    "Cause sources": "",
    "FIELD22": ""
  },
  {
    "code": 349,
    "date": "17-Nov-2006",
    "plane_type": "DHC-6 Twin Otter 300",
    "location": "Puncak Jaya",
    "country": "Indonesia",
    "registration": "PK-YPY",
    "operator": "Trigana Air Service",
    "fat": "12",
    "f": 12,
    "sqrt+10": 13.5,
    "px": 23,
    "PX": 25,
    "cat": "A1",
    "phase": "en route",
    "certainty": "unknown",
    "FIELD16": "",
    "meta": "unknown",
    "cause": "unknown",
    "interesting notes": "aircraft was chartered by the regency administration to take officials to talk with residents in several districts. The wreckage was found the next morning.",
    "FIELD20": "",
    "Cause sources": "",
    "FIELD22": ""
  },
  {
    "code": 350,
    "date": "1-Jan-2007",
    "plane_type": "Boeing 737-4Q8",
    "location": "near Pambauang",
    "country": "Indonesia",
    "registration": "PK-KKW",
    "operator": "AdamAir",
    "fat": "102",
    "f": 102,
    "sqrt+10": 20.1,
    "px": 30,
    "PX": 65,
    "cat": "A1",
    "phase": "en route",
    "certainty": "confirmed",
    "FIELD16": "",
    "meta": "human_error",
    "cause": "pilot error",
    "interesting notes": "the worst ever crash involving a 737-400 (Wikipedia)",
    "FIELD20": "",
    "Cause sources": "",
    "FIELD22": ""
  },
  {
    "code": 351,
    "date": "7-Mar-2007",
    "plane_type": "Boeing 737-497",
    "location": "Yogyakarta-A...",
    "country": "Indonesia",
    "registration": "PK-GZC",
    "operator": "Garuda",
    "fat": "21",
    "f": 21,
    "sqrt+10": 14.6,
    "px": 25,
    "PX": 35,
    "cat": "A1",
    "phase": "landing",
    "certainty": "confirmed",
    "FIELD16": "",
    "meta": "human_error",
    "cause": "pilot error",
    "interesting notes": "",
    "FIELD20": "",
    "Cause sources": "",
    "FIELD22": ""
  },
  {
    "code": 352,
    "date": "17-Mar-2007",
    "plane_type": "Tupolev 134A-3",
    "location": "Samara Airpo...",
    "country": "Russia",
    "registration": "RA-65021",
    "operator": "UTAir",
    "fat": "6",
    "f": 6,
    "sqrt+10": 12.4,
    "px": 22,
    "PX": 25,
    "cat": "A1",
    "phase": "landing",
    "certainty": "confirmed",
    "FIELD16": "",
    "meta": "human_error",
    "cause": "pilot & crew error",
    "interesting notes": "",
    "FIELD20": "",
    "Cause sources": "",
    "FIELD22": ""
  },
  {
    "code": 353,
    "date": "5-May-2007",
    "plane_type": "Boeing 737-8AL",
    "location": "near Douala Airpo...",
    "country": "Cameroon",
    "registration": "5Y-KYA",
    "operator": "Kenya Airways",
    "fat": "114",
    "f": 114,
    "sqrt+10": 20.7,
    "px": 31,
    "PX": 65,
    "cat": "A1",
    "phase": "en route",
    "certainty": "probable",
    "FIELD16": "",
    "meta": "human_error",
    "cause": "pilot error",
    "interesting notes": "",
    "FIELD20": "",
    "Cause sources": "",
    "FIELD22": ""
  },
  {
    "code": 354,
    "date": "21-Jun-2007",
    "plane_type": "Let L-410UVP",
    "location": "near Kamina",
    "country": "DR Congo",
    "registration": "9Q-CEU",
    "operator": "Karibu Airways",
    "fat": "1",
    "f": 1,
    "sqrt+10": 11,
    "px": 21,
    "PX": 15,
    "cat": "A1",
    "phase": "en route",
    "certainty": "unknown",
    "FIELD16": "",
    "meta": "unknown",
    "cause": "unknown",
    "interesting notes": "",
    "FIELD20": "",
    "Cause sources": "",
    "FIELD22": ""
  },
  {
    "code": 355,
    "date": "25-Jun-2007",
    "plane_type": "Antonov 24B",
    "location": "Phnom Damrey",
    "country": "Cambodia",
    "registration": "XU-U4A",
    "operator": "PMT Air",
    "fat": "22",
    "f": 22,
    "sqrt+10": 14.7,
    "px": 25,
    "PX": 35,
    "cat": "A1",
    "phase": "en route",
    "certainty": "unknown",
    "FIELD16": "",
    "meta": "unknown",
    "cause": "unknown",
    "interesting notes": "Bad weather and the location of crash site, deep in the jungle, made the efforts of search and rescue teams difficult.",
    "FIELD20": "",
    "Cause sources": "",
    "FIELD22": ""
  },
  {
    "code": 356,
    "date": "28-Jun-2007",
    "plane_type": "Boeing 737-2M2",
    "location": "M'Banza Cong...",
    "country": "Angola",
    "registration": "D2-TBP",
    "operator": "TAAG",
    "fat": "5+ 1",
    "f": 6,
    "sqrt+10": 12.4,
    "px": 22,
    "PX": 25,
    "cat": "A1",
    "phase": "landing",
    "certainty": "unknown",
    "FIELD16": "",
    "meta": "unknown",
    "cause": "unknown",
    "interesting notes": "The plane collided with two cars and a building, resulting in the deaths of four passengers and one crew member.",
    "FIELD20": "",
    "Cause sources": "",
    "FIELD22": ""
  },
  {
    "code": 357,
    "date": "17-Jul-2007",
    "plane_type": "Airbus A320-233",
    "location": "São Paulo-Co...",
    "country": "Brazil",
    "registration": "PR-MBK",
    "operator": "TAM Brasil",
    "fat": "187+ 12",
    "f": 199,
    "sqrt+10": 24.1,
    "px": 34,
    "PX": 75,
    "cat": "A1",
    "phase": "landing",
    "certainty": "unknown",
    "FIELD16": "",
    "meta": "unknown",
    "cause": "In September 2009, more than two years after the accident, the Aeronautical Accidents Investigation and Prevention Center (CENIPA) announced the results of official investigations. The report shows that one of the thrust levers, which control engines, was in position to accelerate when it should be in idle, but it was not proved if there was mechanical or human failure as the cause of the accident.",
    "interesting notes": "Since the runway is at a higher elevation than the surrounding street and residential area, the A320 probably became airborne, cleared the perimeter fence and a busy highway and collided with a concrete building, bursting into flames.",
    "FIELD20": "",
    "Cause sources": "http://en.wikipedia.org/wiki/TAM_Airlines_Flight_3054",
    "FIELD22": ""
  },
  {
    "code": 358,
    "date": "9-Aug-2007",
    "plane_type": "DHC-6 Twin Otter 300",
    "location": "near Moorea-Temae...",
    "country": "French Polynesia",
    "registration": "F-OIQI",
    "operator": "Air Moorea",
    "fat": "20",
    "f": 20,
    "sqrt+10": 14.5,
    "px": 24,
    "PX": 35,
    "cat": "A1",
    "phase": "initial climb",
    "certainty": "confirmed",
    "FIELD16": "",
    "meta": "mechanical",
    "cause": "mechanical failure",
    "interesting notes": "",
    "FIELD20": "",
    "Cause sources": "",
    "FIELD22": ""
  },
  {
    "code": 359,
    "date": "16-Sep-2007",
    "plane_type": "MD-82",
    "location": "Phuket Inter...",
    "country": "",
    "registration": "HS-OMG",
    "operator": "One-Two-Go Airlines",
    "fat": "90",
    "f": 90,
    "sqrt+10": 19.5,
    "px": 29,
    "PX": 65,
    "cat": "A1",
    "phase": "landing",
    "certainty": "probable",
    "FIELD16": "",
    "meta": "human_error",
    "cause": "pilot & crew error",
    "interesting notes": "",
    "FIELD20": "",
    "Cause sources": "",
    "FIELD22": ""
  },
  {
    "code": 360,
    "date": "8-Oct-2007",
    "plane_type": "Let L-410UVP-E10A",
    "location": "near Cubarral",
    "country": "Colombia",
    "registration": "HK-4055",
    "operator": "Nacional de Aviación",
    "fat": "17",
    "f": 17,
    "sqrt+10": 14.1,
    "px": 24,
    "PX": 35,
    "cat": "A1",
    "phase": "en route",
    "certainty": "confirmed",
    "FIELD16": "",
    "meta": "human_error",
    "cause": "pilot error",
    "interesting notes": "",
    "FIELD20": "",
    "Cause sources": "",
    "FIELD22": ""
  },
  {
    "code": 361,
    "date": "30-Nov-2007",
    "plane_type": "MD-83",
    "location": "near Isparta Airp...",
    "country": "Turkey",
    "registration": "TC-AKM",
    "operator": "World Focus Airlines, opf. Atlasjet",
    "fat": "57",
    "f": 57,
    "sqrt+10": 17.5,
    "px": 28,
    "PX": 55,
    "cat": "A1",
    "phase": "approach",
    "certainty": "probable",
    "FIELD16": "",
    "meta": "human_error",
    "cause": "pilot error",
    "interesting notes": "Upon investigation of the black box of the aircraft in Lufthansa laboratories, the cockpit voice recorder device was found to have been inoperative for the 9 days leading up to the accident,[8] in contravention of regulations which specify that it must be made operable within 72 hours of the fault being discovered.[9] In addition, the flight data recorder was not working properly and only contained 15 minutes of flight data.",
    "FIELD20": "",
    "Cause sources": "http://en.wikipedia.org/wiki/Atlasjet_Flight_4203",
    "FIELD22": ""
  },
  {
    "code": 362,
    "date": "4-Jan-2008",
    "plane_type": "Let L-410UVP-E3",
    "location": "near Los Roques A...",
    "country": "Venezuela",
    "registration": "YV2081",
    "operator": "Transaven",
    "fat": "14",
    "f": 14,
    "sqrt+10": 13.7,
    "px": 24,
    "PX": 25,
    "cat": "A1",
    "phase": "en route",
    "certainty": "confirmed",
    "FIELD16": "",
    "meta": "mechanical",
    "cause": "mechanical failure",
    "interesting notes": "The wreckage was not located until a new search operation was started in 2013 using the Sea Scout, a 134-foot survey vessel.",
    "FIELD20": "",
    "Cause sources": "",
    "FIELD22": ""
  },
  {
    "code": 363,
    "date": "19-Jan-2008",
    "plane_type": "Beechcraft B200 Super King Air",
    "location": "near Bailundo",
    "country": "",
    "registration": "D2-FFK",
    "operator": "Gira Globo",
    "fat": "13",
    "f": 13,
    "sqrt+10": 13.6,
    "px": 24,
    "PX": 25,
    "cat": "A1",
    "phase": "approach",
    "certainty": "probable",
    "FIELD16": "",
    "meta": "human_error",
    "cause": "pilot error",
    "interesting notes": "",
    "FIELD20": "",
    "Cause sources": "",
    "FIELD22": ""
  },
  {
    "code": 364,
    "date": "21-Feb-2008",
    "plane_type": "ATR-42-300",
    "location": "near Mérida-A Car...",
    "country": "Venezuela",
    "registration": "YV1449",
    "operator": "Santa Bárbara Airlines",
    "fat": "46",
    "f": 46,
    "sqrt+10": 16.8,
    "px": 27,
    "PX": 45,
    "cat": "A1",
    "phase": "en route",
    "certainty": "confirmed",
    "FIELD16": "",
    "meta": "human_error",
    "cause": "pilot & crew error",
    "interesting notes": "Worst ever accident involving the ATR 42. (Wikipedia)",
    "FIELD20": "",
    "Cause sources": "http://en.wikipedia.org/wiki/Santa_Bárbara_Airlines_Flight_518",
    "FIELD22": ""
  },
  {
    "code": 365,
    "date": "3-Apr-2008",
    "plane_type": "Antonov 28",
    "location": "Lawa-Antino ...",
    "country": "Suriname",
    "registration": "PZ-TSO",
    "operator": "Blue Wing Airlines",
    "fat": "19",
    "f": 19,
    "sqrt+10": 14.4,
    "px": 24,
    "PX": 35,
    "cat": "A1",
    "phase": "en route",
    "certainty": "unknown",
    "FIELD16": "",
    "meta": "unknown",
    "cause": "unknown (The airplane attempted a go-around, but failed to gain height and crashed into a mountain.)",
    "interesting notes": "The pilot, Soeriani Jhauw-Verkuijl, was the wife of Blue Wing Airlines president Amichand Jhauw.",
    "FIELD20": "",
    "Cause sources": "http://en.wikipedia.org/wiki/2008_Suriname_plane_crash",
    "FIELD22": ""
  },
  {
    "code": 366,
    "date": "15-Apr-2008",
    "plane_type": "DC-9-51",
    "location": "Goma Airport...",
    "country": "Congo (Democratic Republic)",
    "registration": "9Q-CHN",
    "operator": "Hewa Bora Airways",
    "fat": "3+ 37",
    "f": 40,
    "sqrt+10": 16.3,
    "px": 26,
    "PX": 45,
    "cat": "A1",
    "phase": "takeoff",
    "certainty": "unknown",
    "FIELD16": "",
    "meta": "unknown",
    "cause": "Unknown (Failed to lift off the runway 18 on takeoff, reportedly after passing through a puddle, what caused loss of thrust in one of the engines. The airplane continued into a residential area, crashed 100m from runway 36 threshold and caught fire.)",
    "interesting notes": "In their 2011 report to Congress, the NTSB classified this accident as a major ongoing investigation in which they were assisting the Democratic Republic of the Congo.",
    "FIELD20": "",
    "Cause sources": "http://en.wikipedia.org/wiki/Hewa_Bora_Airways_Flight_122",
    "FIELD22": ""
  },
  {
    "code": 367,
    "date": "16-Apr-2008",
    "plane_type": "Antonov 32",
    "location": "near Annobón Island",
    "country": "Equatorial Guinea",
    "registration": "3C-5GE",
    "operator": "Equatorial Guinea National Guard",
    "fat": "13",
    "f": 13,
    "sqrt+10": 13.6,
    "px": 24,
    "PX": 25,
    "cat": "A1",
    "phase": "landing",
    "certainty": "unknown",
    "FIELD16": "",
    "meta": "unknown",
    "cause": "unknown (Crashed into the sea after skidding off the runway in heavy wind and rain.)",
    "interesting notes": "",
    "FIELD20": "",
    "Cause sources": "",
    "FIELD22": ""
  },
  {
    "code": 368,
    "date": "2-May-2008",
    "plane_type": "Beechcraft 1900C",
    "location": "near Rumbek",
    "country": "South Sudan",
    "registration": "5Y-FLX",
    "operator": "Flex Air, opf. Southern Sudan Air Connection",
    "fat": "21",
    "f": 21,
    "sqrt+10": 14.6,
    "px": 25,
    "PX": 35,
    "cat": "A1",
    "phase": "en route",
    "certainty": "probable",
    "FIELD16": "",
    "meta": "mechanical",
    "cause": "engline loss",
    "interesting notes": "The airplane was carrying a delegation of leaders from the (former rebel) Sudan People's Liberation Movement (SPLM). One of the passengers was Dominic Dim, southern Sudan's minister of defence.",
    "FIELD20": "",
    "Cause sources": "",
    "FIELD22": ""
  },
  {
    "code": 369,
    "date": "30-May-2008",
    "plane_type": "Airbus A320-233",
    "location": "Tegucigalpa-...",
    "country": "Honduras",
    "registration": "EI-TAF",
    "operator": "TACA",
    "fat": "3+ 2",
    "f": 5,
    "sqrt+10": 12.2,
    "px": 22,
    "PX": 15,
    "cat": "A1",
    "phase": "landing",
    "certainty": "confirmed",
    "FIELD16": "",
    "meta": "human_error",
    "cause": "pilot error",
    "interesting notes": "",
    "FIELD20": "",
    "Cause sources": "",
    "FIELD22": ""
  },
  {
    "code": 370,
    "date": "10-Jun-2008",
    "plane_type": "Airbus A310-324",
    "location": "Khartoum-Civ...",
    "country": "Sudan",
    "registration": "ST-ATN",
    "operator": "Sudan Airways",
    "fat": "30",
    "f": 30,
    "sqrt+10": 15.5,
    "px": 25,
    "PX": 35,
    "cat": "A1",
    "phase": "landing",
    "certainty": "confirmed",
    "FIELD16": "",
    "meta": "human_error",
    "cause": "pilot & crew error",
    "interesting notes": "",
    "FIELD20": "",
    "Cause sources": "",
    "FIELD22": ""
  },
  {
    "code": 371,
    "date": "10-Jul-2008",
    "plane_type": "Beechcraft 99A",
    "location": "near Puerto Montt",
    "country": "Chile",
    "registration": "CC-CFM",
    "operator": "Aerocord",
    "fat": "9",
    "f": 9,
    "sqrt+10": 13,
    "px": 23,
    "PX": 25,
    "cat": "A1",
    "phase": "approach",
    "certainty": "probable",
    "FIELD16": "",
    "meta": "human_error",
    "cause": "pilot error",
    "interesting notes": "",
    "FIELD20": "",
    "Cause sources": "",
    "FIELD22": ""
  },
  {
    "code": 372,
    "date": "20-Aug-2008",
    "plane_type": "MD-82",
    "location": "Madrid-Baraj...",
    "country": "Spain",
    "registration": "EC-HFP",
    "operator": "Spanair",
    "fat": "154",
    "f": 154,
    "sqrt+10": 22.4,
    "px": 32,
    "PX": 75,
    "cat": "A1",
    "phase": "takeoff",
    "certainty": "confirmed",
    "FIELD16": "",
    "meta": "human_error",
    "cause": "pilot & crew error",
    "interesting notes": "",
    "FIELD20": "",
    "Cause sources": "",
    "FIELD22": ""
  },
  {
    "code": 373,
    "date": "24-Aug-2008",
    "plane_type": "Boeing 737-219",
    "location": "near Bishkek-Mana...",
    "country": "Kyrgyzstan",
    "registration": "EX-009",
    "operator": "Itek Air",
    "fat": "65",
    "f": 65,
    "sqrt+10": 18.1,
    "px": 28,
    "PX": 55,
    "cat": "A1",
    "phase": "approach",
    "certainty": "confirmed",
    "FIELD16": "",
    "meta": "human_error",
    "cause": "pilot & crew error",
    "interesting notes": "",
    "FIELD20": "",
    "Cause sources": "",
    "FIELD22": ""
  },
  {
    "code": 374,
    "date": "14-Sep-2008",
    "plane_type": "Boeing 737-505",
    "location": "near Perm Airport...",
    "country": "Russia",
    "registration": "VP-BKO",
    "operator": "Aeroflot-Nord",
    "fat": "88",
    "f": 88,
    "sqrt+10": 19.4,
    "px": 29,
    "PX": 65,
    "cat": "A1",
    "phase": "approach",
    "certainty": "probable",
    "FIELD16": "",
    "meta": "human_error",
    "cause": "pilot error",
    "interesting notes": "worst ever accident involving the Boeing 737–500 (Wikipedia)",
    "FIELD20": "",
    "Cause sources": "",
    "FIELD22": ""
  },
  {
    "code": 375,
    "date": "8-Oct-2008",
    "plane_type": "DHC-6 Twin Otter 300",
    "location": "Lukla-Tenzin...",
    "country": "Nepal",
    "registration": "9N-AFE",
    "operator": "Yeti Airlines",
    "fat": "18",
    "f": 18,
    "sqrt+10": 14.2,
    "px": 24,
    "PX": 35,
    "cat": "A1",
    "phase": "landing",
    "certainty": "probable",
    "FIELD16": "",
    "meta": "human_error",
    "cause": "pilot & crew error",
    "interesting notes": "",
    "FIELD20": "",
    "Cause sources": "",
    "FIELD22": ""
  },
  {
    "code": 376,
    "date": "7-Feb-2009",
    "plane_type": "Embraer 110P1 Bandeirante",
    "location": "near Santo Antóni...",
    "country": "Brazil",
    "registration": "PT-SEA",
    "operator": "Manaus Aerotáxi",
    "fat": "24",
    "f": 24,
    "sqrt+10": 14.9,
    "px": 25,
    "PX": 35,
    "cat": "A1",
    "phase": "en route",
    "certainty": "confirmed",
    "FIELD16": "",
    "meta": "mechanical",
    "cause": "mechanical failure",
    "interesting notes": "Plane has maximum passenger seats of 19, but 26 were travelling including 8 children. Total plane weight was 6414kg, compared to the max weight allowed of 5670kg",
    "FIELD20": "",
    "Cause sources": "",
    "FIELD22": ""
  },
  {
    "code": 377,
    "date": "12-Feb-2009",
    "plane_type": "DHC-8-402 Q400",
    "location": "near Buffalo Niag...",
    "country": "USA",
    "registration": "N200WQ",
    "operator": "Colgan Air",
    "fat": "49+ 1",
    "f": 50,
    "sqrt+10": 17.1,
    "px": 27,
    "PX": 45,
    "cat": "A1",
    "phase": "approach",
    "certainty": "probable",
    "FIELD16": "",
    "meta": "human_error",
    "cause": "pilot error",
    "interesting notes": "Noted that the flight crew didn't adhere to cockpit procedures, and Colgan Air’s inadequate procedures for airspeed selection and management during approaches in icing conditions",
    "FIELD20": "",
    "Cause sources": "",
    "FIELD22": ""
  },
  {
    "code": 378,
    "date": "25-Feb-2009",
    "plane_type": "Boeing 737-8F2",
    "location": "near Amsterdam-Sc...",
    "country": "Netherlands",
    "registration": "TC-JGE",
    "operator": "THY",
    "fat": "9",
    "f": 9,
    "sqrt+10": 13,
    "px": 23,
    "PX": 25,
    "cat": "A1",
    "phase": "approach",
    "certainty": "Certain",
    "FIELD16": "",
    "meta": "mechanical",
    "cause": "mechanical failure",
    "interesting notes": "Plane was being flown by a pilot in training",
    "FIELD20": "",
    "Cause sources": "",
    "FIELD22": ""
  },
  {
    "code": 379,
    "date": "1-Jun-2009",
    "plane_type": "Airbus A330-203",
    "location": "near São Pedro an...",
    "country": "Atlantic Ocean (nr. Brazil)",
    "registration": "F-GZCP",
    "operator": "Air France",
    "fat": "228",
    "f": 228,
    "sqrt+10": 25.1,
    "px": 35,
    "PX": 85,
    "cat": "A1",
    "phase": "en route",
    "certainty": "Certain",
    "FIELD16": "",
    "meta": "human_error",
    "cause": "maintenance/crew error",
    "interesting notes": "The obstruction of the Pitot probes by ice crystals during cruise was a phenomenon that was known but misunderstood by the aviation community at the time of the accident",
    "FIELD20": "",
    "Cause sources": "",
    "FIELD22": ""
  },
  {
    "code": 380,
    "date": "30-Jun-2009",
    "plane_type": "Airbus A310-324",
    "location": "near Mitsamiouli",
    "country": "Comoros",
    "registration": "7O-ADJ",
    "operator": "Yemenia Airways",
    "fat": "152",
    "f": 152,
    "sqrt+10": 22.3,
    "px": 32,
    "PX": 75,
    "cat": "A1",
    "phase": "approach",
    "certainty": "Probable",
    "FIELD16": "",
    "meta": "human_error",
    "cause": "pilot & crew error",
    "interesting notes": "One surviver – a young girl",
    "FIELD20": "",
    "Cause sources": "",
    "FIELD22": ""
  },
  {
    "code": 381,
    "date": "15-Jul-2009",
    "plane_type": "Tupolev 154M",
    "location": "near Qazvin",
    "country": "Iran",
    "registration": "EP-CPG",
    "operator": "Caspian Airlines",
    "fat": "168",
    "f": 168,
    "sqrt+10": 23,
    "px": 33,
    "PX": 75,
    "cat": "A1",
    "phase": "en route",
    "certainty": "unknown",
    "FIELD16": "",
    "meta": "unknown",
    "cause": "Unknown (plane suddendly turned 270 degrees and entered rapid descent – plane could not be contacted by air traffic control)",
    "interesting notes": "Plane entered a rapid descent to 14000 feet in just 90 seconds (which would be an average of 222 feet/sec, 67m/sec). Plane crash created a 10m deep crater",
    "FIELD20": "",
    "Cause sources": "",
    "FIELD22": ""
  },
  {
    "code": 382,
    "date": "24-Jul-2009",
    "plane_type": "Ilyushin 62M",
    "location": "Mashhad-Shah...",
    "country": "Iran",
    "registration": "UP-I6208",
    "operator": "Aria Air",
    "fat": "16",
    "f": 16,
    "sqrt+10": 14,
    "px": 24,
    "PX": 35,
    "cat": "A1",
    "phase": "landing",
    "certainty": "Suspected",
    "FIELD16": "",
    "meta": "human_error",
    "cause": "pilot error",
    "interesting notes": "Plane collided with a wall located more than 1100 meters from runway",
    "FIELD20": "",
    "Cause sources": "",
    "FIELD22": ""
  },
  {
    "code": 383,
    "date": "2-Aug-2009",
    "plane_type": "DHC-6 Twin Otter 300",
    "location": "near Oksibil Airp",
    "country": "Indonesia",
    "registration": "PK-NVC",
    "operator": "EU blacklist Merpati Nusantara",
    "fat": "15",
    "f": 15,
    "sqrt+10": 13.9,
    "px": 24,
    "PX": 25,
    "cat": "A1",
    "phase": "en route",
    "certainty": "suspected",
    "FIELD16": "",
    "meta": "human_error",
    "cause": "pilot & crew error",
    "interesting notes": "",
    "FIELD20": "",
    "Cause sources": "",
    "FIELD22": ""
  },
  {
    "code": 384,
    "date": "4-Aug-2009",
    "plane_type": "ATR-72-212",
    "location": "Koh Samui Ai...",
    "country": "Thailand",
    "registration": "HS-PGL",
    "operator": "Bangkok Airways",
    "fat": "1",
    "f": 1,
    "sqrt+10": 11,
    "px": 21,
    "PX": 15,
    "cat": "A1",
    "phase": "landing",
    "certainty": "unknown",
    "FIELD16": "",
    "meta": "unknown",
    "cause": "unknown (plane skidded off runway at landing)",
    "interesting notes": "Plane collided with air control tower",
    "FIELD20": "",
    "Cause sources": "",
    "FIELD22": ""
  },
  {
    "code": 385,
    "date": "12-Nov-2009",
    "plane_type": "Canadair CL-600-2B19 Regional",
    "location": "Kigali Airpo...",
    "country": "Rwanda",
    "registration": "5Y-JLD",
    "operator": "JetLink Express, opf. Rwandair Express",
    "fat": "1",
    "f": 1,
    "sqrt+10": 11,
    "px": 21,
    "PX": 15,
    "cat": "A1",
    "phase": "taxi",
    "certainty": "Certain",
    "FIELD16": "",
    "meta": "mechanical",
    "cause": "mechanical failure",
    "interesting notes": "Hit the VIP wing of the building. The nose of the plane was buried inside the building up to and including the left hand forward passenger door.",
    "FIELD20": "",
    "Cause sources": "",
    "FIELD22": ""
  },
  {
    "code": 386,
    "date": "25-Jan-2010",
    "plane_type": "Boeing 737-8AS (WL)",
    "location": "near Beirut Inter...",
    "country": "Lebanon",
    "registration": "ET-ANB",
    "operator": "Ethiopian Airlines",
    "fat": "90",
    "f": 90,
    "sqrt+10": 19.5,
    "px": 29,
    "PX": 65,
    "cat": "A1",
    "phase": "initial climb",
    "certainty": "certain",
    "FIELD16": "",
    "meta": "human_error",
    "cause": "pilot & crew error",
    "interesting notes": "The flight crew failure to abide by CRM principles of mutual support",
    "FIELD20": "",
    "Cause sources": "",
    "FIELD22": ""
  },
  {
    "code": 387,
    "date": "12-May-2010",
    "plane_type": "Airbus A330-202",
    "location": "near Tripoli Inte...",
    "country": "libya",
    "registration": "5A-ONG",
    "operator": "Afriqiyah Airways",
    "fat": "103",
    "f": 103,
    "sqrt+10": 20.1,
    "px": 30,
    "PX": 65,
    "cat": "A1",
    "phase": "approach",
    "certainty": "certain",
    "FIELD16": "",
    "meta": "weather",
    "cause": "low visibility",
    "interesting notes": "one passenger survived",
    "FIELD20": "",
    "Cause sources": "",
    "FIELD22": ""
  },
  {
    "code": 388,
    "date": "17-May-2010",
    "plane_type": "Antonov 24B",
    "location": "Salang Pass",
    "country": "Afghanistan",
    "registration": "YA-PIS",
    "operator": "Pamir Airways",
    "fat": "44",
    "f": 44,
    "sqrt+10": 16.6,
    "px": 27,
    "PX": 45,
    "cat": "A1",
    "phase": "en route",
    "certainty": "suspected",
    "FIELD16": "",
    "meta": "weather",
    "cause": "low visibility",
    "interesting notes": "wreckage was found 2 days later scattered over the mountainside",
    "FIELD20": "",
    "Cause sources": "",
    "FIELD22": ""
  },
  {
    "code": 389,
    "date": "22-May-2010",
    "plane_type": "Boeing 737-8HG (WL)",
    "location": "Mangalore-Ba...",
    "country": "India",
    "registration": "VT-AXV",
    "operator": "Air India Express",
    "fat": "158",
    "f": 158,
    "sqrt+10": 22.6,
    "px": 33,
    "PX": 75,
    "cat": "A1",
    "phase": "landing",
    "certainty": "certain",
    "FIELD16": "",
    "meta": "human_error",
    "cause": "pilot error",
    "interesting notes": "Airport has a table top runway – aerodrome located on hilltop. Valleys 200ft to 250ft immediately beyond paved surface of runway",
    "FIELD20": "",
    "Cause sources": "",
    "FIELD22": ""
  },
  {
    "code": 390,
    "date": "28-Jul-2010",
    "plane_type": "Airbus A321-231",
    "location": "near Islamabad-Be...",
    "country": "Pakistan",
    "registration": "AP-BJB",
    "operator": "Airblue",
    "fat": "152",
    "f": 152,
    "sqrt+10": 22.3,
    "px": 32,
    "PX": 75,
    "cat": "A1",
    "phase": "approach",
    "certainty": "certain",
    "FIELD16": "",
    "meta": "human_error",
    "cause": "pilot error",
    "interesting notes": "The captain decided to fly a non-standard self-created PBD (Place Bearing Distance, RNAV waypoint)-based approach. He had been bullying the first officer throughout the flight so the first officer did not stand up to the captain's decisions",
    "FIELD20": "",
    "Cause sources": "",
    "FIELD22": ""
  },
  {
    "code": 391,
    "date": "16-Aug-2010",
    "plane_type": "Boeing 737-73V (WL)",
    "location": "San Andres I...",
    "country": "Columbia",
    "registration": "HK-4682",
    "operator": "AIRES Colombia",
    "fat": "2",
    "f": 2,
    "sqrt+10": 11.4,
    "px": 21,
    "PX": 15,
    "cat": "A1",
    "phase": "landing",
    "certainty": "certain",
    "FIELD16": "",
    "meta": "human_error",
    "cause": "pilot error",
    "interesting notes": "Impact of hit made plane break into 3 parts",
    "FIELD20": "",
    "Cause sources": "",
    "FIELD22": ""
  },
  {
    "code": 392,
    "date": "24-Aug-2010",
    "plane_type": "Embraer ERJ 190-100 LR (ERJ-1",
    "location": "near Yichun Lindu...",
    "country": "China",
    "registration": "B-3130",
    "operator": "Henan Airlines",
    "fat": "44",
    "f": 44,
    "sqrt+10": 16.6,
    "px": 27,
    "PX": 45,
    "cat": "A1",
    "phase": "landing",
    "certainty": "certain",
    "FIELD16": "",
    "meta": "human_error",
    "cause": "pilot error",
    "interesting notes": "The reported visibility of 2800 m was below the minimum of 3600 m. A contributing factor to the accident was Henan Airlines' weak safety management. The parent company, Shenzen Airlines failed to supervise the safety management at Henan Airlines.",
    "FIELD20": "",
    "Cause sources": "",
    "FIELD22": ""
  },
  {
    "code": 393,
    "date": "13-Sep-2010",
    "plane_type": "ATR-42-320",
    "location": "near Puerto Ordaz...",
    "country": "Venuzuela",
    "registration": "YV1010",
    "operator": "Conviasa",
    "fat": "17",
    "f": 17,
    "sqrt+10": 14.1,
    "px": 24,
    "PX": 35,
    "cat": "A1",
    "phase": "approach",
    "certainty": "unknown",
    "FIELD16": "",
    "meta": "unknown",
    "cause": "unknown (plane came down 8km short of runway)",
    "interesting notes": "unusual that there was no further information noted about the crash other than it missed the runway",
    "FIELD20": "",
    "Cause sources": "",
    "FIELD22": ""
  },
  {
    "code": 394,
    "date": "4-Nov-2010",
    "plane_type": "ATR-72-212",
    "location": "near Guasimal, Sa...",
    "country": "Cuba",
    "registration": "CU-T1549",
    "operator": "Aerocaribbean",
    "fat": "68",
    "f": 68,
    "sqrt+10": 18.2,
    "px": 28,
    "PX": 55,
    "cat": "A1",
    "phase": "en route",
    "certainty": "certain",
    "FIELD16": "",
    "meta": "human_error",
    "cause": "pilot & crew error",
    "interesting notes": "no survivors",
    "FIELD20": "",
    "Cause sources": "",
    "FIELD22": ""
  },
  {
    "code": 395,
    "date": "4-Dec-2010",
    "plane_type": "Tupolev 154M",
    "location": "Moskva-Domod...",
    "country": "Russia",
    "registration": "RA-85744",
    "operator": "Aeroflot, North Kavkaz Civil Aviation Directorate, opf. Dagestan",
    "fat": "2",
    "f": 2,
    "sqrt+10": 11.4,
    "px": 21,
    "PX": 15,
    "cat": "A1",
    "phase": "landing",
    "certainty": "certain",
    "FIELD16": "",
    "meta": "mechanical",
    "cause": "mechanical failure",
    "interesting notes": "This was the same aircraft (RA-85744) that was used to transport the Belgium national football team from Brussels to Voronezh on November 16, 2010 for their match against Russia.",
    "FIELD20": "",
    "Cause sources": "",
    "FIELD22": ""
  },
  {
    "code": 396,
    "date": "1-Jan-2011",
    "plane_type": "Tupolev 154B-2",
    "location": "Surgut Airpo...",
    "country": "Russia",
    "registration": "RA-85588",
    "operator": "Kolavia",
    "fat": "3",
    "f": 3,
    "sqrt+10": 11.7,
    "px": 22,
    "PX": 15,
    "cat": "A1",
    "phase": "grounded",
    "certainty": "probable",
    "FIELD16": "",
    "meta": "mechanical",
    "cause": "electric fire",
    "interesting notes": "",
    "FIELD20": "",
    "Cause sources": "",
    "FIELD22": ""
  },
  {
    "code": 397,
    "date": "9-Jan-2011",
    "plane_type": "Boeing 727-286",
    "location": "near Urmia (Orumi...",
    "country": "Iran",
    "registration": "EP-IRP",
    "operator": "Iran Air",
    "fat": "77",
    "f": 77,
    "sqrt+10": 18.8,
    "px": 29,
    "PX": 55,
    "cat": "A1",
    "phase": "approach",
    "certainty": "suggested",
    "FIELD16": "",
    "meta": "weather",
    "cause": "low visibility",
    "interesting notes": "",
    "FIELD20": "",
    "Cause sources": "http://en.wikipedia.org/wiki/Iran_Air_Flight_277",
    "FIELD22": ""
  },
  {
    "code": 398,
    "date": "10-Feb-2011",
    "plane_type": "Swearingen SA227-BC Metro III",
    "location": "Cork Airport...",
    "country": "Ireland",
    "registration": "EC-ITP",
    "operator": "Flightline, opf. Manx2",
    "fat": "6",
    "f": 6,
    "sqrt+10": 12.4,
    "px": 22,
    "PX": 25,
    "cat": "A1",
    "phase": "landing",
    "certainty": "suspected",
    "FIELD16": "",
    "meta": "weather",
    "cause": "low visibility",
    "interesting notes": "crashed on its third attempt to land. Pilot had recently been promoted from co-pilot.",
    "FIELD20": "",
    "Cause sources": "http://en.wikipedia.org/wiki/Manx2_Flight_7100",
    "FIELD22": ""
  },
  {
    "code": 399,
    "date": "14-Feb-2011",
    "plane_type": "Let L-410UVP-E20",
    "location": "near Cerro de Hula",
    "country": "Honduras",
    "registration": "HR-AUQ",
    "operator": "Central American Airways",
    "fat": "14",
    "f": 14,
    "sqrt+10": 13.7,
    "px": 24,
    "PX": 25,
    "cat": "A1",
    "phase": "en route",
    "certainty": "unknown",
    "FIELD16": "",
    "meta": "unknown",
    "cause": "collision (into forest)",
    "interesting notes": "Among the dead were Rodolfo Rovelo, Honduran Assistant Secretary for Public Works, and Jose Israel Salinas, leader of the United Workers Federation of Honduras, Plutarco Molina Castellanos, CEO of Constructora William - Molina, and Former finance minister Carlos Chain. In response to the incident, the government of Honduras declared three days of national mourning for the deceased government officials.",
    "FIELD20": "",
    "Cause sources": "http://en.wikipedia.org/wiki/Central_American_Airways_Flight_731",
    "FIELD22": ""
  },
  {
    "code": 400,
    "date": "4-Apr-2011",
    "plane_type": "Canadair CL-600-2B19 Regional",
    "location": "Kinshasa-N'D...",
    "country": "Congo (Democratic Republic)",
    "registration": "4L-GAE",
    "operator": "Georgian Airways, opf. UN",
    "fat": "32",
    "f": 32,
    "sqrt+10": 15.7,
    "px": 26,
    "PX": 45,
    "cat": "A1",
    "phase": "landing",
    "certainty": "suggested",
    "FIELD16": "",
    "meta": "weather",
    "cause": "low visibility",
    "interesting notes": "The airplane operated on behalf of the United Nations Organization Stabilization Mission in the Democratic Republic of the Congo. Only 1 survivor.",
    "FIELD20": "",
    "Cause sources": "",
    "FIELD22": ""
  },
  {
    "code": 401,
    "date": "7-May-2011",
    "plane_type": "Xian MA60",
    "location": "near Kaimana-Utar...",
    "country": "Indonesia",
    "registration": "PK-MZK",
    "operator": "Merpati Nusantara",
    "fat": "25",
    "f": 25,
    "sqrt+10": 15,
    "px": 25,
    "PX": 35,
    "cat": "A1",
    "phase": "approach",
    "certainty": "confirmed",
    "FIELD16": "",
    "meta": "human_error",
    "cause": "pilot error",
    "interesting notes": "",
    "FIELD20": "",
    "Cause sources": "http://en.wikipedia.org/wiki/Merpati_Nusantara_Airlines_Flight_8968",
    "FIELD22": ""
  },
  {
    "code": 402,
    "date": "18-May-2011",
    "plane_type": "Saab 340A",
    "location": "near Prahuaniyeu, RN",
    "country": "Argentina",
    "registration": "LV-CEJ",
    "operator": "SOL Líneas Aéreas",
    "fat": "22",
    "f": 22,
    "sqrt+10": 14.7,
    "px": 25,
    "PX": 35,
    "cat": "A1",
    "phase": "en route",
    "certainty": "probable",
    "FIELD16": "",
    "meta": "mechanical",
    "cause": "mechanical failure",
    "interesting notes": "",
    "FIELD20": "",
    "Cause sources": "",
    "FIELD22": ""
  },
  {
    "code": 403,
    "date": "20-Jun-2011",
    "plane_type": "Tupolev 134A-3",
    "location": "near Petrozavodsk...",
    "country": "russia",
    "registration": "RA-65691",
    "operator": "RusAir, opf. RusLine",
    "fat": "47",
    "f": 47,
    "sqrt+10": 16.9,
    "px": 27,
    "PX": 45,
    "cat": "A1",
    "phase": "approach",
    "certainty": "confirmed",
    "FIELD16": "",
    "meta": "human_error",
    "cause": "pilot & crew error",
    "interesting notes": "As a result of the crash, all Tu-134s were to be withdrawn from commercial service in Russia.",
    "FIELD20": "",
    "Cause sources": "",
    "FIELD22": ""
  },
  {
    "code": 404,
    "date": "8-Jul-2011",
    "plane_type": "Boeing 727-022 (WL)",
    "location": "Kisangani-Ba...",
    "country": "Congo (Democratic Republic)",
    "registration": "9Q-COP",
    "operator": "Hewa Bora Airways",
    "fat": "77",
    "f": 77,
    "sqrt+10": 18.8,
    "px": 29,
    "PX": 55,
    "cat": "A1",
    "phase": "landing",
    "certainty": "suggested",
    "FIELD16": "",
    "meta": "weather",
    "cause": "bad weather",
    "interesting notes": "investigation found that the captain of the aircraft and the tower controllers were not licenced.",
    "FIELD20": "",
    "Cause sources": "",
    "FIELD22": ""
  },
  {
    "code": 405,
    "date": "11-Jul-2011",
    "plane_type": "Antonov 24RV",
    "location": "near Strezhevoy",
    "country": "russia",
    "registration": "RA-47302",
    "operator": "Angara Airlines",
    "fat": "7",
    "f": 7,
    "sqrt+10": 12.6,
    "px": 23,
    "PX": 25,
    "cat": "A1",
    "phase": "en route",
    "certainty": "confirmed",
    "FIELD16": "",
    "meta": "human_error",
    "cause": "maintenance/crew error",
    "interesting notes": "A criminal investigation was opened and two officials of Angara Airlines were charged.",
    "FIELD20": "",
    "Cause sources": "http://en.wikipedia.org/wiki/Angara_Airlines_Flight_5007",
    "FIELD22": ""
  },
  {
    "code": 406,
    "date": "13-Jul-2011",
    "plane_type": "Let L-410UVP-E20",
    "location": "near Recife-Guara...",
    "country": "brazil",
    "registration": "PR-NOB",
    "operator": "NOAR Linhas Aéreas",
    "fat": "16",
    "f": 16,
    "sqrt+10": 14,
    "px": 24,
    "PX": 35,
    "cat": "A1",
    "phase": "initial climb",
    "certainty": "suggested",
    "FIELD16": "",
    "meta": "mechanical",
    "cause": "engine loss",
    "interesting notes": "",
    "FIELD20": "",
    "Cause sources": "http://en.wikipedia.org/wiki/Noar_Linhas_A%C3%A9reas_Flight_4896",
    "FIELD22": ""
  },
  {
    "code": 407,
    "date": "20-Aug-2011",
    "plane_type": "Boeing 737-210C",
    "location": "near Resolute Air...",
    "country": "canada",
    "registration": "C-GNWN",
    "operator": "First Air",
    "fat": "12",
    "f": 12,
    "sqrt+10": 13.5,
    "px": 23,
    "PX": 25,
    "cat": "A1",
    "phase": "approach",
    "certainty": "suggested (investigation is ongoing)",
    "FIELD16": "",
    "meta": "human_error",
    "cause": "pilot & crew error",
    "interesting notes": "The Canadian Forces were conducting Operation Nanook 2011 nearby, which was about to simulate an air disaster in the Resolute Bay area and Royal Canadian Air Force firefighters were the first to respond and reach the crash site",
    "FIELD20": "",
    "Cause sources": "",
    "FIELD22": ""
  },
  {
    "code": 408,
    "date": "6-Sep-2011",
    "plane_type": "Swearingen SA227-BC Metro III",
    "location": "near Trinidad Air...",
    "country": "bolivia",
    "registration": "CP-2548",
    "operator": "Aerocon",
    "fat": "8",
    "f": 8,
    "sqrt+10": 12.8,
    "px": 23,
    "PX": 25,
    "cat": "A1",
    "phase": "approach",
    "certainty": "unknown",
    "FIELD16": "",
    "meta": "unknown",
    "cause": "unknown",
    "interesting notes": "sole survivor found on September 9. The crash was heard by two fishermen, who informed the authorities of the approximate location of the crash",
    "FIELD20": "",
    "Cause sources": "http://en.wikipedia.org/wiki/Aerocon_Flight_238",
    "FIELD22": ""
  },
  {
    "code": 409,
    "date": "7-Sep-2011",
    "plane_type": "Yakovlev 42D",
    "location": "near Yaroslavl-Tu...",
    "country": "russia",
    "registration": "RA-42434",
    "operator": "YAK Service",
    "fat": "44",
    "f": 44,
    "sqrt+10": 16.6,
    "px": 27,
    "PX": 45,
    "cat": "A1",
    "phase": "initial climb",
    "certainty": "confirmed",
    "FIELD16": "",
    "meta": "human_error",
    "cause": "pilot & crew error",
    "interesting notes": "The airplane carried members of the Lokomotiv Yaroslavl ice hockey professional  team for a match in Minsk. It was later revealed that the pilots were illegally flying the plane, having falsified needed documents to gain permission to fly the plane.",
    "FIELD20": "",
    "Cause sources": "",
    "FIELD22": ""
  },
  {
    "code": 410,
    "date": "20-Sep-2011",
    "plane_type": "Beechcraft 99A",
    "location": "near Milot",
    "country": "haiti",
    "registration": "HH-APA",
    "operator": "SALSA d'Haiti",
    "fat": "3",
    "f": 3,
    "sqrt+10": 11.7,
    "px": 22,
    "PX": 15,
    "cat": "A1",
    "phase": "en route",
    "certainty": "unknown",
    "FIELD16": "",
    "meta": "unknown",
    "cause": "collision with flooded sugar cane field/bad weather reported",
    "interesting notes": "",
    "FIELD20": "",
    "Cause sources": "",
    "FIELD22": ""
  },
  {
    "code": 411,
    "date": "25-Sep-2011",
    "plane_type": "Beechcraft 1900D",
    "location": "near Kathmandu-Tr...",
    "country": "nepal",
    "registration": "9N-AEK",
    "operator": "Buddha Air",
    "fat": "19",
    "f": 19,
    "sqrt+10": 14.4,
    "px": 24,
    "PX": 35,
    "cat": "A1",
    "phase": "approach",
    "certainty": "suggested",
    "FIELD16": "",
    "meta": "human_error",
    "cause": "pilot & crew error",
    "interesting notes": "tourist sightseeing flight",
    "FIELD20": "",
    "Cause sources": "",
    "FIELD22": ""
  },
  {
    "code": 412,
    "date": "29-Sep-2011",
    "plane_type": "CASA/Nurtanio NC-212 Aviocar 2",
    "location": "near Bohorok, Sum...",
    "country": "indonesia",
    "registration": "PK-TLF",
    "operator": "Nusantara Buana Air",
    "fat": "18",
    "f": 18,
    "sqrt+10": 14.2,
    "px": 24,
    "PX": 35,
    "cat": "A1",
    "phase": "en route",
    "certainty": "unknown",
    "FIELD16": "",
    "meta": "unknown",
    "cause": "collision with forested mountainside",
    "interesting notes": "",
    "FIELD20": "",
    "Cause sources": "",
    "FIELD22": ""
  },
  {
    "code": 413,
    "date": "13-Oct-2011",
    "plane_type": "DHC-8-102",
    "location": "near Madang Airpo...",
    "country": "Papua New Guinea",
    "registration": "P2-MCJ",
    "operator": "Airlines PNG",
    "fat": "28",
    "f": 28,
    "sqrt+10": 15.3,
    "px": 25,
    "PX": 35,
    "cat": "A1",
    "phase": "approach",
    "certainty": "suspected",
    "FIELD16": "",
    "meta": "mechanical",
    "cause": "engline loss",
    "interesting notes": "the pilot-in-command  who was the handling pilot, was hand-flying the aircraft because the autopilot was unserviceable. After the crash, Airlines PNG decided to ground its entire fleet of 12 Dash 8s until further notice",
    "FIELD20": "",
    "Cause sources": "",
    "FIELD22": ""
  },
  {
    "code": 414,
    "date": "2-Apr-2012",
    "plane_type": "ATR-72-201",
    "location": "near Tyumen Airpo...",
    "country": "Russia",
    "registration": "VP-BYZ",
    "operator": "UTAir",
    "fat": "33",
    "f": 33,
    "sqrt+10": 15.7,
    "px": 26,
    "PX": 45,
    "cat": "A1",
    "phase": "initial climb",
    "certainty": "suggested",
    "FIELD16": "",
    "meta": "human_error",
    "cause": "maintenance/crew error",
    "interesting notes": "",
    "FIELD20": "",
    "Cause sources": "",
    "FIELD22": ""
  },
  {
    "code": 415,
    "date": "20-Apr-2012",
    "plane_type": "Boeing 737-236",
    "location": "near Islamabad-Be...",
    "country": "Pakistan",
    "registration": "AP-BKC",
    "operator": "Bhoja Airlines",
    "fat": "127",
    "f": 127,
    "sqrt+10": 21.3,
    "px": 31,
    "PX": 75,
    "cat": "A1",
    "phase": "approach",
    "certainty": "suspected",
    "FIELD16": "",
    "meta": "human_error",
    "cause": "maintenance/crew error",
    "interesting notes": "- This was Bhoja Airlines' inaugural flight on that route. - It was the second-deadliest aviation accident in Pakistan. - eyewitnesses stated that the aircraft may have been struck by lightning prior to the crash, describing it as a \"ball of fire.\" - Farooq Bhoja, the owner of Bhoja Air, has been put on the Exit Control List, meaning he may not leave the country while the criminal investigation is underway against him and the airline.",
    "FIELD20": "",
    "Cause sources": "http://en.wikipedia.org/wiki/Bhoja_Air_Flight_213",
    "FIELD22": ""
  },
  {
    "code": 416,
    "date": "14-May-2012",
    "plane_type": "Dornier 228-212",
    "location": "near Jomsom Airpo...",
    "country": "nepal",
    "registration": "9N-AIG",
    "operator": "Agni Air",
    "fat": "15",
    "f": 15,
    "sqrt+10": 13.9,
    "px": 24,
    "PX": 25,
    "cat": "A1",
    "phase": "approach",
    "certainty": "unknown",
    "FIELD16": "",
    "meta": "unknown",
    "cause": "collision (wing collision with mountainside during a go-around)",
    "interesting notes": "Among others the crash also claimed the lives of Taruni Sachdev, a 14-year-old child actress from India, and her mother.",
    "FIELD20": "",
    "Cause sources": "",
    "FIELD22": ""
  },
  {
    "code": 417,
    "date": "3-Jun-2012",
    "plane_type": "MD-83",
    "location": "near Lagos-Murtal...",
    "country": "nigeria",
    "registration": "5N-RAM",
    "operator": "Dana Air",
    "fat": "153+ 10",
    "f": 163,
    "sqrt+10": 22.8,
    "px": 33,
    "PX": 75,
    "cat": "A1",
    "phase": "approach",
    "certainty": "suspected",
    "FIELD16": "",
    "meta": "mechanical",
    "cause": "engline loss",
    "interesting notes": "crashed into a crowded neighbourhood near the airport, apparently landing on its tail and causing a large fire. Crowds attempted to bring hoses to the site while soldiers attempted to disperse onlookers with punches and rubber whips. The onlookers then threw stones at the soldiers in retaliation. Water for firefighting was scarce for several hours due to the city's shortage of fire trucks, and civilians attempted to fight the fire by hand with water from plastic buckets",
    "FIELD20": "",
    "Cause sources": "http://en.wikipedia.org/wiki/Dana_Air_Flight_992",
    "FIELD22": ""
  },
  {
    "code": 418,
    "date": "29-Jun-2012",
    "plane_type": "Embraer ERJ 190-100 LR (ERJ-1",
    "location": "Hotan Airpor...",
    "country": "china",
    "registration": "B-3171",
    "operator": "Tianjin Airlines",
    "fat": "2",
    "f": 2,
    "sqrt+10": 11.4,
    "px": 21,
    "PX": 15,
    "cat": "H2",
    "phase": "en route",
    "certainty": "confirmed",
    "FIELD16": "",
    "meta": "criminal",
    "cause": "hijacking",
    "interesting notes": "According to media reports the hijackers carried explosives and had attempted to break into the airplane's cockpit with a crutch.",
    "FIELD20": "",
    "Cause sources": "",
    "FIELD22": ""
  },
  {
    "code": 419,
    "date": "19-Aug-2012",
    "plane_type": "Antonov 26-100",
    "location": "near Talodi",
    "country": "sudan",
    "registration": "ST-ARL",
    "operator": "Alfa Airlines",
    "fat": "32",
    "f": 32,
    "sqrt+10": 15.7,
    "px": 26,
    "PX": 45,
    "cat": "A1",
    "phase": "approach",
    "certainty": "suspected",
    "FIELD16": "",
    "meta": "weather",
    "cause": "dust storm",
    "interesting notes": "The airplane carried Sudanese government officials to attend a function marking the Muslim Eid al-Fitr holiday",
    "FIELD20": "",
    "Cause sources": "",
    "FIELD22": ""
  },
  {
    "code": 420,
    "date": "22-Aug-2012",
    "plane_type": "Let L-410UVP-E9",
    "location": "Ngerende Air...",
    "country": "kenya",
    "registration": "5Y-UVP",
    "operator": "Mombasa Air Safari",
    "fat": "4",
    "f": 4,
    "sqrt+10": 12,
    "px": 22,
    "PX": 15,
    "cat": "A1",
    "phase": "takeoff",
    "certainty": "unknown",
    "FIELD16": "",
    "meta": "unknown",
    "cause": "unknown",
    "interesting notes": "\"It was taking off for another airstrip when the pilots apparently lost control of the airplane on takeoff.\"",
    "FIELD20": "",
    "Cause sources": "",
    "FIELD22": ""
  },
  {
    "code": 421,
    "date": "12-Sep-2012",
    "plane_type": "Antonov 28",
    "location": "near Palana Airport",
    "country": "russia",
    "registration": "RA-28715",
    "operator": "Petropavlovsk-Kamchatsky Air Ent.",
    "fat": "10",
    "f": 10,
    "sqrt+10": 13.2,
    "px": 23,
    "PX": 25,
    "cat": "A1",
    "phase": "approach",
    "certainty": "confirmed",
    "FIELD16": "",
    "meta": "human_error",
    "cause": "pilot & crew error",
    "interesting notes": "",
    "FIELD20": "",
    "Cause sources": "",
    "FIELD22": ""
  },
  {
    "code": 422,
    "date": "28-Sep-2012",
    "plane_type": "Dornier 228-202",
    "location": "near Kathmandu-Tr...",
    "country": "nepal",
    "registration": "9N-AHA",
    "operator": "Sita Air",
    "fat": "19",
    "f": 19,
    "sqrt+10": 14.4,
    "px": 24,
    "PX": 35,
    "cat": "A1",
    "phase": "initial climb",
    "certainty": "suggested",
    "FIELD16": "",
    "meta": "mechanical",
    "cause": "bird strike",
    "interesting notes": "Three minutes after take-off, on their way back to the airport, the aircraft crashed on the banks of the Manohara river and caught fire. Rumours about brid strike are unconfirmed",
    "FIELD20": "",
    "Cause sources": "",
    "FIELD22": ""
  },
  {
    "code": 423,
    "date": "22-Dec-2012",
    "plane_type": "Swearingen SA227-AC Metro III",
    "location": "Sanikiluaq A...",
    "country": "canada",
    "registration": "C-GFWX",
    "operator": "Perimeter Aviation, opf. Kivalliq Air",
    "fat": "1",
    "f": 1,
    "sqrt+10": 11,
    "px": 21,
    "PX": 15,
    "cat": "A1",
    "phase": "landing",
    "certainty": "unknown",
    "FIELD16": "",
    "meta": "unknown",
    "cause": "unknown (hard landing, plane overran the runway)",
    "interesting notes": "one passenger, an infant, was fatally injured. The aircraft came to a stop approximately 150 to 200 metres past the end of the intended runway surface.",
    "FIELD20": "",
    "Cause sources": "",
    "FIELD22": ""
  },
  {
    "code": 424,
    "date": "25-Dec-2012",
    "plane_type": "Fokker 100",
    "location": "near Heho Airport...",
    "country": "Myanmar",
    "registration": "XY-AGC",
    "operator": "Air Bagan",
    "fat": "1+ 1",
    "f": 2,
    "sqrt+10": 11.4,
    "px": 21,
    "PX": 15,
    "cat": "A1",
    "phase": "landing",
    "certainty": "unknown (ongoing investigation)",
    "FIELD16": "",
    "meta": "unknown",
    "cause": "collision (with power lines)/weather (fog)/possibly crew error (misjudgement of runway)",
    "interesting notes": "one passenger, a tour guide, was killed. A person who was travelling on a motor cycle on the ground was hit by the aircraft as it landed and was also killed. - Myanmar Information Ministry stated that the pilots mistook a road for the airport's runway in conditions described by the airline as heavy fog and landed the aircraft in a rice field",
    "FIELD20": "",
    "Cause sources": "",
    "FIELD22": ""
  },
  {
    "code": 425,
    "date": "29-Jan-2013",
    "plane_type": "Canadair CL-600-2B19 Regional",
    "location": "near Almaty Airport, Kazakhstan",
    "country": "Kazakhstan",
    "registration": "UP-CJ006",
    "operator": "SCAT",
    "fat": "21",
    "f": 21,
    "sqrt+10": 14.6,
    "px": 25,
    "PX": 35,
    "cat": "A1",
    "phase": "approach",
    "certainty": "suspected",
    "FIELD16": "",
    "meta": "weather",
    "cause": "bad weather",
    "interesting notes": "",
    "FIELD20": "",
    "Cause sources": "",
    "FIELD22": ""
  },
  {
    "code": 426,
    "date": "13-Feb-2013",
    "plane_type": "Antonov 24RV",
    "location": "near Donetsk Airp...",
    "country": "Ukraine",
    "registration": "UR-WRA",
    "operator": "South Airlines",
    "fat": "5",
    "f": 5,
    "sqrt+10": 12.2,
    "px": 22,
    "PX": 15,
    "cat": "A1",
    "phase": "landing",
    "certainty": "confirmed",
    "FIELD16": "",
    "meta": "human_error",
    "cause": "pilot error",
    "interesting notes": "\"unprepared crew\"",
    "FIELD20": "",
    "Cause sources": "",
    "FIELD22": ""
  },
  {
    "code": 427,
    "date": "6-Jul-2013",
    "plane_type": "Boeing 777-28EER",
    "location": "San Francisc...",
    "country": "USA",
    "registration": "HL7742",
    "operator": "Asiana Airlines",
    "fat": "2",
    "f": 2,
    "sqrt+10": 11.4,
    "px": 21,
    "PX": 15,
    "cat": "A1",
    "phase": "landing",
    "certainty": "suspected",
    "FIELD16": "",
    "meta": "human_error",
    "cause": "pilot error",
    "interesting notes": "",
    "FIELD20": "",
    "Cause sources": "",
    "FIELD22": ""
  },
  {
    "code": 428,
    "date": "7-Jul-2013",
    "plane_type": "DHC-3T Texas Turbine Otter",
    "location": "Soldotna Air...",
    "country": "USA",
    "registration": "N93PC",
    "operator": "Rediske Air",
    "fat": "10",
    "f": 10,
    "sqrt+10": 13.2,
    "px": 23,
    "PX": 25,
    "cat": "A1",
    "phase": "takeoff",
    "certainty": "unknown",
    "FIELD16": "",
    "meta": "unknown",
    "cause": "unknown",
    "interesting notes": "",
    "FIELD20": "",
    "Cause sources": "",
    "FIELD22": ""
  },
  {
    "code": 429,
    "date": "16-Oct-2013",
    "plane_type": "ATR-72",
    "location": "Mekong River",
    "country": "Laos",
    "registration": "RDPL-34233",
    "operator": "Laos Airlines",
    "fat": "49",
    "f": 49,
    "sqrt+10": null,
    "px": null,
    "PX": 45,
    "cat": "A1",
    "phase": "landing",
    "certainty": "suspected",
    "FIELD16": "",
    "meta": "weather",
    "cause": "bad weather",
    "interesting notes": "",
    "FIELD20": "",
    "Cause sources": "http://www.theguardian.com/world/2013/oct/18/laos-air-crash-debris-bodies-retrieved",
    "FIELD22": ""
  },
  {
    "code": 430,
    "date": "19-Oct-2013",
    "plane_type": "Pilatus PC-6 Turbo Porter - exclude because private, not in database",
    "location": "Namur",
    "country": "Belgium",
    "registration": "-",
    "operator": "a parachuting trip",
    "fat": "11",
    "f": 11,
    "sqrt+10": null,
    "px": null,
    "PX": 25,
    "cat": "",
    "phase": "en route",
    "certainty": "suggested",
    "FIELD16": "",
    "meta": "mechanical",
    "cause": "wing damage",
    "interesting notes": "",
    "FIELD20": "",
    "Cause sources": "http://www.bbc.co.uk/news/world-europe-24595694",
    "FIELD22": "http://news.sky.com/story/1156862/ten-skydivers-killed-in-belgium-plane-crash"
  },
  {
    "code": 431,
    "date": "17-Nov-2013",
    "plane_type": "Boeing 747-53A",
    "location": "Kazan",
    "country": "Russia",
    "registration": "VQ-BBN",
    "operator": "Tatarstan Airlines",
    "fat": "50",
    "f": 50,
    "sqrt+10": null,
    "px": null,
    "PX": 45,
    "cat": "A1",
    "phase": "landing",
    "certainty": "unknown (ongoing investigation)",
    "FIELD16": "",
    "meta": "unknown",
    "cause": "pilot or mechanical error",
    "interesting notes": "",
    "FIELD20": "",
    "Cause sources": "http://en.wikipedia.org/wiki/Tatarstan_Airlines_Flight_363",
    "FIELD22": "http://www.theguardian.com/world/2013/nov/17/russia-plane-crash-kazan-airport"
  },
  {
    "code": 432,
    "date": "29-Nov-2013",
    "plane_type": "        Embraer E190",
    "location": "Namibia",
    "country": "Namibia",
    "registration": "C9-EMC",
    "operator": "Mozambique Airlines",
    "fat": "33",
    "f": 33,
    "sqrt+10": null,
    "px": null,
    "PX": 45,
    "cat": "A1",
    "phase": "en route",
    "certainty": "unknown (ongoing investigation)",
    "FIELD16": "",
    "meta": "unknown",
    "cause": "heavy rainfall reported in flight path at the time of the crash",
    "interesting notes": "",
    "FIELD20": "",
    "Cause sources": "http://en.wikipedia.org/wiki/LAM_Mozambique_Airlines_Flight_470",
    "FIELD22": "http://www.theguardian.com/world/2013/nov/30/mozambique-airlines-plane-crash-kills-all-namibia"
  },
  {
    "code": 433,
    "date": "16-Feb-2014",
    "plane_type": "DHC-6 Twin Otter 300",
    "location": "near Sandhikhark",
    "country": "Nepal",
    "registration": "9N-ABB",
    "operator": "EU blacklist Nepal Airlines",
    "fat": "18",
    "f": 18,
    "sqrt+10": null,
    "px": null,
    "PX": 35,
    "cat": "A1",
    "phase": "en route",
    "certainty": "suspected",
    "FIELD16": "",
    "meta": "weather",
    "cause": "icing after flying into freezing rain",
    "interesting notes": "Flight RA-183 was a domestic flight from Kathmandu to Jumla with an en route stop at Pokhara. The airplane took off from Pokhara at 12:43. Last radio contact was at 13:13. The airplane did not arrive at the scheduled arrival time of 13:45. The wreckage was located the following morning at an altitude of 7000 feet (2130 m). There were no surivors. On Feb 17th 2014 listeners on frequency reported that the aircraft attempted to divert to Bhairahawa Airport due to icing after the aircraft had flown into freezing rain.",
    "FIELD20": "",
    "Cause sources": "http://aviation-safety.net/database/record.php?id=20140216-0",
    "FIELD22": "http://www.aeroinside.com/item/3704/nepal-dhc6-near-khidim-on-feb-16th-2014-aircraft-impacted-terrain"
  },
  {
    "code": 434,
    "date": "8-Mar-2014",
    "plane_type": "Boeing 777-2H6ER",
    "location": "South China Sea",
    "country": "Malaysia, Pacific Ocean",
    "registration": "9M-MRO",
    "operator": "Malaysia Airlines",
    "fat": "-",
    "f": null,
    "sqrt+10": null,
    "px": null,
    "PX": null,
    "cat": "C1",
    "phase": "en route",
    "certainty": "unknown (ongoing investigation)",
    "FIELD16": "",
    "meta": "unknown",
    "cause": "disappeared on 8 March.",
    "interesting notes": "Malaysia Airlines Flight MH-370 from Kuala Lumpur, Malaysia to Beijing, China was reported missing. There were 227 passengers and 12 crew members on board. Unconfirmed media reports suggest that the airplane climbed to 45,000 feet, above the approved altitude limit for a Boeing 777-200, soon after it disappeared from civilian radar. The plane then descended unevenly to 23,000 feet. The plane was reportedly last recorded flying at 29,500 feet when radar contact was lost.",
    "FIELD20": "",
    "Cause sources": "http://aviation-safety.net/database/record.php?id=20140308-0",
    "FIELD22": ""
  }
]
