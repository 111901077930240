import data from "../data/data.json";
import Isotope from "isotope-layout";

const phases = data.map(({ meta }) => meta);
function onlyUnique(value, index, array) {
  return array.indexOf(value) === index;
}
console.log(phases.filter(onlyUnique));

import "../css/style.css";

let iso;

var biw,
  bih,
  bitop,
  bileft,
  ih,
  iho,
  humanBar,
  weatherBar,
  mechanicalBar,
  criminalBar,
  unknownBar,
  ghostBar;
var counter = $("#countno");
var counterWord = $("#counterword");
var countHelp = $("#count-help");
var clear = $("#clear");
var logo = $("#logo");
var intro = $("#intro");
var overlay = $("#overlay");
var months = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];
var shortMonths = [
  "Jan",
  "Feb",
  "Mar",
  "Apr",
  "May",
  "Jun",
  "Jul",
  "Aug",
  "Sep",
  "Oct",
  "Nov",
  "Dec",
];
var sum = $("#summary");
var sg = $("#summary-graph");
var st = $("#summary-totals");
var year = 2014;
window.lastYear = year - 1;
window.key = "date";
var from = 1993;
var years = year - from;
var classes = {};
var filters = {};
var body = $("body");
var html = $("html,body");
var brk = $(".breaker");
filters["cert"] = [];
$.fn.powerTip.smartPlacementLists.n = ["n", "ne", "nw", "s"];
$.fn.powerTip.smartPlacementLists.e = ["e", "w", "s", "n"];
$.fn.powerTip.smartPlacementLists.s = [
  "s",
  "n",
  "se",
  "sw",
  "ne",
  "nw",
  "w",
  "e",
];
$.fn.powerTip.smartPlacementLists.w = ["w", "e", "s", "n"];

function pop(url, name) {
  var day = new Date();
  var id = day.getTime();
  eval(
    "page" +
      id +
      " = window.open('" +
      url +
      "', '" +
      name +
      "', 'height=320, width=640,left=" +
      window.popCenter +
      ",top=200, toolbar=no, menubar=no, scrollbars=no, resizable=no, location=no, directories=no, status=no');"
  );
}

function removeA(arr) {
  var what,
    a = arguments,
    L = a.length,
    ax;
  while (L > 1 && arr.length) {
    what = a[--L];
    while ((ax = arr.indexOf(what)) !== -1) {
      arr.splice(ax, 1);
    }
  }
  return arr;
}

function capF(string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

function killLoader() {
  $("#loading").fadeOut().hide();
}

function killLoadHelp() {
  $("#loadhelp").fadeOut(300);
  body.removeClass("loadhelpvisible").addClass("contentloaded");
  $("#summary").fadeTo(300, 1);
}

function loadIntro() {
  body.removeClass("loading");
  $("#intro .panel-one,#skipintro").fadeTo(500, 1);
  setTimeout(panelOneNext, 4000);
}

function panelOneNext() {
  $("#intro .panel-one").fadeTo(500, 0.5).addClass("panel-out");
  $("#intro .panel-one .source").fadeTo(500, 0.5).addClass("out");

  $("#intro .panel-two").fadeTo(500, 1).removeClass("subtext-hidden");

  function bubblesIn() {
    $("#intro .panel-two .safety-table td").each(function (i) {
      $(this)
        .delay(i++ * 500)
        .fadeTo(500, 1);
    });
  }

  setTimeout(bubblesIn, 2000);

  setTimeout(panelTwoNext, 11000);
}

function panelTwoNext() {
  $("#intro .panel-one,#intro .panel-two")
    .addClass("panel-out")
    .delay(500)
    .fadeOut(function () {
      $(this).hide();
    });
  $("#intro .panel-two .source").fadeTo(500, 0.5).addClass("out");
  $("#intro .panel-three").fadeTo(500, 1).removeClass("subtext-hidden");

  function bubblesIn() {
    $("#intro .panel-three .panel-image .panel-icon").each(function (i) {
      $(this)
        .delay(i++ * 500)
        .fadeTo(500, 1);
    });
  }

  setTimeout(bubblesIn, 1000);

  setTimeout(panelThreeNext, 9000);
}

function panelThreeNext() {
  $("#intro .panel-three .panel-icon").each(function (i) {
    $(this)
      .delay(i++ * 100)
      .animate({ top: "-1000px" });
  });
  $("#intro .panel-three .panel-text")
    .addClass("panel-out")
    .fadeTo(500, 0.5, function () {
      $("#intro .panel-four")
        .delay(200)
        .fadeTo(500, 1)
        .removeClass("subtext-hidden");
    });

  $("#skipintro").hide();

  setTimeout(panelFourNext, 4000);
}

function panelFourNext() {
  $("#intro .panel-four .panel-next").click(function () {
    $("#intro .panel-four").fadeTo(500, 0.5).addClass("panel-out");

    endIntro();
  });
}

function endIntro() {
  intro.fadeOut(function () {
    body.removeClass("intro").addClass("intro-done");
    window.container.fadeIn();
    renderMain();
  });
}

function showStartButton() {
  $("#closeloadhelp").show();
}

function doDateLabels() {
  window.container.before(
    '<div class="start-date date-label-shown anim-slow"><span></span></div>'
  );
  window.container.after(
    '<div class="end-date date-label-shown anim-xslow"><span></span></div>'
  );
}

function renderMain() {
  //var c=window.c;
  intro.hide();
  arrange();
  renderIsotope();
  makeGraph();
  resizeGraph();
  graphHover();
  manageBoxes();
  doDateLabels();
  resizeLabels();
  iso.reloadItems();
  iso.arrange({ sortBy: "date", sortAscending: false });
  iso.on("layoutComplete", renderSummary);
  iso.once("layoutComplete", fadeWrapper);
  $(".box-inner").hoverIntent(boxOn, boxOff);
  $(".box-inner").powerTip({
    placement: "s" /*followMouse:true,*/,
    offset: 10,
    smartPlacement: true,
    popupId: "tip",
    intentPollInterval: 300,
    fadeInTime: 150,
    fadeOutTime: 150,
  });
  $(".option-set label").powerTip({
    placement: "s",
    popupId: "labeltip",
    intentPollInterval: 150,
    fadeInTime: 100,
    fadeOutTime: 50,
  });
}

function fadeWrapper() {
  //window.container.animate({'opacity':1},1000);
  $(".box-parent").each(function (i) {
    $(this)
      .delay(i++ * 3)
      .fadeTo(50, 0.6);
  });

  $("#loadhelp").removeClass("out");

  /*
    $('#options input[type=checkbox] + label').each(function(i) {
        $(this).delay((i++) * 200).fadeTo(500, 1).delay(0).fadeTo(500,0.4);
    });
    */
  $("#summary").fadeTo(300, 0.2);
  $("#closeloadhelp").click(function () {
    killLoadHelp();
  });

  doFirstLast();
  showStartButton();
}

function resizeElems() {
  window.ww = $(window).width();
  window.wwD = (ww - 300) / 5;
  window.wh = $(window).height();
  window.bw = Math.round((wwD * 4) / 20);
  window.sgh = wh - 210;
  window.yh = Math.round((wh - 420) / years);
  window.popCenter = ww / 2 - 320;

  brk.width(ww - 80);
}

function resizeJsonWrapper() {
  window.container.width(window.ww - 350);
}

function resizeLabels() {
  $(".start-date,.end-date").width(window.ww - 380);
}

function arrange() {
  var box = $(".box");
  var boxinner = $(".box-inner");
  var info = $(".info");
  window.container.width(window.ww - 350);
  counterWord.show();
  counter.animateNumbers(window.c);
  //alert(window.c);
  box.width(window.bw);
  boxinner.each(function (index) {
    biw = $(this).width();
    bitop = biw / 2;
    bitop = 0 - bitop;
    ih = $(this).children(".info").height();
    iho = 0 - ih;
    $(this).css({ top: +bitop, left: +bitop });
    $(this).children(".info").css({ top: +iho });
  });
  $("#sort-form-selector").chosen({
    disable_search_threshold: 10,
    width: "120px",
  });
}

function renderIsotope() {
  iso = new Isotope("#json-data", {
    itemSelector: ".box",
    layoutMode: "fitRows",
    getSortData: { date: ".date parseInt", fat: ".fat parseInt" },
  });
  window.totalItems = iso.filteredItems.length;
}

function getComboFilter(filters) {
  var i = 0;
  var comboFilters = [];
  var message = [];
  for (var prop in filters) {
    message.push(filters[prop].join(" "));
    var filterGroup = filters[prop];
    // skip to next filter group if it doesn't have any values
    if (!filterGroup.length) {
      continue;
    }
    if (i === 0) {
      // copy to new array
      comboFilters = filterGroup.slice(0);
    } else {
      var filterSelectors = [];
      // copy to fresh array
      var groupCombo = comboFilters.slice(0); // [ A, B ]
      // merge filter Groups
      for (var k = 0, len3 = filterGroup.length; k < len3; k++) {
        for (var j = 0, len2 = groupCombo.length; j < len2; j++) {
          filterSelectors.push(groupCombo[j] + filterGroup[k]); // [ 1, 2 ]
        }
      }
      // apply filter selectors to combo filters for next group
      comboFilters = filterSelectors;
    }
    i++;
  }
  var comboFilter = comboFilters.join(", ");
  return comboFilter;
}

function manageCheckbox($checkbox) {
  var checkbox = $checkbox[0];
  var group = $checkbox.parents(".option-set").attr("data-group");
  // create array for filter group, if not there yet
  var filterGroup = filters[group];
  if (!filterGroup) {
    filterGroup = filters[group] = [];
  }
  var isAll = $checkbox.hasClass("all");
  // reset filter group if the all box was checked
  if (isAll) {
    delete filters[group];
    if (!checkbox.checked) {
      checkbox.checked = "checked";
    }
  }
  // index of
  var index = $.inArray(checkbox.value, filterGroup);
  if (checkbox.checked) {
    var selector = isAll ? "input" : "input.all";
    $checkbox.siblings(selector).removeAttr("checked");
    if (!isAll && index === -1) {
      // add filter to group
      filters[group].push(checkbox.value);
    }
  } else if (!isAll) {
    // remove filter from group
    filters[group].splice(index, 1);
    // if unchecked the last box, check the all
    if (!$checkbox.siblings("[checked]").length) {
      $checkbox.siblings("input.all").attr("checked", "checked");
    }
  }
}

function boxOn() {
  $(this).addClass("f");
  $(this).parent().addClass("f");
  //$('.box-parent:not(.f)').stop().css({'opacity':0.3});
}

function boxOff() {
  $(this).removeClass("f");
  $(this).parent().removeClass("f");
  //$('.box-parent').stop().css({'opacity':'1'});
}

function clearAll() {
  body.addClass("loading");
  $(".options-container input").prop("checked", false);
  $(".options-container label").removeClass("selected");
  filters["cert"] = [];
  filters["cause"] = [];
  filters["reason"] = [];
  filters["phase"] = [];
  filters["year"] = [];
  if (iso) {
    iso.arrange({ filter: "*" });
  }
  var comboFilter = getComboFilter(filters);
  countItems();
  $("#debug .filter-opt-text").text(comboFilter);
  body.removeClass("loading");
}

/*function disableCertOnUnknown(cOptSet) {

    var cInput = cOptSet.children('input');

    $('#r_unknown').change(function() {
    	cInput.prop('disabled',this.checked);
    	if(this.checked == true) {
    		cOptSet.animate({'opacity':0.2},1000);

    		if(filters['cert'].length > 0) {
    		cOptSet.children('label').removeClass('selected');
    		removeA(filters['cert'],'.c_suspected');
    		removeA(filters['cert'],'.c_confirmed');
    		removeA(filters['cert'],'.c_probable');
    		}

    		cInput.prop('checked',false);
    	}
    	else {
    		cOptSet.animate({'opacity':1},1000);
    	}
    });

}*/
function endDateOffset() {
  var offset = window.container.height();
  offset = offset + 310;
  $(".end-date").css({ top: offset + "px" });
}

function doFirstLast() {
  var $filtered = iso.filteredItems;

  if ($filtered.length === 0) {
    return;
  }

  var firstItem = $filtered[0]["element"]["id"];
  var lastItem = $filtered[$filtered.length - 1]["element"]["id"];
  var countMsg;

  window.container.find(".first").removeClass("first");
  $("#" + firstItem).addClass("first");

  window.container.find(".last").removeClass("last");
  $("#" + lastItem).addClass("last");

  if (window.key == "date") {
    var startDate = $(".first .date-label").html();
    var endDate = $(".last .date-label").html();

    $(".start-date span").html(startDate);
    $(".end-date span").html(endDate);
  }

  if (window.key == "date" && window.order == "asc") {
    countMsg = "oldest first";
  }
  if (window.key == "date" && window.order == "desc") {
    countMsg = "newest first";
  }
  if (window.key == "fat" && window.order == "asc") {
    countMsg = "least deadly first";
    $(".start-date span").html("Least deadly");
    $(".end-date span").html("Most deadly");
  }
  if (window.key == "fat" && window.order == "desc") {
    countMsg = "most deadly first";
    $(".start-date span").html("Most deadly");
    $(".end-date span").html("Least deadly");
  }
  countHelp.html(countMsg);
  //alert(countMsg);

  endDateOffset();
}

function countItems() {
  if (!iso) {
    return;
  }

  var $filtered = iso.filteredItems;
  if ($filtered.length === window.totalItems) {
    counterWord.show();
    counter.animateNumbers(window.totalItems);
  } else {
    counterWord.hide();
    counter.animateNumbers($filtered.length);
  }
  doFirstLast();
}

function setUpClasses() {
  for (let w = 1993; w <= year; w++) {
    //var elY = all[w]['element']['dataset']['year'];
    classes[w] = [];
  }
}

function getHumanErrorTotal(el) {
  return el["element"]["className"].indexOf("r_human_error") !== -1;
}

function getWeatherTotal(el) {
  return el["element"]["className"].indexOf("r_weather") !== -1;
}

function getMechanicalTotal(el) {
  return el["element"]["className"].indexOf("r_mechanical") !== -1;
}

function getCriminalTotal(el) {
  return el["element"]["className"].indexOf("r_criminal") !== -1;
}

function getUnknownTotal(el) {
  return el["element"]["className"].indexOf("r_unknown") !== -1;
}

function tallyHumanError(el) {
  return el.indexOf("r_human_error") !== -1;
}

function tallyWeather(el) {
  return el.indexOf("r_weather") !== -1;
}

function tallyMechanical(el) {
  return el.indexOf("r_mechanical") !== -1;
}

function tallyCriminal(el) {
  return el.indexOf("r_criminal") !== -1;
}

function tallyUnknown(el) {
  return el.indexOf("r_unknown") !== -1;
}

function makeGraph() {
  for (var i = from; i <= year; i++) {
    sg.append(
      "<div class='year' id='year-" +
        i +
        "' data-year='" +
        i +
        "'><input type='checkbox' name='y" +
        i +
        "' id='y_" +
        i +
        "' value='.y_" +
        i +
        "' /><label for='y_" +
        i +
        "' class='marker anim-med' data-powertiptarget='year-breakdown-" +
        i +
        "'>" +
        i +
        "</label><div class='bars'><div class='bar bar_ghost anim-med'></div><div class='bar bar_human_error anim-med'></div><div class='bar bar_weather anim-med'></div><div class='bar bar_mechanical anim-med'></div><div class='bar bar_criminal anim-med'></div><div class='bar bar_unknown anim-med' ></div></div></div>"
    );
  }
}

function resizeGraph() {
  sg.height(window.sgh + 20);
  sum.height(window.sgh - 115);
  st.css({ top: window.sgh - 180 + "px" });
  $(".year").height(window.yh);
}

function graphHover() {
  $("label.marker").hoverIntent(
    function () {
      var highlightYear = $(this).parent().data("year");

      window.highlightBoxes = $(
        ".box[data-year=" +
          highlightYear +
          "] .box-parent, .box[data-year=" +
          highlightYear +
          "] .box-parent .box-inner"
      );
      window.nonBoxes = $(".box").not("[data-year=" + highlightYear + "]");

      //window.nonBoxes.addClass('h');
      window.highlightBoxes.addClass("f");
    },
    function () {
      //window.nonBoxes.removeClass('h');
      window.highlightBoxes.removeClass("f");
    }
  );

  $("label.marker").click(function () {
    $(this).powerTip("hide");
  });
}

function renderSummary() {
  setUpClasses();
  var totals = [];
  var all = iso.filteredItems;
  for (let w = 0; w < all.length; w++) {
    const elY = all[w]["element"]["attributes"]["data-year"]["nodeValue"];
    if (!classes[elY]) {
      classes[elY] = [];
    }
    classes[elY].push(all[w]["element"]["className"]);
  }
  totals.push(all.filter(getHumanErrorTotal).length);
  totals.push(all.filter(getWeatherTotal).length);
  totals.push(all.filter(getMechanicalTotal).length);
  totals.push(all.filter(getCriminalTotal).length);
  totals.push(all.filter(getUnknownTotal).length);
  $(".summary-total-container").each(function (index) {
    var stc = $(this);
    if (totals[index] > 0) {
      stc.removeClass("empty");
    } else {
      stc.addClass("empty");
    }
    stc.children(".summary-total").animateNumbers(totals[index]);
  });

  $("#debug .breakdown").html(
    "Human: " +
      totals[0] +
      "<br /> Weather: " +
      totals[1] +
      "<br />Mech: " +
      totals[2] +
      "<br />Criminal: " +
      totals[3] +
      "<br />Unknown: " +
      totals[4] +
      ""
  );

  $(".debug_content,#year-breakdown-holder").html("");
  //sg.html('');

  for (var i = from; i <= year; i++) {
    var tallies = [];
    var tallyClasses = [];
    tallies.push(classes[i].filter(tallyHumanError).length);
    tallies.push(classes[i].filter(tallyWeather).length);
    tallies.push(classes[i].filter(tallyMechanical).length);
    tallies.push(classes[i].filter(tallyCriminal).length);
    tallies.push(classes[i].filter(tallyUnknown).length);
    tallies.push(
      tallies[0] + tallies[1] + tallies[2] + tallies[3] + tallies[4]
    );
    for (var ii = 0; ii < tallies.length; ii++) {
      var elClass = tallies[ii] == 0 ? "empty" : "";
      tallyClasses.push(elClass);
    }
    $("#year-breakdown-holder").append(
      "<div class='year-breakdown' id='year-breakdown-" +
        i +
        "'><span class='info-l'>" +
        i +
        "</span><div class='info-d'></div><span class='info-label'>" +
        tallies[5] +
        " incidents shown</span><div class='info-d'></div><div class='summary-year-breakdown'><div class='cf summary-total-container stc-human_error " +
        tallyClasses[0] +
        "'><span class='icon'></span><span class='info-s'>Human Error: <span class='stc-tally'>" +
        tallies[0] +
        "</span></span></div><div class='cf summary-total-container stc-weather " +
        tallyClasses[1] +
        "'><span class='icon'></span><span class='info-s'>Weather: <span class='stc-tally'>" +
        tallies[1] +
        "</span></span></div><div class='cf summary-total-container stc-mechanical " +
        tallyClasses[2] +
        "'><span class='icon'></span><span class='info-s'>Mechanical: <span class='stc-tally'>" +
        tallies[2] +
        "</span></span></div><div class='cf summary-total-container stc-criminal " +
        tallyClasses[3] +
        "'><span class='icon'></span><span class='info-s'>Criminal: <span class='stc-tally'>" +
        tallies[3] +
        "</span></span></div><div class='cf summary-total-container stc-unknown " +
        tallyClasses[4] +
        "'><span class='icon'></span><span class='info-s'>Unknown: <span class='stc-tally'>" +
        tallies[4] +
        "</span></span></div></div></div>"
    );

    $("#debug .advanced .human .debug_content").append(
      i + ": " + tallies[0] + "<br />"
    );
    $("#debug .advanced .weather .debug_content").append(
      i + ": " + tallies[1] + "<br />"
    );
    $("#debug .advanced .mech .debug_content").append(
      i + ": " + tallies[2] + "<br />"
    );
    $("#debug .advanced .crim .debug_content").append(
      i + ": " + tallies[3] + "<br />"
    );
    $("#debug .advanced .unknown .debug_content").append(
      i + ": " + tallies[4] + "<br />"
    );

    humanBar = tallies[0] * 5;
    weatherBar = tallies[1] * 5;
    mechanicalBar = tallies[2] * 5;
    criminalBar = tallies[3] * 5;
    unknownBar = tallies[4] * 5;
    ghostBar = humanBar + weatherBar + mechanicalBar + criminalBar + unknownBar;

    $("#year-" + i + " .bar_human_error").css({ width: +humanBar + "px" });
    $("#year-" + i + " .bar_weather").css({
      width: +weatherBar + "px",
      left: +humanBar + "px",
    });
    $("#year-" + i + " .bar_mechanical").css({
      width: +mechanicalBar + "px",
      left: +(humanBar + weatherBar) + "px",
    });
    $("#year-" + i + " .bar_criminal").css({
      width: +criminalBar + "px",
      left: +(humanBar + weatherBar + mechanicalBar) + "px",
    });
    $("#year-" + i + " .bar_unknown").css({
      width: +unknownBar + "px",
      left: +(humanBar + weatherBar + mechanicalBar + criminalBar) + "px",
    });
    $("#year-" + i + " .bar_ghost").css({ width: +ghostBar + "px" });
  }

  $("label.marker").powerTip({
    placement: "w",
    smartPlacement: true,
    popupId: "yeartip",
    intentPollInterval: 300,
    fadeInTime: 200,
    fadeOutTime: 50,
  });
}

function manageBoxes() {
  $(".options-container input").change(function () {
    html.animate({ scrollTop: 0 }, 2000);

    //$('body').addClass('loading');

    if ($(".options-container input:checked").length > 0) {
      clear.animate({ opacity: 1 });
    } else {
      clear.animate({ opacity: 0 });
    }

    $(this).next("label").toggleClass("selected", this.checked);

    //$('label.marker').not('.selected').css({'opacity':0.5});
    //$('label.marker.selected').css({'opacity':1});

    var checkbox = $(this);

    manageCheckbox(checkbox);

    var comboFilter = getComboFilter(filters);

    console.log({ checkbox, comboFilter });

    iso.arrange({ filter: comboFilter, transitionDuration: "1s" });

    countItems();

    $("#debug .filter-opt-text").text(comboFilter);

    //$('body').removeClass('loading');
  });
}

function resizeIntro() {
  intro.width(window.ww).height(window.wh - 180);
}

$(document).ready(function () {
  resizeElems();
  resizeIntro();

  var c = 0;

  clear.click(function (e) {
    e.preventDefault();
    clearAll();
    $(this).animate({ opacity: 0 }, 50);
  });

  logo.click(function (e) {
    e.preventDefault();
    clearAll();
    clear.animate({ opacity: 0 }, 50);
  });

  //disableCertOnUnknown(cOptSet);

  $("#skipintro").click(function () {
    endIntro();
  });

  /*
    $('.sort a').click(function(e) {

        e.preventDefault();

        $('body').addClass('loading');

        var $this = $(this);

        // Turn 'selected' class on/off
        if ($this.hasClass('selected')) return false;
        $this.parents('.sort-set').find('.selected').removeClass('selected');
        $this.addClass('selected');

        var key = $('.sortKey a.selected').attr('data-option-value');
        var order = $('.sortOrder a.selected').attr('data-option-value');

        var valBy = key; // For instance name_asc
        var valAscending = (order == "asc"); // true for 'asc', false otherwise

        $("#json-data").isotope({sortBy : valBy, sortAscending : valAscending,transitionDuration:'1s'});

        $('body').removeClass('loading');


    });

    */

  $("#sort-form-selector").change(function () {
    var val = $(this).val();
    val = val.split("-");

    var key = val[0];
    var order = val[1];

    window.key = key;
    window.order = order;

    var orderBool = order === "asc"; // true for 'asc', false otherwise

    iso.arrange({
      sortBy: key,
      sortAscending: orderBool,
      transitionDuration: "1s",
    });

    iso.on("layoutComplete", doFirstLast);

    body.removeClass("loading");
  });

  function debugLog(msg) {
    $(".debug-output").html(msg);
  }

  // start of data work

  var items = [];
  var z,
    i,
    x,
    s,
    m,
    d,
    y,
    date,
    dateStamp,
    prettyDate,
    country,
    fatWord,
    adjustedCause;

  $.each(data, function (i, obj) {
    c++;
    date = obj.date;
    dateStamp = date.replace(/-/g, "");

    prettyDate = date.split("-");

    d = prettyDate[0];
    m = prettyDate[1];
    y = prettyDate[2];

    prettyDate = d + " " + m + " " + y;
    country = capF(obj.country);

    if (obj.cause != "unknown") {
      adjustedCause = " - " + obj.cause;
    } else {
      adjustedCause = "";
    }

    //z=obj.id;
    if (obj.PX > 200) {
      z = 1;
      s = "oversize";
    } else if (obj.PX > 100) {
      z = 2;
      s = "med";
    } else {
      z = 3;
      s = "normal";
    }

    if (obj.f === 1) {
      fatWord = "fatality";
    } else {
      fatWord = "fatalities";
    }

    items.push(
      "<div id='box_" +
        i +
        "' data-year='" +
        y +
        "' rel='" +
        y +
        "' class='anim-slow-opacity size_" +
        s +
        " box y_" +
        y +
        " p_" +
        obj.phase.replace(" ", "_") +
        " c_" +
        obj.cert +
        " r_" +
        obj.meta +
        "'><div class='date-label'>Date: " +
        y +
        "</div><div class='box-parent anim-xslow'><div class='box-inner anim-fast-opacity' style='z-index:" +
        z +
        ";width:" +
        obj.PX +
        "px;height:" +
        obj.PX +
        "px' data-powertiptarget='info_" +
        i +
        "'><div class='info' id='info_" +
        i +
        "'><span class='info-label'>Fatalities</span><span class='info-l'>" +
        obj.f +
        "</span><div class='info-d'></div><span class='info-label'>Date</span><span class='info-date info-l'>" +
        prettyDate +
        "</span><div class='info-d'></div><span class='info-label'>Aircraft</span><span class='info-s'>" +
        obj.plane_type +
        "</span><div class='info-d'></div><span class='info-label'>Location</span><span class='info-s'>" +
        country +
        "</span><div class='info-d'></div><span class='info-label'>Airline</span><span class='info-s'>" +
        obj.operator +
        "</span><div class='info-d'></div><span class='info-label'>Cause</span><span class='info-s'>" +
        capF(obj.meta.replace("_", " ")) +
        "" +
        adjustedCause +
        "</span><div class='info-d'></div><span class='info-label'>Certainty</span><span class='info-s'>" +
        capF(obj.certainty) +
        "</span></div><span class='id'>" +
        obj.id +
        "</span><span class='date'>" +
        dateStamp +
        "</span><span class='fat'>" +
        obj.f +
        "</span></div></div></div>"
    );
  });

  $("<div/>", {
    id: "json-data",
    class: "anim-slow-opacity",
    html: items.join(""),
  }).appendTo("#wrapper");

  window.container = $("#json-data");
  resizeJsonWrapper();

  window.c = c;
  killLoader();
  loadIntro();

  $(".share-fb").click(function () {
    pop(
      "https://www.facebook.com/sharer/sharer.php?u=" + document.URL + "",
      "facebook_share"
    );
  });
  $(".share-tw").click(function () {
    pop(
      "https://www.twitter.com/share?url=" +
        document.URL +
        "&text=Explore this %23infographic of every commercial plane crash in the last 20 years via @BBC_Future %23aviation %23planes",
      "twitter_share"
    );
  });
  $(".share-rd").click(function () {
    pop(
      "http://www.reddit.com/submit?url=" +
        document.URL +
        "&title=This infographic allows you to investigate every commercial plane crash in the last 20 years by cause",
      "reddit_share"
    );
  });
  $(".share-dg").click(function () {
    pop(
      "http://www.digg.com/submit?phase=2&url=" +
        document.URL +
        "&title=This infographic allows you to investigate every commercial plane crash in the last 20 years by cause",
      "digg_share"
    );
  });
  window.onbeforeunload = function (e) {
    clearAll();
  };
});

$(window).resize(function () {
  resizeIntro();
  resizeElems(endDateOffset());
  resizeJsonWrapper();
  resizeGraph();
  resizeLabels();
});
